import { useEffect } from "react";
import PropTypes from "prop-types";
import { RadioGroupField } from "components/form";
import { Field, useFormikContext } from "formik";
import useTranslate from "hooks/use-translate-language";
import { formatDate } from "utils/misc";
import InsuranceForm from "components/edit-profile/insurance-form";

const Insurance = ({ patientInsurances }) => {
  const translate = useTranslate();
  const { values, errors, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.changed_insurance == false)
      setFieldValue(
        "insurances",
        patientInsurances?.map((insurance, index) => ({
          ...insurance,
          primary_order: index + 1,
          policy_number: insurance.plan_no,
          group_number: insurance.group,
          policy_start_date: formatDate(
            insurance.policy_start_date,
            "YYYY/MM/DD"
          )
        })) ?? []
      );
  }, [values.changed_insurance, setFieldValue]);

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <Field
        component={RadioGroupField}
        name="changed_insurance"
        label={translate("insuranceHasChanged")}
        value={values.changed_insurance}
        options={[
          { text: translate("yes"), value: true },
          { text: translate("no"), value: false }
        ]}
      />
      <div style={{ marginTop: 10 }}>
        <h4 style={{ padding: 10 }}>{translate("insurances")}</h4>
        <InsuranceForm
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
        />
      </div>
      {/* <Field */}
      {/*   component={RadioGroupField} */}
      {/*   name="insurance_card_provided" */}
      {/*   label="My insurance card is available" */}
      {/*   value={values.insurance_card_provided} */}
      {/*   options={[ */}
      {/*     { value: true, text: "Yes" }, */}
      {/*     { value: false, text: "No" } */}
      {/*   ]} */}
      {/* /> */}
    </div>
  );
};

export default Insurance;

Insurance.propTypes = {
  insuranceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  patientInsurances: PropTypes.arrayOf(PropTypes.object)
};
