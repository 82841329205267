import { Button } from "@mui/material";
import useTranslate from "hooks/use-translate-language";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

export const NewOrderErrorPage = ({ errorMessage, showLoginButton }) => {
  const translate = useTranslate();
  const history = useHistory();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}
    >
      <h2 style={{ color: "black" }}>{translate("sorry")}</h2>
      <h5 style={{ color: "black" }}>
        {translate("errorAuthenticatingAccount")}
      </h5>
      <h5 style={{ color: "black", marginBottom: 30 }}>
        {errorMessage || translate("linkNoLongerValid")}
      </h5>
      {showLoginButton && (
        <Button
          onClick={() => {
            history.push("/login");
          }}
          variant="contained"
        >
          {translate("loginToPlaceOrder")}
        </Button>
      )}
    </div>
  );
};

NewOrderErrorPage.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  showLoginButton: PropTypes.bool
};
