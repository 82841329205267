import React from "react";
import Modal from "components/ui/modal";
import { Button } from "@mui/material";
import useTranslate from "hooks/use-translate-language";
import { Spinner } from "components/ui";

const ConfirmationModal = ({ buttonText, title, message, onYes, spinner }) => {
    const translate = useTranslate();
    
    const submit = (closeModal) => {
        onYes(closeModal);  
    }

    return (
        <Modal
            maxWidth="sm"
            fullWidth
            renderTrigger={({ openModal }) => (
                <Button
                    color="error"
                    style={{ marginLeft: '10px' }}
                    onClick={() => { openModal(); }}
                >
                    {translate(buttonText)}
                </Button>
            )
            }
            renderContent={({ closeModal }) => (
                <div
                    className="reschedule-chat-modal-container"
                    style={{ padding: '10px' }}
                >
                    <h3 style={{ marginBottom: '20px' }}>{translate(title)}</h3>
                    <span style={{ fontSize: '20px', padding: '20px' }}> {translate(message)} </span>

                    <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                        <Button
                            variant="contained"
                            color="error"
                            style={{ display: 'inline-block', marginRight: '150px' }}
                            onClick={() => closeModal()}
                        >
                            No
                        </Button>
                        { !spinner &&<Button
                            variant="contained"
                            style={{ display: 'inline-block' }}
                            onClick={() => { submit(closeModal); }}
                        >
                            <div style={{ textAlign: 'center' }}>
                                {translate("yes")}
                            </div>
                        </Button>}
                        { spinner && <Spinner style={{ display: 'inline-block' }} />}
                    </div>
                </div>
            )}
        />)

}

export default ConfirmationModal;