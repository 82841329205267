import { useEffect } from "react";
// import PropTypes from "prop-types";
import { useParams, Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import selectors from "selectors";
import {
  fetchEquipmentDetails,
  fetchEquipmentFaqs,
  fetchEquipmentVideos,
} from "actions/products";
import ProductInfo from "./product-info";
import ProductVideos from "./product-videos";
import ProductFaqs from "./product-faqs";
import "./product-details.scss";

const ProductDetails = () => {
  const { product_id } = useParams();
  const dispatch = useDispatch();
  const product = useSelector((state) =>
    selectors.getProductById(state, product_id)
  );
  useEffect(() => {
    dispatch(fetchEquipmentDetails(product_id));
    dispatch(fetchEquipmentFaqs(product_id));
    dispatch(fetchEquipmentVideos(product_id));
  }, [dispatch, product_id]);
  return (
    <Switch>
      <Route path="/products/:product_id" exact>
        <ProductInfo product={product} product_id={product_id} />
      </Route>
      <Route path="/products/:product_id/videos" exact>
        <ProductVideos product={product} product_id={product_id} />
      </Route>
      <Route path="/products/:product_id/faqs" exact>
        <ProductFaqs product={product} product_id={product_id} />
      </Route>
    </Switch>
  );
};

ProductDetails.defaultProps = {};

ProductDetails.propTypes = {};

export default ProductDetails;
