import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from "@mui/material";
import { path } from "ramda";

const getPath = name => name.split(".");

// https://material-ui.com/components/selects/
const SelectInput = ({ label, options, name, ...rest }) => {
  const { values, handleBlur, handleChange, errors, touched } =
    useFormikContext();
  const namePath = getPath(name);
  const error = path(namePath, touched) && path(namePath, errors);
  const value = path(namePath, values);

  return (
    <FormControl fullWidth error={!!error}>
      <InputLabel shrink={true} id={`${name}-label`}>
        {label}
      </InputLabel>
      <Select
        id={name}
        labelId={`${name}-label`}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        {...rest}
      >
        {options.map(({ text, value }) => (
          <MenuItem key={value} value={value}>
            {text}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired
};

export default SelectInput;
