import { Page } from "components/ui";
import { useLocation } from "react-router-dom";
import { propOr } from "ramda";
import useTwilioVideo from "hooks/use-twilio-video";
import useTwilioChat from "hooks/use-twilio-chat";
import MessageList from "components/twilio-chat/message-list";
import SendMsgInput from "components/twilio-chat/send-msg-input";
import TwilioVideo from "components/twilio-video";
import "./product-demo.scss";
import useTranslate from "hooks/use-translate-language";

const ProductDemo = () => {
  const translate = useTranslate();
  const { state } = useLocation();
  const videoChat = propOr({}, "video_chat", state);
  const textChats = propOr([], "text_chats", state);
  const { members, room_name, publish_video_track, publish_audio_track } =
    videoChat;
  const twilioVideo = useTwilioVideo({
    members,
    room_name,
    publish_video_track,
    publish_audio_track,
  });
  const twilioChat = useTwilioChat({
    channel_sid: textChats[0]?.conversation_sid,
  });
  return (
    <Page.Container style={{ margin: 0 }}>
      <Page.Header title={translate("productDemo")} goBack />
      <div className="product-demo-container">
        <TwilioVideo {...twilioVideo} members={members} />
        {twilioVideo.connected && twilioChat.connected && (
          <div className="twilio-chat-container">
            <MessageList messages={twilioChat.messages} />
            <div className="send-message-info">
              {translate("haveAQuestion")}
            </div>
            <SendMsgInput sendMessage={twilioChat.sendMessage} />
          </div>
        )}
      </div>
    </Page.Container>
  );
};

export default ProductDemo;
