import React from "react";
import { Page } from "components/ui";
import useTranslate from "hooks/use-translate-language";
import "./styles.scss";

const headerStyle = {
  // borderBottom: `1px solid ${gainsboro}`,
  marginBottom: "0.9375rem",
  paddingBottom: "0.3125rem"
};
const KnowledgeBase = () => {
  const translate = useTranslate();
  return (
    <Page.Container>
      <Page.Header title={translate("frequentlyAskedQuestions")} goBack />
      <Page.Content>
        <div className="knowledge-base">
          <article>
            <h3 style={headerStyle}>{translate("aboutMySupplies")}</h3>
            <h4>{translate("maskLeakAir")}</h4>
            <p>{translate("maskLeakAirAnswer")}</p>
            <h4>{translate("gettingReddness")}</h4>
            <p>{translate("gettingReddnessAnswer")}</p>
            <h4>{translate("changeToDifferentMask")}</h4>
            <p>{translate("changeToDifferentMaskAnswer")}</p>
            <h4>{translate("waterInTubing")}</h4>
            <p>{translate("waterInTubingAnswer")}</p>
          </article>
          <article>
            <h3 style={headerStyle}>{translate("cleaningEquipment")}</h3>
            <h4>{translate("cleanMySupplies")}</h4>
            <p>
              <strong>{translate("daily")}:</strong>{" "}
              {translate("cleanMySuppliesDailyAnswer")}
            </p>
            <p>
              <strong>{translate("weekly")}:</strong>
              {translate("cleanMySuppliesWeeklyAnswer")}
            </p>
            <h4>{translate("precautionsToTake")}</h4>
            <p>{translate("precautionsToTakeAnswer")}</p>
            <p>{translate("headgearMayBeWashed")}</p>
          </article>
          <article>
            <h3 style={headerStyle}>{translate("reorderingSupplies")}</h3>
            <h4>{translate("howOftenToOrder")}</h4>
            <p>{translate("howOftenToOrderAnswer")}</p>
            <p>{translate("howOftenToOrderMethods")}</p>
          </article>
        </div>
      </Page.Content>
    </Page.Container>
  );
};

export default KnowledgeBase;
