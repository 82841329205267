import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  checkToken,
  checkTokenAndDob,
  checkAuth,
  checkAuthAndDob
} from "actions/auth";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { decodeJwt } from "utils/misc";
import { useLocation } from "react-router";
import { Card } from "@mui/material";
import useTranslate from "hooks/use-translate-language";
import { loginSuccess, setLanguage } from "reducers/auth";
import { NewOrderErrorPage } from "./error-page";
import { Spinner, LoadingButton } from "components/ui";
import { Formik, Form } from "formik";
import FormikField from "components/form/formik-field";
import * as Yup from "yup";

export const AuthorizePatient = ({ children }) => {
  const location = useLocation();
  const tokenString = queryString.parse(location.search).token;
  const authString = queryString.parse(location.search).auth ?? "";

  const decoded = decodeJwt(tokenString);
  const translate = useTranslate();

  const [isLoading, setIsLoading] = useState(true);
  const [numberOfAttempts, setNumberOfAttempts] = useState(1);
  const [patientAuthorized, setPatientAuthorized] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();

  //const ss = useSelector(selectors.getSpeaksSpanish) ? "spanish" : "english";

  useEffect(() => {
    var token =
      authString?.length > 0 ? checkAuth(authString) : checkToken(tokenString);

    dispatch(token).then(result => {
      setIsLoading(false);
      if (!result) setErrorMessage(translate("linkNoLongerValid"));
    });
  }, [tokenString, authString, dispatch]);

  useEffect(() => {
    if (tokenString) {
      dispatch(setLanguage(decoded.speaks_spanish));
    } else {
      dispatch(setLanguage(authString?.startsWith("s")));
    }
  }, []);

  const authView = () => {
    //Patient has entered dob correctly
    if (patientAuthorized) return children;
    // Token was invalid or dob was entered wrong
    else if (errorMessage)
      return <NewOrderErrorPage errorMessage={errorMessage} />;
    //Token is valid but dob hasn't been entered
    else
      return (
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          <Formik
            initialValues={{ dateOfBirth: "" }}
            onSubmit={async ({ dateOfBirth }) => {
              try {
                var success = false;
                if (tokenString && tokenString.length > 0) {
                  const resultToken = await dispatch(
                    checkTokenAndDob(tokenString, {
                      dateOfBirth,
                      numberOfAttempts
                    })
                  );
                  success = resultToken.success;
                } else {
                  const resultAuth = await dispatch(
                    checkAuthAndDob(authString, {
                      dateOfBirth,
                      numberOfAttempts
                    })
                  );
                  success = resultAuth.success;
                  dispatch(
                    loginSuccess({ authentication_token: resultAuth.token })
                  );
                  dispatch(setLanguage(authString?.startsWith("s")));
                }
                setPatientAuthorized(success);
                if (!success) setNumberOfAttempts(numberOfAttempts + 1);
                if (numberOfAttempts > 2) {
                  setErrorMessage(translate("dateOfBirthError"));
                }
              } catch (err) {
                console.error(err);
                setErrorMessage(err.message);
              }
            }}
            enableReinitialize
            validationSchema={Yup.object().shape({
              dateOfBirth: Yup.date().required(translate("selectADate"))
            })}
          >
            {({ isSubmitting, errors }) => (
              <Form className="patient-form" autoComplete="off">
                <Card style={{ padding: 30 }}>
                  <h4>{translate("confirmDateOfBirth")}</h4>
                  <div
                    style={{
                      padding: "15px 10px",
                      alignItems: "center",
                      display: "grid",
                      gridTemplateColumns: "1fr auto",
                      gridGap: 10
                    }}
                  >
                    <FormikField label="" name="dateOfBirth" type="date" />
                    <LoadingButton
                      color="primary"
                      type="submit"
                      isFetching={isSubmitting}
                      disabled={errors.dateOfBirth}
                    >
                      {translate("submit")}
                    </LoadingButton>
                  </div>
                  {numberOfAttempts > 1 && (
                    <div>{translate("tryAgainError")}</div>
                  )}
                </Card>
              </Form>
            )}
          </Formik>
        </div>
      );
  };

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Spinner />
        </div>
      ) : (
        authView()
      )}
    </div>
  );
};

AuthorizePatient.propTypes = {
  children: PropTypes.object.isRequired
};
