import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import selectors from "selectors";
import { fetchUpcomingChats } from "actions/chat";
import { Page } from "components/ui";
import { MoreVert } from "@mui/icons-material";
import { Menu, MenuItem, IconButton } from "@mui/material";
import UpcomingChat from "./upcoming-chat";
import ChatHistory from "components/video-chat-history";
import "./upcoming-chats.scss";
import useTranslate from "hooks/use-translate-language";
import UpcomingChatError from "./upcoming-chat-error";

const UpcomingChats = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const chats = useSelector(selectors.getUpcomingChats);
  const [isFetching, setIsFetching] = useState(false);
  const [inUpcomingAppointments, setInUpcomingAppointments] = useState(true)
  useEffect(() => {
    setIsFetching(true);
    dispatch(fetchUpcomingChats()).finally(() => {
      setIsFetching(false);
    });
  }, [dispatch]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [UpcomingChatErrors, setUpcomingChatErrors] = useState(false)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (inUpcomingAppointments) {
    return (
      <Page.Container>
        <Page.Header
          title={translate("upcomingChats")}
          goBack
          action={
            <IconButton
              onClick={handleClick}
              aria-controls="chat-menu"
              aria-label="settings"
              aria-haspopup="true"
            >
              <MoreVert />
            </IconButton>
          }
        />
        <Menu
          id="chat-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={(event) => {
              handleClick(event);
              setInUpcomingAppointments(false)
            }}
          >
            {translate("chatHistory")}
          </MenuItem>
        </Menu>
        <Page.Content
          isFetching={isFetching}
          isEmpty={chats.length === 0}
          emptyMessage={translate("noUpcomingChats")}
        >
          {chats
            // .sort(
            //   (a, b) =>
            //     new Date(a.chats[0].chat_details.date_time).getTime() -
            //     new Date(b.chats[0].chat_details.date_time).getTime()
            // )
            .map((chat) => {
              if (chat.slot_id != null && chat.chats[0] != null && chat.chats[0].chat_details != null && chat.chats[0].topic_details != null) {
                return <UpcomingChat key={chat.slot_id} {...chat} />}
              else if (!UpcomingChatErrors) {
                setUpcomingChatErrors(true)
              }
            })}
          {UpcomingChatErrors && <UpcomingChatError />}
        </Page.Content>
      </Page.Container>
    );
  } else {
    return (
      <ChatHistory setInUpcomingAppointments={setInUpcomingAppointments} />
    )
  }
};

export default UpcomingChats;
