import { useEffect, useState } from "react";
import { Page, Spinner } from "components/ui";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  fetchQuestionnaireDetails,
  submitQuestionnaire,
} from "actions/questionnaires";
import { Formik, Form } from "formik";
import { Button } from "@mui/material";
import Question from "./question";
import { reduce, propOr, assoc, prop } from "ramda";
import { transformQuestionAnswers } from "./helpers";
import "./sign-questionnaire.scss";
import useTranslate from "hooks/use-translate-language";
import queryString from "query-string";
import { useSelector } from "react-redux";
import selectors from "selectors";
import { checkToken } from "actions/auth";

const setInitialValues = reduce(
  (acc, cur) =>
    cur.active === true ? assoc(prop("question_id", cur), "", acc) : acc,
  {}
);

const SignQuestions = () => {
  const authIsValid = useSelector(selectors.getUserAuthValid);
  const translate = useTranslate();
  const { questionnaire_id: questionnaire_assignment_id } = useParams();

  const location = useLocation();
  const isSignQuestionnaire = useState(location.pathname.includes("sign-questionnaires"));
  const tokenString = queryString.parse(location.search).token;
  
  const [validToken, setIsValidToken] = useState(null);
  useEffect(() => {
    if (isSignQuestionnaire) {
      dispatch(checkToken(tokenString)).then((result) => {
        setIsValidToken(result);
      });
    }
  }, [tokenString, dispatch]);

  const history = useHistory();
  const dispatch = useDispatch();
  const [questionnair, setQuestionnaire] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  useEffect(() => {
    setIsFetching(true);
    let fetch_questionnaire_details;
    if (authIsValid) {
      fetch_questionnaire_details = fetchQuestionnaireDetails(questionnaire_assignment_id);
    } else {
      fetch_questionnaire_details = fetchQuestionnaireDetails(questionnaire_assignment_id, tokenString);
    }
    dispatch(fetch_questionnaire_details)
      .then(({ success, message, questionnaire }) => {
        if (success) setQuestionnaire(questionnaire);
        else setErrorMsg(message);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [dispatch, questionnaire_assignment_id]);
  return (
    <Page.Container>
      {!isSignQuestionnaire ? (
        <Page.Header
          title={translate("completeQuestionnaire")}
          goBack="/questionnaires"
        />
      ) : ""}
      {authIsValid || validToken ? (
        <Page.Content
          isFetching={isFetching}
          isEmpty={questionnair && questionnair.questions?.length === 0}
          emptyMessage={translate("questionnaireNotAvailable")}
        >
          <div className="sign-questionnaire">
            <h2>{questionnair.description}</h2>
            {!errorMsg ? (
              <Formik
                initialValues={setInitialValues(
                  propOr([], "questions", questionnair)
                )}
                onSubmit={async (values) => {
                  const { product_id, questionnaire_id } = questionnair;
                  const questions = transformQuestionAnswers(
                    values,
                    questionnair?.questions.filter(
                      ({ active }) => active === true
                    )
                  );
                  await dispatch(
                    submitQuestionnaire({
                      product_id,
                      questionnaire_id,
                      questionnaire_assignment_id,
                      questions,
                      digital_consent_accept: true
                    }, tokenString)
                  );
                }}
                enableReinitialize={true}
              >
                {({ isValid, isSubmitting }) => (
                  <Form className="questionnaire-form">
                    {questionnair.questions
                      ?.filter(({ active }) => active)
                      .map((q) => (
                        <div className="question-container" key={q.question_id}>
                          <Question {...q} />
                        </div>
                      ))}
                    <div
                      style={{
                        margin: 10,
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      {isSubmitting ? (
                        <Spinner />
                      ) : (
                        <Button
                          type="submit"
                          variant="outlined"
                          color="primary"
                          disabled={!isValid}
                        >
                          {translate("submit")}
                        </Button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h2 style={{ color: "red", marginBottom: 20 }}>{errorMsg}</h2>
                <Button
                  onClick={history.goBack}
                  variant="outlined"
                  color="primary"
                >
                  {translate("back")}
                </Button>
              </div>
            )}
          </div>
        </Page.Content>
      ) : ""}
      
    </Page.Container>
  );
};

export default SignQuestions;
