import { useEffect, useState } from "react";
import selectors from "selectors";
import { fetchPatientSignatures } from "actions/signatures";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Card, Page } from "components/ui";
import { formatDate } from "utils/misc";
import CalandarIcon from "assets/images/calendar.png";
import { DOCUSIGN_HOST } from "environment";
import "./signature.scss";
import useTranslate from "hooks/use-translate-language";

const Signatures = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const patientSignatures = useSelector(selectors.getPatientSignatures);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    setIsFetching(true);
    dispatch(fetchPatientSignatures()).finally(() => {
      setIsFetching(false);
    });
  }, [dispatch]);
  const authToken = useSelector(selectors.getAuthenticationToken);

  return (
    <Page.Container>
      <Page.Header title={translate("mySignatures")} goBack />
      <Page.Content
        isFetching={isFetching}
        isEmpty={patientSignatures.length === 0}
        emptyMessage={translate("noDocuments")}
      >
        {patientSignatures.map(sig => (
          <Card
            key={sig.signature_assignment_id}
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              margin: 10
            }}
          >
            <div>
              <h4 className="signature-title">{sig.title}</h4>
              <div className="date-sent-container">
                <img src={CalandarIcon} alt="" />
                <span className="signature-date-sent">
                  {formatDate(sig.date_sent)}
                </span>
              </div>
              {sig.status === 0 ? (
                <div className="signature-status pending">
                  {translate("unsigned")}
                </div>
              ) : (
                <div className="signature-status signed">
                  {`${translate("signed")}: ${formatDate(sig.date_signed)}`}
                </div>
              )}
            </div>
            <div className="signature-action-btn">
              {sig.status === 0 ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ fontFamily: "Poppins-SemiBold" }}
                  onClick={() => {
                    const { innerWidth: width, innerHeight: height } = window;
                    const url = `${DOCUSIGN_HOST}/signature?token=${authToken}&template_id=${sig.html_document_template_id}&signature_id=${sig.signature_assignment_id}`;
                    var new_window = window.open(
                      url,
                      "_blank",
                      "height=" + height + ", width=" + width
                    );
                    var timer = setInterval(() => {
                      if (new_window.closed) {
                        clearInterval(timer);
                        dispatch(fetchPatientSignatures());
                      }
                    }, 1000);
                  }}
                >
                  {translate("signNow")}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ fontFamily: "Poppins-SemiBold" }}
                  onClick={() => {
                    window.open(sig.download_url);
                  }}
                >
                  {translate("view")}
                </Button>
              )}
            </div>
          </Card>
        ))}
      </Page.Content>
    </Page.Container>
  );
};

export default Signatures;
