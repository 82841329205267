import PropTypes from "prop-types";
import S3Logo from "assets/images/icon_trans.png";

const EmptyView = ({ message }) => (
  <div
    style={{
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    }}
  >
    <img style={{ width: 120, height: 120 }} src={S3Logo} alt="" />
    <div
      style={{
        marginTop: 12,
        marginStart: 48,
        marginEnd: 48,
        fontSize: 16,
        color: "#24ABFB",
        fontFamily: "Poppins-Medium",
        textAlign: "center"
      }}
    >
      {message}
    </div>
  </div>
);

EmptyView.propTypes = { message: PropTypes.string };

export default EmptyView;
