import {
  setPatientsEquipment,
  setEquipmentFaqs,
  setEquipmentVideos,
  setEquipmentDetails
} from "reducers/products";
import { API } from "actions/api";

export const fetchPatientsEquipment = token => async dispatch => {
  try {
    const { products } = await dispatch({
      type: API,
      route: "patient_portal/mobile/users/products",
      publicRoute: token ? true : false,
      method: "GET",
      headers: token
        ? {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        : undefined
    });
    dispatch(setPatientsEquipment(products));
    return products;
  } catch (error) {
    console.error(error.message);
  }
};

export const fetchEquipmentDetails = product_id => async dispatch => {
  try {
    const { product_details } = await dispatch({
      type: API,
      route: `patient_portal/mobile/products/${product_id}`,
      method: "GET"
    });
    dispatch(setEquipmentDetails({ product_id, product_details }));
    // dispatch(setPatientsEquipment(result));
    return product_details;
  } catch (error) {
    console.error(error.message);
  }
};
export const fetchEquipmentFaqs = product_id => async dispatch => {
  try {
    const { faqs } = await dispatch({
      type: API,
      route: `patient_portal/mobile/products/${product_id}/faqs`,
      method: "GET"
    });
    dispatch(setEquipmentFaqs({ product_id, faqs }));
    return { faqs };
  } catch (error) {
    console.error(error.message);
  }
};

export const fetchEquipmentVideos = product_id => async dispatch => {
  try {
    const { media } = await dispatch({
      type: API,
      route: `patient_portal/mobile/products/${product_id}/videos`,
      method: "GET"
    });
    dispatch(setEquipmentVideos({ product_id, media }));
    // dispatch(setPatientsEquipment(result));
    return media;
  } catch (error) {
    console.error(error.message);
  }
};

export const fetchRecalledEquipment = () => async dispatch => {
  try {
    const { products } = await dispatch({
      type: API,
      route: "inventory_items/recalled_items",
      method: "GET"
    });
    return products;
  } catch (error) {
    console.error(error.message);
  }
};
