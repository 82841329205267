import { find, prop } from "ramda";

export const transformQuestionAnswers = (values, questions) => {
  const result = questions.map(
    ({ question_id, question_type, question_text, options }) => {
      if (question_type === "2") {
        const choosenOption = find(
          ({ option_id }) => option_id === prop(question_id, values),
          options
        );
        return {
          question_id,
          question_type,
          question_text,
          answer_value: "",
          options: [{ ...choosenOption, chosen: true }]
        };
      } else {
        return {
          question_id,
          question_type,
          question_text,
          answer_value: prop(question_id, values)
        };
      }
    }
  );
  return result;
};
