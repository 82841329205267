import { TextInput, SelectInput, PhoneNumberInput } from "components/form";
import useTranslate from "hooks/use-translate-language";
import { STATES } from "utils/enum";

const EditPhysician = () => {
  const translate = useTranslate();
  return (
    <div>
      <h4>{translate("physicianInformation")}</h4>
      <TextInput label={translate("name")} name="physician_information.name" />
      <PhoneNumberInput
        label={translate("phoneNumber")}
        name="physician_information.phone"
        type="tel"
      />
      <PhoneNumberInput
        label={translate("faxNumber")}
        name="physician_information.fax"
        type="tel"
      />
      <div className="address">
        <TextInput
          label={translate("streetAddress")}
          name="physician_information.address.street_address"
        />
        <TextInput
          label={translate("city")}
          name="physician_information.address.city"
        />
        <SelectInput
          label={translate("state")}
          name="physician_information.address.state"
          options={STATES}
        />
        <TextInput
          label={translate("zip")}
          name="physician_information.address.zip"
        />
      </div>
    </div>
  );
};

export default EditPhysician;
