import { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CssBaseline } from "@mui/material";
import { useDispatch } from "react-redux";
import SideBar from "./side-bar";
import TwilioChatNowWindow from "components/twilio-chat/chat-now-window";
import { fetchPatientFormAttributes } from "actions/user";
import "./layout.scss";

export const AppLayoutContext = createContext();

const AppLayout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPatientFormAttributes());
  }, [dispatch]);
  return (
    <div className="app">
      <CssBaseline />
      <SideBar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <AppLayoutContext.Provider
        value={{
          handleDrawerToggle,
          mobileOpen
        }}
      >
        <main className="main">
          <div className="toolbar" />
          <div className="main-content">{children}</div>
        </main>
        <TwilioChatNowWindow />
      </AppLayoutContext.Provider>
    </div>
  );
};

export default AppLayout;

AppLayout.propTypes = { children: PropTypes.node };
