import PropTypes from "prop-types";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import { useFormikContext } from "formik";
import { path } from "ramda";

const getPath = (name) => name.split(".");

const RadioInput = ({ name, label, options, ...props }) => {
  const { values, handleBlur, handleChange, errors, touched } =
    useFormikContext();
  const namePath = getPath(name);
  const error = path(namePath, touched) && path(namePath, errors);
  const value = path(namePath, values);
  return (
    <FormControl component="fieldset" style={{ width: "100%" }} error={!!error}>
      <FormHelperText>{error}</FormHelperText>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        aria-label={name}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        {...props}
      >
        {options.map(({ text, value }) => (
          <FormControlLabel
            value={value}
            control={<Radio color="primary" />}
            label={text}
            key={value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
export default RadioInput;

RadioInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
};
