import { useEffect } from "react";
import PropTypes from "prop-types";
import { TextInput, SelectInput, RadioGroupField } from "components/form";
import { STATES } from "utils/enum";
import { useFormikContext, Field } from "formik";
import useTranslate from "hooks/use-translate-language";
import selectors from "selectors";
import { useSelector } from "react-redux";

const ShippingDetails = ({ address }) => {
  const translate = useTranslate();
  const { values, setFieldValue } = useFormikContext();
  const companyOrderFormOptions = useSelector(
    selectors.getCompanyOrderFormOptions
  );
  useEffect(() => {
    if (values.ordered_by === "patient") setFieldValue("caregiver_name", "");
  }, [values.ordered_by, setFieldValue]);

  useEffect(() => {
    if (values.changed_address === false) {
      setFieldValue("address", address);
    }
  }, [values.changed_address]);
  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <h3>{translate("shippingDetails")}</h3>
      <div>
        <Field
          component={RadioGroupField}
          name="changed_address"
          label={translate("addressHasChanged")}
          value={values.changed_address}
          options={[
            { text: translate("no"), value: false },
            { text: translate("yes"), value: true }
          ]}
        />
        <div>
          {values.changed_address && (
            <Field
              component={RadioGroupField}
              name="address_change_type"
              label=""
              value={values.address_change_type}
              options={[
                { value: "P", text: "Permanent Address Change" },
                { value: "T", text: "Temporary Address Change" }
              ]}
            />
          )}
        </div>
      </div>
      <TextInput
        name="address.street_address"
        label={translate("streetAddress")}
        disabled={!values.changed_address}
      />
      <TextInput
        name="address.street_address2"
        label={translate("streetAddress2")}
        disabled={!values.changed_address}
      />
      <TextInput
        name="address.city"
        label={translate("city")}
        disabled={!values.changed_address}
      />
      <SelectInput
        name="address.state"
        label={translate("state")}
        options={STATES}
        disabled={!values.changed_address}
      />
      <TextInput
        name="address.zip"
        label={translate("zip")}
        disabled={!values.changed_address}
      />
      <div style={{ marginTop: 20 }}>
        {companyOrderFormOptions.person_placing_order && (
          <div>
            <label>{translate("personPlacingOrder")}</label>
            <SelectInput
              name="ordered_by"
              options={translate("patientOrCaregiver")}
            />
          </div>
        )}
        {values.ordered_by === "caregiver" && (
          <TextInput name="caregiver_name" label={translate("caregiverName")} />
        )}
      </div>

      <div style={{ marginTop: 20 }}>
        {companyOrderFormOptions.office_pickup_question && (
          <div>
            <label>{translate("officePickupQuestion")}</label>
            <SelectInput
              name="office_pickup"
              options={[
                { text: translate("no"), value: false },
                { text: translate("yes"), value: true }
              ]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ShippingDetails;
ShippingDetails.propTypes = {
  address: PropTypes.shape({
    street_address: PropTypes.string,
    street_addres2: PropTypes.string,
    city: PropTypes.string,
    zip: PropTypes.string
  }).isRequired
};
