import { SelectInput } from "components/form";
import useTranslate from "hooks/use-translate-language";
import { nightsPerWeekOptions } from "./options";
import { useSelector } from "react-redux";
import selectors from "selectors";

const Ussage = () => {
  const translate = useTranslate();

  const companyOrderFormOptions = useSelector(
    selectors.getCompanyOrderFormOptions
  );

  return (
    <div className="additional-info" style={{ width: "100%" }}>
      <h3 style={{ marginBottom: 20 }}>{translate("equipmentUsage")}</h3>
      {companyOrderFormOptions.usage && (
        <div>
          <SelectInput
            label={translate("hoursPerNightQuestion")}
            name="hours_per_night"
            options={translate("hoursPerNightOptions")}
            style={{ marginBottom: 20 }}
          />
          <SelectInput
            label={translate("nightsPerWeekQuestion")}
            name="nights_per_week"
            options={nightsPerWeekOptions}
            style={{ marginBottom: 20 }}
          />
        </div>
      )}
      <div
        className="order-form-warning"
        style={{ marginTop: 10, marginBottom: 30 }}
      >
        {translate("adequateUseOfPAP")}
      </div>
      {companyOrderFormOptions.saw_physician_last && (
        <SelectInput
          label={translate("sawSleepPhysician")}
          name="saw_physician_last"
          options={translate("lastSawPhysicianOptions")}
          style={{ marginBottom: 20 }}
        />
      )}
    </div>
  );
};

export default Ussage;
