import { memo } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function ProtectedRoute({ children, allowed, redirectPath, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        allowed ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

ProtectedRoute.defaultProps = {
  redirectPath: "/dashboard"
};

ProtectedRoute.propTypes = {
  redirectPath: PropTypes.string,
  allowed: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

export default memo(ProtectedRoute);
