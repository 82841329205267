import React from "react";
import selectors from "selectors";
import { useSelector } from "react-redux";
import useTranslate from "hooks/use-translate-language";

const EligibilityInfo = () => {
  const translate = useTranslate();
  const patientInfo = useSelector(selectors.getPatientInfo);

  if (!patientInfo.insurance_eligibility_date) return null;
  return (
    <>
      <div
        className="order-form-info"
        style={{
          fontWeight: "normal",
          fontSize: 16,
          backgroundColor: "#fafafa"
        }}
      >
        {translate("indicatorNotice")}
        {translate("noIndicatorNotice")}
        <div style={{ display: "flex", marginTop: "10px" }}>
          <div className={"eligible"}>Eligible</div>
          <span style={{ marginLeft: "50px" }}>
            {" "}
            {translate("youAreEligible")}
          </span>
        </div>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <div className={"notEligible"}>01/01/2023</div>
          <span style={{ marginLeft: "50px" }}>
            {translate("youAreNotEligible")}
          </span>
        </div>
        {translate("selectingNonEligibleItem")}
      </div>
      <hr style={{ backgroundColor: "#ccc", height: 1, border: "none" }} />
    </>
  );
};

EligibilityInfo.defaultProps = {};

EligibilityInfo.propTypes = {};

export default EligibilityInfo;
