import React from "react";
import PropTypes from "prop-types";
import ChooseSupplies from "./choose-supplies";
import FormElement from "./form-elements";
import { Formik, Field, Form } from "formik";
import "./styles.scss";
import Dysfunctions from "./dysfunctions.jsx";
import PhysicianForm from "./physician";
import InsuranceForm from "./insurance.jsx";
import AddressForm from "./address";
import { RadioGroupField } from "components/form";
import getInitialValues from "./initial-values";
import OrderFormValidationSchema from "./validation";
import { OrderFormContext } from "./provider";
import { Spinner } from "components/ui";

const OrderForm = () => {
  const {
    patientId,
    patientInfo,
    productLine,
    eligibleSupplies,
    supplyPeriodOptions,
    insurance_companies,
    questions,
    last_order,
    order_create_method,
    submitOrderAPI
  } = React.useContext(OrderFormContext);

  var groupedQuestions =
    questions?.reduce((acc, question) => {
      let groupKey = question.category;
      if (groupKey.includes("Dysfunctions|")) {
        const productType = question.category.split("|")[1] ?? "";
        return {
          ...acc,
          Dysfunctions: [
            ...(acc?.Dysfunctions ?? []),
            { ...question, productType }
          ]
        };
      }

      if (!acc[groupKey]) {
        acc[groupKey] = [{ ...question }];
      } else {
        acc[groupKey] = [...acc[groupKey], { ...question }];
      }
      return acc;
    }, {}) ?? {};

  const validationSchema = OrderFormValidationSchema(groupedQuestions);

  const initialValues = getInitialValues({
    patientId,
    eligibleSupplies,
    supplyPeriodOptions,
    productLine,
    patientInfo,
    last_order,
    questions
  });

  return (
    <Formik
      initialValues={{
        ...initialValues,
        order_create_method: order_create_method ?? "Patient Portal"
      }}
      onSubmit={async values => {
        await submitOrderAPI.fetchData(values);
      }}
      validationSchema={validationSchema}
    >
      {({ values, errors, isSubmitting }) => (
        <Form
          style={{ minHeight: 300, padding: 25 }}
          className="order-form-new"
        >
          <ChooseSupplies />
          <hr />
          <h4 style={{ paddingBottom: 15 }}>
            Please Review The Following Information
          </h4>
          <div>
            {groupedQuestions.Usage?.map(x => (
              <FormElement key={x.id} {...x} />
            ))}
          </div>
          <div>
            {groupedQuestions["Additional Info"]?.map(x => (
              <FormElement key={x.id} {...x} />
            ))}
          </div>
          <Dysfunctions questions={groupedQuestions.Dysfunctions} />
          <hr />
          <PhysicianForm
            physician={patientInfo?.physician ?? {}}
            questions={groupedQuestions.Physician ?? []}
          />
          <hr />
          <InsuranceForm
            insuranceOptions={insurance_companies ?? []}
            patientInsurances={patientInfo?.insurances ?? []}
            questions={groupedQuestions.Insurance ?? []}
          />
          <hr />
          <AddressForm
            patientInfo={patientInfo}
            questions={groupedQuestions.Address ?? []}
          />
          <hr />
          <div>
            <span style={{ flex: 1 }}>
              <Field
                component={RadioGroupField}
                name="supply_period"
                label="How many days of supplies"
                value={values.supply_period}
                options={supplyPeriodOptions}
              />
            </span>
            {groupedQuestions["Misc"]?.map(x => (
              <FormElement key={x.id} {...x} />
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyItems: "flex-start",
              alignItems: "flex-end",
              width: "100%"
            }}
          >
            {isSubmitting ? <Spinner /> : <button type="submit">Submit</button>}
          </div>
          {JSON.stringify(isSubmitting)}
          <div>DEBUGING(validation errors): {JSON.stringify(errors)}</div>
        </Form>
      )}
    </Formik>
  );
};

OrderForm.propTypes = {
  productLine: PropTypes.string,
  itemEligibility: PropTypes.arrayOf(PropTypes.object),
  questions: PropTypes.arrayOf(PropTypes.object),
  supply_options: PropTypes.arrayOf(PropTypes.object),
  supply_period_options: PropTypes.arrayOf(PropTypes.object),
  insurance_companies: PropTypes.arrayOf(PropTypes.object),
  patientInfo: PropTypes.object
};

export default OrderForm;
