import { Card } from "components/ui";
import PropTypes from "prop-types";
import BackgroundImg from "assets/images/dashbord_graphic.png";

const DashboardItem = ({ backgroundColor, onClick, children, className }) => (
  <Card
    className={`dashboard-card ${className}`}
    style={{
      backgroundColor,
      backgroundImage: `url(${BackgroundImg})`
    }}
    onClick={onClick}
  >
    <div className="dashboard-layout">{children}</div>
  </Card>
);

DashboardItem.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string
};
export default DashboardItem;
