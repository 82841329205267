import PropTypes from "prop-types";
import { Avatar } from "@mui/material";
import { connect } from "react-redux";
import selectors from "selectors";
import useTranslate from "hooks/use-translate-language";

const UserWelcome = ({ userDetails }) => {
  const translate = useTranslate();
  return (
    <div className="user-welcome">
      <Avatar alt="user" src={userDetails.profile_photo} />
      <div className="welcome-container">
        <div className="welcome-label">{translate("welcome")}</div>
        <div className="patient-name">{userDetails.name}</div>
      </div>
    </div>
  );
};

UserWelcome.propTypes = {
  userDetails: PropTypes.shape({
    profile_photo: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export default connect((state) => ({
  userDetails: selectors.getUserDetails(state),
}))(UserWelcome);
