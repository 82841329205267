import PropTypes from "prop-types";
import { Card, Page } from "components/ui";
import { Button } from "@mui/material";
import { propOr } from "ramda";
import useTranslate from "hooks/use-translate-language";

const ProductVideos = ({ product_id, product }) => {
  const translate = useTranslate();
  const videos = propOr([], "videos", product);
  return (
    <Page.Container>
      <Page.Header
        title={translate("productVideos")}
        goBack={`/products/${product_id}`}
      />
      <Page.Content
        isEmpty={videos.length === 0}
        emptyMessage={translate("noProductMedia")}
      >
        {videos.map(({ media_id, product_name, link }) => (
          <Card style={{ margin: "5px 0px", padding: 5 }} key={media_id}>
            <div
              key={media_id}
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "#141C36",
                  fontFamily: "Poppins-Regular",
                }}
              >
                {product_name}
              </div>
              <Button
                color="primary"
                onClick={() => {
                  window.open(link);
                }}
              >
                {translate("view")}
              </Button>
            </div>
          </Card>
        ))}
      </Page.Content>
    </Page.Container>
  );
};

ProductVideos.propTypes = {
  product: PropTypes.object.isRequired,
  product_id: PropTypes.string.isRequired,
};

export default ProductVideos;
