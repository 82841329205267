export default {
  shippingDate: {
    english: "Shipping Date",
    spanish: "Fecha de envío"
  },
  carrier: {
    english: "Carrier",
    spanish: "Portador"
  },
  noTrackingNumbers: {
    english: "You have no tracking numbers",
    spanish: "No tienes números de seguimiento"
  }
};
