export default {
  // Index
  // documents listed in dashboard
  // noDocuments listed in signatures

  // File-Upload-Input
  remove: {
    english: "Remove",
    spanish: "Eliminar",
  },
  uploadAFile: {
    english: "Upload a file",
    spanish: "Cargar un archivo",
  },
  // Document
  // view listed in signatures
  delete: {
    english: "Delete",
    spanish: "Borrar",
  },

  //Upload-Document-Form
  uploadDocument: {
    english: "Upload Document",
    spanish: "Subir documento",
  },
  title: {
    english: "Title",
    spanish: "Título",
  },
  selectCategory: {
    english: "Select Category",
    spanish: "Selecciona una categoría",
  },

  "Signature Document Template": {
    english: "Signature Document Template",
    spanish: "Plantilla de documento de firma",
  },
  "Signature Document Instance": {
    english: "Signature Document Instance",
    spanish: "Instancia de documento de firma",
  },
  "User Photo": {
    english: "User Photo",
    spanish: "Foto de usuario",
  },
  "Company Photo": {
    english: "Company Photo",
    spanish: "Foto de la empresa",
  },
  "Insurance Card Back": {
    english: "Insurance Card Back",
    spanish: "Parte posterior de la tarjeta de seguro",
  },
  "Insurance Card Front": {
    english: "Insurance Card Front",
    spanish: "Anverso de la tarjeta de seguro",
  },
  "Product Photo": {
    english: "Product Photo",
    spanish: "Foto del producto",
  },
  Photo: {
    english: "Photo",
    spanish: "Foto",
  },
  "Document File": {
    english: "Document File",
    spanish: "Archivo de documento",
  },
  "Document Photo": {
    english: "Document Photo",
    spanish: "Foto del documento",
  },
  "Ins card": {
    english: "Ins card",
    spanish: "Anverso de la tarjeta de seguro",
  },
  // notes listed in profile
  // submit listed in profile
};
