import { Checkbox } from "@mui/material";
import { useFormikContext } from "formik";
import useTranslate from "hooks/use-translate-language";
import { RadioInput } from "components/form";
import { useSelector } from "react-redux";
import selectors from "selectors";

const OrderSummary = () => {
  const { values } = useFormikContext();
  const translate = useTranslate();

  const companyOrderFormOptions = useSelector(
    selectors.getCompanyOrderFormOptions
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <h4>{translate("orderSummary")}</h4>
      <div style={{ marginTop: "1%", marginBottom: "1%" }}>
        <RadioInput
          name={"supply_period"}
          row
          options={companyOrderFormOptions?.supply_period_options.map(
            period => ({
              text: `${period} Day Supply`,
              value: String(period)
            })
          )}
          label={translate("howManyDaysOfSupplies")}
        />
      </div>
      <div className="order-form-prompt">
        {translate("youWillReceive") + " "}
        <span className="day-supply" style={{ fontWeight: "bold" }}>
          {values.supply_period}
        </span>{" "}
        {translate("daySupplyOfItems")}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          background: "white",
          padding: 3,
          marginTop: 10,
          marginBottom: 20
        }}
      >
        {values.requested_equipment.map(equipment => (
          <div key={equipment} style={{ display: "flex", padding: 5 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox checked={true} readOnly color="primary" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 36
              }}
            >
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  letterSpacing: "0.00938em"
                }}
              >
                {
                  translate("supplyOptions").find(x => x.value == equipment)
                    ?.text
                }
              </div>
              <div
                style={{
                  color: "#787878",
                  fontFamily: "Poppins-Regular",
                  marginBottom: 5
                }}
              >
                {
                  translate("supplyOptions").find(x => x.value == equipment)
                    ?.description
                }
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="order-form-warning">
        {translate("orderFormWarning") +
          " " +
          companyOrderFormOptions.last_day_to_order_message}
      </div>
    </div>
  );
};

export default OrderSummary;
