import { Page } from "components/ui";
import { useLocation } from "react-router-dom";
import { propOr } from "ramda";
import TwilioVideo from "components/twilio-video";
import useTwilioVideo from "hooks/use-twilio-video";

const VideoChat = () => {
  const { state } = useLocation();
  const videoChat = propOr({}, "video_chat", state);
  const { members, room_name, publish_video_track, publish_audio_track } =
    videoChat;
  const video = useTwilioVideo({
    room_name,
    publish_video_track,
    publish_audio_track,
  });
  return (
    <Page.Container style={{ margin: 0, padding: 0 }}>
      <Page.Header title="Video Chat" goBack />
      <Page.Content>
        <TwilioVideo
          {...video}
          members={members}
          publishLocalMedia={publish_video_track}
        />
      </Page.Content>
    </Page.Container>
  );
};

export default VideoChat;
