import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import * as R from "ramda";

/* ========= Reducer ======== */
export const orders = createSlice({
  name: "orders",
  initialState: [],
  reducers: {
    setPatientOrders: (state, { payload }) => payload,
  },
});

/* ========= Actions ======== */
export const { setPatientOrders } = orders.actions;

/* ========= SELECTORS ======== */
const getPatientOrders = R.propOr([], "orders");

const hasEqThresshold = R.allPass([
  R.includes("mask"),
  R.includes("tubing"),
  (eq) => R.includes("filter", eq) || R.includes("filters", eq),
  (eq) => R.includes("seal", eq) || R.includes("seals", eq),
]);

const getAvailableOrderToDuplicate = createSelector(
  [getPatientOrders],
  R.pipe(
    R.filter(
      ({ status, requested_equipment }) =>
        R.toLower(status) === "completed" &&
        hasEqThresshold(requested_equipment)
    ),
    R.sortWith([R.descend(R.prop("order_date"))]),
    R.nth(0)
  )
);

export const selectors = { getPatientOrders, getAvailableOrderToDuplicate };

export default orders.reducer;
