import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, Button } from "@mui/material";

const LoadingButton = ({ isFetching, disabled, children, ...rest }) => {
  return (
    <Button disabled={isFetching || disabled} {...rest}>
      {isFetching ? <CircularProgress size={12} /> : children}
    </Button>
  );
};

LoadingButton.defaultProps = {};

LoadingButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired
};

export default LoadingButton;
