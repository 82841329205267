import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import selectors from "selectors";
import { Formik } from "formik";
import { fetchPatientProfile, fetchPatientFormAttributes } from "actions/user";
import getInitialValues from "components/order-form/initial-values";
import { Page } from "components/ui";
import OrderFormContainer from "components/order-form/order-form";
import { submitOrder, fetchPatientOrders } from "actions/orders";
import queryString from "query-string";
import useTranslate from "hooks/use-translate-language";
import { getOrderFormValidationSchema } from "components/order-form/validation";
import { AuthorizePatient } from "./authorize-patient";

const EmailOrder = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const [isFetching, setIsFetchingProfile] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const profile = useSelector(selectors.getPatientProfile);

  const location = useLocation();
  const authToken = useSelector(selectors.getAuthenticationToken);
  const tokenString = queryString.parse(location.search).token ?? authToken;

  const origin = getOrderFormOrigin(location.search);

  useEffect(() => {
    setIsFetchingProfile(true);
    dispatch(
      fetchPatientOrders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenString}`
      })
    );
    dispatch(
      fetchPatientFormAttributes({ Authorization: `Bearer ${tokenString}` })
    );
    dispatch(
      fetchPatientProfile({ Authorization: `Bearer ${tokenString}` })
    ).finally(() => {
      setIsFetchingProfile(false);
    });
  }, [tokenString, dispatch]);

  const companyOrderFormOptions = useSelector(
    selectors.getCompanyOrderFormOptions
  );

  const OrderFormValidationSchema = getOrderFormValidationSchema(companyOrderFormOptions, translate);

  return (
    <Page.Container style={{ height: "100vh", margin: 0 }}>
      <Page.Content isFetching={isFetching}>
        <Formik
          initialValues={{
            ...getInitialValues(profile, companyOrderFormOptions),
            order_create_method: origin
          }}
          onSubmit={async values => {
            await dispatch(
              submitOrder(values, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenString}`
              })
            );
          }}
          initialTouched={{
            caregiver_name: true,
            changed_insurance: true,
            requested_equipment: true
          }}
          validationSchema={OrderFormValidationSchema}
          enableReinitialize
        >
          {() => (
            <div
              style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <OrderFormContainer
                profile={profile}
                setActiveStep={setActiveStep}
                activeStep={activeStep}
                token={tokenString}
              />
            </div>
          )}
        </Formik>
      </Page.Content>
    </Page.Container>
  );
};

export default () => (
  <AuthorizePatient>
    <EmailOrder />
  </AuthorizePatient>
);

export const getOrderFormOrigin = urlParams => {
  const params = queryString.parse(urlParams);
  if (params.origin == "rotech_portal") {
    return "External Portal";
  } else {
    const origin = params.auth
      ? "Patient Portal From SMS"
      : "Patient Portal From Email";
    return origin;
  }
};
