import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import FormElement from "./form-elements";

const Dysfunctions = ({ questions }) => {
  const { values } = useFormikContext();
  return (
    <div>
      {questions?.length > 0 && values.requested_equipment.length > 0 && (
        <div>
          {/* <h3>Dysfunctions</h3> */}
          <div>
            {questions
              ?.filter(({ productType }) =>
                values.requested_equipment.includes(productType?.toLowerCase())
              )
              .map(x => (
                <FormElement key={x.id} {...x} />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

Dysfunctions.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Dysfunctions;
