import React, { useState, useEffect } from "react";
import { Spinner } from "components/ui";
import { Button } from "@mui/material";
import Logo from "assets/images/icon_trans.png";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkToken } from "actions/auth";
import { pushOutreachWithToken } from "actions/user";
import "./push-outreach.scss";
import queryString from "query-string";
import { decodeJwt } from "utils/misc";
import { setLanguage } from "reducers/auth";
import useTranslate from "hooks/use-translate-language";

const PushOutreach = () => {
  const translate = useTranslate();
  const location = useLocation();
  const tokenString = queryString.parse(location.search).token;
  const decoded = decodeJwt(tokenString);
  const speaksSpanish = decoded.speaks_spanish;
  const dispatch = useDispatch();
  const [validToken, setIsValidToken] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    dispatch(checkToken(tokenString)).then((result) => {
      setIsValidToken(result);
    });
  }, [tokenString, dispatch]);

  useEffect(() => {
    dispatch(setLanguage(speaksSpanish));
  }, []);
  return (
    <div className="login-screen push-outreach">
      <div className="login-screen-header">
        <img src={Logo} className="login-logo" alt="logo" />
      </div>
      {validToken === null && (
        <div className="validating-container">
          <Spinner />
        </div>
      )}
      {validToken === true && (
        <div className="push-outreach-container">
          <div className="push-outreach-text">
            {translate("stopReceivingEmails")}
          </div>
          <Button
            variant="contained"
            disabled={isFetching}
            onClick={async () => {
              try {
                setIsFetching(true);
                await dispatch(
                  pushOutreachWithToken(tokenString, speaksSpanish)
                );
              } finally {
                setIsFetching(false);
              }
            }}
          >
            {translate("confirm")}
          </Button>
        </div>
      )}
      {validToken === false && (
        <div className="validation-error">
          <h3>{translate("sorryLinkNoLongerValid")}</h3>
        </div>
      )}
    </div>
  );
};

export default PushOutreach;
