import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
import createSagaMiddleware from "redux-saga";
import ApiMiddleware from "middleware/api-middleware";
import appReducer from "reducers";
import sagas from "./sagas";

export default () => {
  const sagaMiddleware = createSagaMiddleware();

  const middleware = [
    sagaMiddleware,
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredActionPaths: ["meta.arg", "payload.timestamp"],
        ignoredPaths: ["meta.arg", "payload.timestamp"]
      }
    }),
    ApiMiddleware
  ];
  const store = configureStore({
    reducer: appReducer,
    middleware
  });
  sagaMiddleware.run(sagas);
  return store;
};
