import PropTypes from "prop-types";
import { RadioInput, SelectInput, TextInput } from "components/form";
import useTranslate from "hooks/use-translate-language";

const Question = ({ question_type, question_text, question_id, options }) => {
  const translate = useTranslate();
  switch (question_type) {
    case "3":
      return <TextInput name={question_id} label={question_text} />;
    case "2":
      return (
        <SelectInput
          name={question_id}
          options={options.map((o) => ({
            text: o.option_text,
            value: o.option_id,
          }))}
          label={question_text}
        />
      );
    case "1":
      return (
        <RadioInput
          name={question_id}
          options={[
            { text: translate("no"), value: "false" },
            { text: translate("yes"), value: "true" },
          ]}
          label={question_text}
        />
      );
    case "4":
      return (
        <SelectInput
          name={question_id}
          options={[...Array(10)].map((_, index) => ({
            text: `${index + 1}`,
            value: `${index + 1}`,
          }))}
          label={question_text}
        />
      );
    default:
      return null;
  }
};

Question.propTypes = {
  question_type: PropTypes.string.isRequired,
  question_text: PropTypes.string.isRequired,
  question_id: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      option_text: PropTypes.string,
      option_id: PropTypes.string,
    })
  ),
};

export default Question;
