import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Page } from "components/ui";
import { formatAddress, formatDate } from "utils/misc";
import { isEmpty, toLower } from "ramda";
import "../orders.scss";
import { API } from "actions/api";
import { useParams } from "react-router";
import { Card, CardContent, Divider } from "@mui/material";
import OrderResponseAccordian from "./order-response-accordian";
import useTranslate from "hooks/use-translate-language";

const OrderDetails = () => {
  const [order, setOrder] = useState({
    address: { city: "", state: "", zip: "", street_address: "" },
    compliance: {},
    dysfunctions: {},
    requested_equipment: [],
    status: ""
  });
  const [isFetching, setIsFetching] = useState(true);
  const { order_id } = useParams();
  const GetSupplyPeriodRequested = supplyPeriod => {
    if (supplyPeriod === 30) {
      return translate("thirtyDaysSupply");
    }
    if (supplyPeriod === 60) {
      return translate("sixtyDaysSupply");
    }
    if (supplyPeriod === 90) {
      return translate("ninetyDaysSupply");
    }
  };
  const dispatch = useDispatch();
  const translate = useTranslate();
  const fetchTrackingInfo = async () => {
    try {
      const { orderProfile } = await dispatch({
        type: API,
        route: `patient_portal/mobile/orders/${order_id}`,
        method: "GET"
      });
      await setOrder(orderProfile);
      setIsFetching(false);
    } catch (error) {
      console.error(error.message);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchTrackingInfo();
  }, []);

  return (
    <Page.Container>
      <Page.Header title={translate("orderDetails")} goBack />
      <Page.Content
        isFetching={isFetching}
        isEmpty={isEmpty(order)}
        emptyMessage={translate("orderDoesntExist")}
      >
        <Card
          style={{ width: "70%", alignSelf: "center" }}
          className="order-details-container"
        >
          <CardContent>
            <h2 style={{ marginBottom: 0 }}>
              {translate("invoiceNumber")}: {order.invoice_number}
            </h2>
            <span>
              {translate("status")}: {translate(toLower(order.status))}
            </span>
            <div className="order-detail-line">
              <h4>{translate("equipmentOrdered")}:</h4>
              <p>
                {order.requested_equipment
                  .map(equipment => translate(equipment))
                  .join(", ")}
              </p>
            </div>
            <Divider />
            <div className="order-detail-line">
              <h4>{translate("orderedOn")}:</h4>{" "}
              <p>{formatDate(order.order_date)}</p>
            </div>
            <Divider />

            <div className="order-detail-line">
              <h4>{translate("shippingAddress")}</h4>
              <p>{formatAddress(order.address)}</p>
            </div>

            <Divider />
            {order.tracking_number && (
              <div>
                <div className="order-detail-line">
                  <h4>{translate("trackingNumber")}:</h4>{" "}
                  <p>{order.tracking_number}</p>
                </div>
                <Divider />
              </div>
            )}

            <div className="order-detail-line">
              <h4>{translate("quantityRequested")}:</h4>
              <p>
                {GetSupplyPeriodRequested(order.supply_period)}
              </p>
            </div>
            <Divider />

            <h3 style={{ margin: 10 }}>{translate("orderFormResponses")}</h3>

            <OrderResponseAccordian order={order} />
          </CardContent>
        </Card>
      </Page.Content>
    </Page.Container>
  );
};

export default OrderDetails;
