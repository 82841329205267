import React, { useState, useEffect } from "react";
import { Spinner } from "components/ui";
import { Button } from "@mui/material";
import Logo from "assets/images/icon_trans.png";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkToken, updateLanguageWithToken } from "actions/auth";
import "./update-language.scss";
import queryString from "query-string";
import { decodeJwt } from "utils/misc";

const UpdateLanguage = () => {
  const location = useLocation();
  const tokenString = queryString.parse(location.search).token;
  const decoded = decodeJwt(tokenString);
  const dispatch = useDispatch();
  const [validToken, setIsValidToken] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    dispatch(checkToken(tokenString)).then((result) => {
      setIsValidToken(result);
    });
  }, [tokenString, dispatch]);
  return (
    <div className="login-screen update-language">
      <div className="login-screen-header">
        <img src={Logo} className="login-logo" alt="logo" />
        <div className="update-language-title">
          Update Primary Language / Actualizar el idioma principal
        </div>
      </div>
      {validToken === null && (
        <div className="validating-container">
          <Spinner />
        </div>
      )}
      {validToken === true && (
        <div className="update-language-container">
          {decoded.speaks_spanish ? (
            <div className="update-language-text">
              <div>
                {`Click this button to update your profile's language preference
                to English.`}
              </div>
              <div>
                {`Haga clic en este botón para actualizar la preferencia de idioma
                de su perfil a inglés.`}
              </div>
            </div>
          ) : (
            <div className="update-language-text">
              <div>
                {`Click this button to update your profile's language preference
                to Spanish.`}
              </div>
              <div>
                {`Haga clic en este botón para actualizar la preferencia de idioma
                de su perfil a español.`}
              </div>
            </div>
          )}
          <Button
            variant="contained"
            disabled={isFetching}
            onClick={async () => {
              try {
                setIsFetching(true);
                await dispatch(updateLanguageWithToken(tokenString));
              } finally {
                setIsFetching(false);
              }
            }}
          >
            Confirm / Confirmar
          </Button>
        </div>
      )}
      {validToken === false && (
        <div className="validation-error">
          <h3>
            Sorry, link is no longer valid / Lo sentimos, el enlace ya no es
            válido
          </h3>
        </div>
      )}
    </div>
  );
};

export default UpdateLanguage;
