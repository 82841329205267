import React, { useEffect } from "react";
import { Page, Spinner } from "components/ui";
import Logo from "assets/images/icon_trans.png";
import { useDispatch, useSelector } from "react-redux";
import "./recall-notice.scss";
import AcknowledgementForm from "./acknowledgement-form";
import { fetchPatientProfile, submitPhilipsRecall } from "actions/user";
import selectors from "selectors";
import { isEmpty } from "ramda";
import useTranslate from "hooks/use-translate-language";

const LoggedInRecallPage = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const profile = useSelector(selectors.getPatientProfile);
  useEffect(() => {
    dispatch(fetchPatientProfile());
  }, [dispatch]);
  return (
    <div className=" philips-recall">
      <Page.Header title={translate("recallNotice")} goBack />
      <div className="recall-screen-header">
        <img src={Logo} className="recall-logo" alt="logo" />
      </div>
      {isEmpty(profile) ? (
        <div className="validating-container">
          <Spinner />
        </div>
      ) : (
        <div className="philips-recall-container">
          <div className="philips-recall-form-container">
            <AcknowledgementForm
              profile={profile}
              handleSubmit={async (formValues) => {
                await dispatch(submitPhilipsRecall(formValues));
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LoggedInRecallPage;
