import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import selectors from "selectors";
import { fetchPatientNotifications } from "actions/notifications";
import { Page } from "components/ui";
import NotificationItem from "./notification-item";
import useTranslate from "hooks/use-translate-language";
import { setPatientLastBrowse } from "reducers/notifications";
import moment from "moment";

const Notifications = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const notifications = useSelector(selectors.getPatientNotifications);
  const timezone = useSelector(selectors.getUserTimeZone);

  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    setIsFetching(true);
    dispatch(fetchPatientNotifications()).then(() => {
      setIsFetching(false);
    });
    dispatch(setPatientLastBrowse(moment().tz(timezone).format("YYYY-MM-DD HH:mm:ss")));
  }, [dispatch]);

  return (
    <Page.Container>
      <Page.Header title={translate("notifications")} goBack />
      <Page.Content
        isEmpty={notifications.length === 0}
        isFetching={isFetching}
      >
        {notifications.map((notification) => (
          <NotificationItem
            key={notification.notification_id}
            {...notification}
          />
        ))}
      </Page.Content>
    </Page.Container>
  );
};

export default Notifications;
