export default {
  // Error-Boundary
  havingTrouble: {
    english: "Sorry, we seem to be having trouble",
    spanish: "Lo siento, parece que estamos teniendo problemas",
  },
  teamNotified: {
    english: "Our team has been notified",
    spanish: "Nuestro equipo ha sido notificado",
  },
  seeDetailedReport: {
    english: "Click here to send a detailed report",
    spanish: "Haga clic aquí para enviar un informe detallado",
  },
};
