export default {
  passwordExpired: {
    english: "Your Password has Expired",
    spanish: "Tu contraseña ha expirado",
  },
  passwordsDontMatch: {
    english: "Passwords do not match",
    spanish: "Las contraseñas no coinciden",
  },
  newPassword: {
    english: "New Password",
    spanish: "Nueva contraseña",
  },
  confirmNewPassword: {
    english: "Confirm New Password",
    spanish: "Confirmar nueva contraseña",
  },
  changePassword: {
    english: "Change Password",
    spanish: "Cambiar la contraseña",
  },
};
