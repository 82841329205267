import React from "react";
import { useSelector } from "react-redux";
import selectors from "selectors";
import NewOrderForm from "./index";
import OrderFormProvider from "./provider";
import useTranslate from "hooks/use-translate-language";
import { Page } from "components/ui";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom";

const NewAppOrderFormContainer = () => {
  const token = useSelector(selectors.getAuthenticationToken);
  const patientId = useSelector(selectors.getUserId);
  const translate = useTranslate();
  const history = useHistory();

  return (
    <Page.Container style={{ margin: 0 }}>
      <Page.Header
        title={translate("orderSupplies")}
        goBack="/"
        closeText="areYouSureYouWantToCancel"
      />
      <Page.Content>
        <OrderFormProvider
          patientId={patientId}
          productLine="CPAP"
          token={token}
          origin={"Patient Portal"}
          handleCreateOrderSuccess={message => {
            NotificationManager.success(message, "Order Submitted");
            history.push("/");
          }}
        >
          <NewOrderForm />
        </OrderFormProvider>
      </Page.Content>
    </Page.Container>
  );
};

export default NewAppOrderFormContainer;
