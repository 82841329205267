import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import selectors from "selectors";
import queryString from "query-string";
import { AuthorizePatient } from "components/new-email-order/authorize-patient";
import NewOrderForm from "./index";
import OrderFormProvider from "./provider";

export const getOrderFormOrigin = urlParams => {
  const params = queryString.parse(urlParams);
  switch (params.origin) {
    case "rotech_portal":
    case "External Portal":
    case "external":
      return "External Portal";
    case "sms":
    case "Patient Portal From SMS":
      return "Patient Portal From SMS";
    case "email":
    case "Patient Portal From Email":
      return "Patient Portal From Email";
    case "mobile":
    case "Mobile App":
      return "Mobile App";
    default:
      return "Patient Portal";
  }
};

const NewOrderFormContainer = () => {
  const token = useSelector(selectors.getAuthenticationToken);
  const patientId = useSelector(selectors.getUserId);
  const location = useLocation();
  const productLine = "CPAP";

  const tokenString = queryString.parse(location.search).token;
  const history = useHistory();
  const origin = getOrderFormOrigin(location.search);

  if (origin == "Mobile App")
    return (
      <OrderFormProvider
        patientId={patientId}
        productLine={productLine}
        token={tokenString}
        origin={origin}
        handleCreateOrderSuccess={message => {
          history.push({
            pathname: "/thanks",
            state: {
              message,
              shouldPostMessage: origin === "External Portal"
            }
          });
        }}
      >
        <NewOrderForm />
      </OrderFormProvider>
    );

  return (
    <AuthorizePatient>
      <OrderFormProvider
        patientId={patientId}
        productLine={productLine}
        token={tokenString ?? token}
        origin={origin}
        handleCreateOrderSuccess={message => {
          history.push({
            pathname: "/thanks",
            state: {
              message,
              shouldPostMessage: origin === "External Portal"
            }
          });
        }}
      >
        <NewOrderForm />
      </OrderFormProvider>
    </AuthorizePatient>
  );
};

export default NewOrderFormContainer;
