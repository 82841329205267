import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "@mui/material";

import { find } from "ramda";
import { SelectInput, CheckboxInput } from "components/form";
import useTranslate from "hooks/use-translate-language";

const Dysfunctions = ({ order }) => {
  const translate = useTranslate();
  const getEquipmentQuestions = equipment =>
    translate("orderEquipmentQuestions").filter(
      question => question.equipment === equipment
    );
  return (
    <div className="dysfunctions-container">
      <div style={{ padding: 10 }}>
        <CheckboxInput
          name="issues_with_pap_device"
          label={translate("havingIssues")}
        />
        {order.issues_with_pap_device && (
          <SelectInput
            name="issue_type"
            label="Select Issue"
            options={translate("papIssuesOptions")}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          background: "white",
          padding: 3
        }}
      >
        {order.requested_equipment.map(equipment => (
          <div style={{ display: "flex", padding: 5 }} key={equipment}>
            <div
              style={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  letterSpacing: "0.00938em"
                }}
              >
                {
                  find(
                    ({ value }) => value === equipment,
                    translate("supplyOptions")
                  )?.text
                }
              </div>
              <div
                style={{
                  color: "#787878",
                  fontFamily: "Poppins-Regular",
                  marginBottom: 5
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  {getEquipmentQuestions(equipment).map(question => (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center"
                      }}
                      key={question.key}
                    >
                      <Checkbox
                        checked={order[question.key]}
                        readOnly
                        color="primary"
                      />
                      <span>{question.text}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
Dysfunctions.propTypes = {
  order: PropTypes.shape({
    requested_equipment: PropTypes.arrayOf(PropTypes.string).isRequired,
    issues_with_pap_device: PropTypes.bool
  }).isRequired
};

export default React.memo(Dysfunctions);
