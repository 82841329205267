import PropTypes from "prop-types";

const ListItem = ({ label, value }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          fontFamily: "Poppins-SemiBold",
          marginTop: 8,
          color: "#141C36"
        }}
      >
        {label}
      </div>
      <div
        style={{
          fontFamily: "Poppins-Medium",
          color: "#707070"
        }}
      >
        {value}
      </div>
    </div>
  );
};

ListItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ])
};

export default ListItem;
