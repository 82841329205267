import React, { useState, useEffect } from "react";
import { Spinner } from "components/ui";
import Logo from "assets/images/icon_trans.png";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkToken } from "actions/auth";
import "./recall-notice.scss";
import queryString from "query-string";
import AcknowledgementForm from "./acknowledgement-form";
import { fetchPatientProfile, submitPhilipsRecall } from "actions/user";
import selectors from "selectors";
import { setLanguage } from "reducers/auth";
import { decodeJwt } from "utils/misc";
import useTranslate from "hooks/use-translate-language";

const EmailRecallPage = () => {
  const location = useLocation();
  const translate = useTranslate();
  const tokenString = queryString.parse(location.search).token;
  const decoded = decodeJwt(tokenString);
  const speaksSpanish = decoded.speaks_spanish;
  const dispatch = useDispatch();

  const [validToken, setIsValidToken] = useState(null);
  useEffect(() => {
    if (tokenString) {
      dispatch(setLanguage(speaksSpanish));
      dispatch(checkToken(tokenString)).then(result => {
        setIsValidToken(result);
      });
    }
  }, [tokenString, dispatch]);

  const [isFetchingProfile, setIsFetchingProfile] = useState(false);
  const profile = useSelector(selectors.getPatientProfile);
  useEffect(() => {
    setIsFetchingProfile(true);
    dispatch(
      fetchPatientProfile(
        tokenString ? { Authorization: `Bearer ${tokenString}` } : undefined
      )
    ).finally(() => {
      setIsFetchingProfile(false);
    });
  }, [tokenString, dispatch]);

  return (
    <div className="recall-screen philips-recall">
      <div className="recall-screen-header">
        <img src={Logo} className="recall-logo" alt="logo" />
      </div>
      {validToken === null && isFetchingProfile && (
        <div className="validating-container">
          <Spinner />
        </div>
      )}
      {validToken !== false && !isFetchingProfile && (
        <div className="philips-recall-container">
          <AcknowledgementForm
            profile={profile}
            tokenString={tokenString}
            handleSubmit={async formValues => {
              await dispatch(submitPhilipsRecall(formValues, tokenString));
            }}
          />
        </div>
      )}

      {validToken === false && (
        <div className="validation-error">
          <h3>{translate("sorryLinkNoLongerValid")}</h3>
        </div>
      )}
    </div>
  );
};

export default EmailRecallPage;
