import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { propOr } from "ramda";

/* ========= Reducer ======== */
export const questionnaires = createSlice({
  name: "questionnaires",
  initialState: {},
  reducers: {
    setPatientQuestionnaires: (state, { payload }) => payload
  }
});

/* ========= Actions ======== */
export const { setPatientQuestionnaires } = questionnaires.actions;

/* ========= SELECTORS ======== */
export const selectors = {
  getPatientQuestionnaires: createSelector(
    [state => state.questionnaires],
    propOr([], "questionnaires")
  )
};

export default questionnaires.reducer;
