import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, IconButton } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import selectors from "selectors";
import FileUploadInput from "./file-upload-input";
import { uploadPatientDocument } from "actions/documents";
import { Formik } from "formik";
import { TextInput, SelectInput } from "components/form";
import * as Yup from "yup";
import useTranslate from "hooks/use-translate-language";
import { Spinner } from "components/ui";
import { Close } from "@mui/icons-material";

const UploadPatientDocumentForm = ({ closeModal }) => {
  const translate = useTranslate();
  const categories = useSelector(selectors.getDocumentCategories);
  const dispatch = useDispatch();
  const fileRef = useRef();
  const [isFetching, setIsFetching] = useState(false);
  return (
    <div style={{ display: "flex", flexDirection: "column", padding: 20 }}>
      <h3 style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
        {translate("uploadDocument")}
        <IconButton
          onClick={closeModal}
          aria-controls="close-document"
          aria-label="add-document"
          aria-haspopup="true"
          size="small"
          type="button"
        >
          <Close />
        </IconButton>
      </h3>
      <Formik
        initialValues={{
          file: null,
          title: "",
          description: "",
          document_category_id: ""
        }}
        onSubmit={async values => {
          try {
            setIsFetching(true);
            await dispatch(uploadPatientDocument(values));
            closeModal();
          } finally {
            setIsFetching(false);
          }
        }}
        validationSchema={Yup.object().shape({
          file: Yup.mixed().required(),
          title: Yup.string().required(translate("required")),
          description: Yup.string().required(translate("required")),
          document_category_id: Yup.string().required(translate("required"))
        })}
      >
        {({ setFieldValue, handleSubmit }) => (
          <div
            style={{ display: "flex", flexDirection: "column", padding: 10 }}
          >
            <TextInput name="title" label={translate("title")} />
            <TextInput name="description" label={translate("notes")} />
            <SelectInput
              name="document_category_id"
              label={translate("selectCategory")}
              options={categories.map(
                ({ document_category_name, document_category_id }) => ({
                  value: document_category_id,
                  text: translate(document_category_name)
                })
              )}
            />
            <FileUploadInput
              ref={fileRef}
              onChange={event => {
                setFieldValue("file", event.currentTarget.files[0]);
              }}
              onRemove={() => {
                setFieldValue("file", null);
              }}
            />
            {isFetching ? (
              <Spinner />
            ) : (
              <Button type="submit" color="primary" onClick={handleSubmit}>
                {translate("submit")}
              </Button>
            )}
          </div>
        )}
      </Formik>
    </div>
  );
};

UploadPatientDocumentForm.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default UploadPatientDocumentForm;
