export default {
  // welcome listed in app-layout

  onboardingNotAvailable: {
    english: "Onboarding not available",
    spanish: "Onboarding no disponible"
  },
  downloadFile: {
    english: "Download file",
    spanish: "Descargar archivo"
  },
  watchVideo: {
    english: "Watch video",
    spanish: "Mirar video"
  }
};
