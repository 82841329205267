import { supplyOptions } from "./options";

const availableEqTypeOptions = supplyOptions.map(x => x.value);

export const getOrderEligibleEquipment = (
  patientEligibleEq,
  company_order_settings
) => {
  return (
    patientEligibleEq
      ?.filter(e => e.eligible === true && e.productType)
      .map(({ productType }) => productType.toLowerCase())
      .filter(
        productType =>
          company_order_settings.equipment_type_options?.includes(
            productType
          ) && availableEqTypeOptions.includes(productType)
      ) ?? []
  );
};
