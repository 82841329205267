import { Types } from "actions/twilio-video";
import { assoc } from "ramda";

const initialState = {
  roomName: undefined,
  room: undefined,
  identity: undefined,
  errMsg: undefined,
  log: [],
  localMedia: [],
  participants: [],
  remoteMedia: []
};

function twilioVideoReducer(state = initialState, action) {
  switch (action.type) {
    case Types.ATTACH_LOCAL_MEDIA:
      return assoc("localMedia", action.tracks, state);
    case Types.CONNECT_TO_ROOM_REQUEST:
      return assoc("errMsg", undefined, state);
    case Types.CONNECT_TO_ROOM_SUCCESS:
      return assoc("room", action.room, state);
    case Types.CONNECT_TO_ROOM_FAILURE:
      return assoc("errMsg", action.errMsg, state);
    case Types.DISCONNECT_FROM_ROOM_REQUEST:
      return assoc("errMsg", undefined, state);
    case Types.DISCONNECT_FROM_ROOM_SUCCESS:
      return { ...state, remoteMedia: [], localMedia: [] };
    case Types.DISCONNECT_FROM_ROOM_FAILURE:
      return assoc("errMsg", action.errMsg, state);
    case Types.LOG_ACTIVITY: {
      return assoc("log", [...state.log, action.message], state);
    }
    case Types.UPDATE_PARTICIPANTS: {
      const updatedRemoteMedia = action.participants.length
        ? state.remoteMedia
        : [];
      return {
        ...state,
        remoteMedia: updatedRemoteMedia,
        participants: action.participants
      };
    }
    case Types.UPDATE_TRACKS: {
      // const existingRemoteMedia = state.get("remoteMedia").toSet();
      // const newRemoteMedia = Set(action.tracks);
      // const updatedRemoteMedia = existingRemoteMedia
      //   .union(newRemoteMedia)
      //   .toList();
      return assoc("remoteMedia", [], state);
    }
    default:
      return state;
  }
}
export const selectors = {
  getTwilioVideoRoom: state => state.twilioVideo.room
};

export default twilioVideoReducer;
