import {
  setPatientNotifications,
  setPatientNotificationSettings,
  setPatientLastLogin
} from "reducers/notifications";
import { API } from "actions/api";
import { NotificationManager } from "react-notifications";

export const fetchPatientNotifications = () => async dispatch => {
  try {
    const { notifications, last_login } = await dispatch({
      type: API,
      route: "patient_portal/mobile/notifications",
      method: "GET"
    });
    dispatch(setPatientNotifications(notifications));
    dispatch(setPatientLastLogin(last_login));

    return notifications;
  } catch (error) {
    console.error(error.message);
  }
};

export const fetchNotificationSettings = () => async dispatch => {
  try {
    const { settings } = await dispatch({
      type: API,
      route: "patient_portal/mobile/notification_settings",
      method: "GET"
    });
    dispatch(setPatientNotificationSettings(settings));
    return settings;
  } catch (error) {
    console.error(error.message);
  }
};

export const updateNotificationSettings = body => async dispatch => {
  try {
    const { message } = await dispatch({
      type: API,
      route: "patient_portal/mobile/notification_settings",
      method: "PUT",
      body
    });
    await dispatch(fetchNotificationSettings());
    NotificationManager.success(message);
  } catch (error) {
    console.error(error.message);
  }
};

export const fetchNotificationCountBeforeDate = date => async dispatch => {
  try {
     const { count } = await dispatch({
      type: API,
      route: `patient_portal/mobile/notifications/before-date?date=${date}`,
      method: "GET"
    });
    return count;
  } catch (error) {
    console.error(error.message);
  }
};
