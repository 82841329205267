import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { propOr, assoc } from "ramda";

/* ========= Reducer ======== */
export const maskFitting = createSlice({
  name: "maskFitting",
  initialState: { questions: [] },
  reducers: {
    setMaskFittingQuestions: (state, { payload }) =>
      assoc("questions", payload, state)
  }
});

/* ========= Actions ======== */
export const { setMaskFittingQuestions } = maskFitting.actions;

/* ========= SELECTORS ======== */
export const select_maskFitting = state => state.maskFitting;
export const selectors = {
  getMaskFittingQuestions: createSelector(
    [select_maskFitting],
    propOr([], "questions")
  )
};

export default maskFitting.reducer;
