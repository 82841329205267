import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchOnboarding } from "actions/onboarding";
import { PlayCircle } from "@mui/icons-material";
import { Page } from "components/ui";
import Carousel from "react-multi-carousel";
import Button from "@mui/material/Button";
import useTranslate from "hooks/use-translate-language";
import "react-multi-carousel/lib/styles.css";
import "./onboarding.scss";
import VideoPlayerModal from "./video-player-modal";
import PDFIcon from "assets/images/pdf_icon.png";

export default function Onboarding() {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [tiles, setTiles] = useState([]);
  const [logoTile, setLogoTile] = useState(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [videoPlayerUrl, setVideoPlayerUrl] = useState("");
  const [videoTileTitle, setVideoTileTitle] = useState("");

  useEffect(() => {
    setIsFetching(true);
    dispatch(fetchOnboarding())
      .then(data => {
        let tilesToShow = [];
        if (data) {
          data.forEach(element => {
            if (element.IsCompanyLogo) {
              setLogoTile(element);
            } else {
              tilesToShow.push(element);
            }
          });
        }
        setTiles(tilesToShow);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [dispatch]);

  const getFileType = url => {
    const extension = url.split(".").pop().toLowerCase();
    const pdfExtensions = ["pdf"];
    const videoExtensions = ["mp4"];

    if (pdfExtensions.includes(extension)) {
      return "pdf";
    } else if (videoExtensions.includes(extension)) {
      return "video";
    } else {
      return "unknown";
    }
  };

  const openVideoPlayer = (url, videoTitle) => {
    setVideoTileTitle(videoTitle);
    setVideoPlayerUrl(url);
    setOpenVideoModal(true);
  };

  const closeVideoPlayer = () => {
    setVideoTileTitle("");
    setVideoPlayerUrl("");
    setOpenVideoModal(false);
  };

  const responsive = items => {
    return {
      desktop: {
        breakpoint: {
          max: 3000,
          min: 1024
        },
        items: items < 3 ? items : 3
      },
      tablet: {
        breakpoint: {
          max: 1024,
          min: 560
        },
        items: items < 2 ? items : 2
      },
      mobile: {
        breakpoint: {
          max: 560,
          min: 0
        },
        items: 1
      }
    };
  };

  return (
    <Page.Container>
      <VideoPlayerModal
        open={openVideoModal}
        handleClose={closeVideoPlayer}
        url={videoPlayerUrl}
        videoTileTitle={videoTileTitle}
      />
      <Page.Header title={translate("welcome")} goBack="/" />
      <Page.Content
        isFetching={isFetching}
        isEmpty={tiles && tiles?.length === 0}
        emptyMessage={translate("onboardingNotAvailable")}
        styles={{margin: "-20px"}}
      >
        <div className="company-logo-section">
          {logoTile && <img className="company-logo" src={logoTile.FileUrl} />}
        </div>
        <div className="tiles-section">
          {tiles && (
            <div className="carousel-wrapper">
              <Carousel
                responsive={responsive(tiles.length)}
                containerClass="carousel-container"
                itemClass="carousel-item"
                showDots={true}
                renderDotsOutside
              >
                {tiles &&
                  tiles.map((tile, index) => {
                    if (!tile.IsCompanyLogo) {
                      const fileType = getFileType(tile.FileUrl);
                      return (
                        <div key={index} className="card-container">
                          <div className="card-title">{tile.Title}</div>
                          <div className="card-description">
                            {tile.Description}
                          </div>

                          {fileType === "pdf" && (
                            <Button
                              variant="text"
                              onClick={() => {
                                window.open(tile.FileUrl, "_blank");
                              }}
                              startIcon={
                                <img src={PDFIcon} alt="PDF icon" className="card-pdf-icon" />
                              }
                            >
                              {translate("downloadFile")}
                            </Button>
                          )}
                          {fileType === "video" && (
                            <Button
                              variant="text"
                              onClick={() => {
                                openVideoPlayer(tile.FileUrl, tile.Title);
                              }}
                              startIcon={
                                <PlayCircle style={{ fontSize: 40 }} />
                              }
                            >
                              {translate("watchVideo")}
                            </Button>
                          )}
                        </div>
                      );
                    }
                  })}
              </Carousel>
            </div>
          )}
        </div>
      </Page.Content>
    </Page.Container>
  );
}
