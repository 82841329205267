import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { LoadingButton, Spinner } from "components/ui";
import "react-phone-input-2/lib/style.css";
import { translateUsingBrowser } from "utils/misc";
import { Formik } from "formik";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {
  sendCodeToContactMethod,
  resendCodeToContactMethod
} from "actions/multifactor";
import { loginMFA } from "actions/auth";
import { NotificationManager } from "react-notifications";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";

const MultifactorModal = ({ userMFADetails, closeDialog }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [contactMethod, setContactMethod] = useState("");
  const [resendFetching, setResendFetching] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const sendCodeToContactMethodApi = async contact_method => {
    const result = await dispatch(
      sendCodeToContactMethod(contact_method, userMFADetails.MFAUnmaskedEmail)
    );
    if (result) {
      NotificationManager.success(
        translateUsingBrowser("verificationCodeSent")
      );
      setContactMethod(contact_method);
      setStep(2);
      setTimeLeft(120);
    }
  };

  const handleInputChange = (event, setFieldValue) => {
    let { name, value } = event.target;
    setFieldValue(name, value);
  };

  const resendCodeToContactMethodApi = async () => {
    try {
      const response = await dispatch(
        resendCodeToContactMethod(
          contactMethod,
          userMFADetails.MFAUnmaskedEmail
        )
      );
      if (response) {
        NotificationManager.success(
          translateUsingBrowser("verificationCodeSent")
        );
        setTimeLeft(120);
      }
    } finally {
      setResendFetching(false);
    }
  };

  return (
    <div className="login-screen multifactor">
      <div className="login-screen-header">
        <p> {translateUsingBrowser("additionalSecurityVerification")}</p>
      </div>

      {step === 1 ? (
        <Formik
          initialValues={{
            contact_method: !userMFADetails.MFAPhoneNumberVerified
              ? "EMAIL"
              : ""
          }}
          onSubmit={async ({ contact_method }) => {
            await sendCodeToContactMethodApi(contact_method);
          }}
        >
          {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
            <form className="multifactor-form" onSubmit={handleSubmit}>
              {isSubmitting ? (
                <Spinner
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: 120
                  }}
                />
              ) : (
                <>
                  <FormLabel className="multifactor-step-label">
                    {translateUsingBrowser("step1ContactMethod")}
                  </FormLabel>
                  <FormControl fullWidth>
                    <RadioGroup
                      className="multifactor-methods"
                      name="contact_method"
                      value={values.contact_method}
                      onChange={e => {
                        return handleInputChange(e, setFieldValue);
                      }}
                    >
                      {userMFADetails.MFAPhoneNumberVerified && (
                        <FormControlLabel
                          className={`multifactor-method ${
                            values.contact_method === "SMS" ? "selected" : ""
                          }`}
                          value="SMS"
                          control={<Radio />}
                          label={
                            <>
                              <LocalPhoneIcon />
                              {userMFADetails.MFAPhoneNumber}
                            </>
                          }
                        />
                      )}
                      <FormControlLabel
                        className={`multifactor-method ${
                          values.contact_method === "EMAIL" ? "selected" : ""
                        }`}
                        value="EMAIL"
                        control={<Radio />}
                        label={
                          <>
                            <EmailIcon />
                            {userMFADetails.email}
                          </>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                  <div className="multifactor-column-actions">
                    <Button
                      variant="contained"
                      color="primary"
                      id="next-button"
                      type="submit"
                      disabled={!values.contact_method}
                    >
                      {translateUsingBrowser("next")}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="button"
                      onClick={() => {
                        closeDialog();
                      }}
                    >
                      {translateUsingBrowser("backToLogin")}
                    </Button>
                  </div>
                </>
              )}
            </form>
          )}
        </Formik>
      ) : (
        <Formik
          initialValues={{
            verification_code: ""
          }}
          onSubmit={async ({ verification_code }) => {
            await dispatch(loginMFA(verification_code));
          }}
        >
          {({ isSubmitting, handleSubmit, values, setFieldValue }) => (
            <form className="multifactor-form" onSubmit={handleSubmit}>
              <FormLabel className="multifactor-step-label">
                {translateUsingBrowser("step2EnterCode")}
              </FormLabel>

              <FormControl fullWidth>
                <TextField
                  id="verification_code"
                  name="verification_code"
                  type="text"
                  value={values.verification_code || ""}
                  onChange={e => {
                    return handleInputChange(e, setFieldValue);
                  }}
                />
              </FormControl>
              {isSubmitting ? (
                <Spinner
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: 120
                  }}
                />
              ) : (
                <>
                  <div className="multifactor-row-actions">
                    <FormLabel className="multifactor-step-label">
                      {translateUsingBrowser("didntReceiveCode")}
                    </FormLabel>

                    {timeLeft == 0 && (
                      <LoadingButton
                        color="primary"
                        type="button"
                        isFetching={resendFetching}
                        onClick={() => {
                          setResendFetching(true);
                          resendCodeToContactMethodApi();
                        }}
                      >
                        {translateUsingBrowser("resend")}
                      </LoadingButton>
                    )}
                    {timeLeft > 0 && (
                      <p>
                        {translateUsingBrowser("waiting").replace(
                          "X",
                          timeLeft
                        )}
                      </p>
                    )}
                  </div>
                  <div className="multifactor-column-actions">
                    <Button
                      variant="contained"
                      color="primary"
                      id="verify-button"
                      type="submit"
                      disabled={
                        !values.verification_code ||
                        values.verification_code.length !== 6
                      }
                    >
                      {translateUsingBrowser("verify")}
                    </Button>                   
                  </div>
                </>
              )}
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default MultifactorModal;
