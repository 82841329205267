import { Page } from "components/ui";
import { useDispatch } from "react-redux";
import TimeSlotSearchForm from "./time-slot-search-form";
import { scheduleChat } from "actions/chat";
import useTranslate from "hooks/use-translate-language";

const ScheduleChat = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  return (
    <Page.Container>
      <Page.Header title={translate("scheduleAMeeting")} goBack />
      <Page.Content>
        <TimeSlotSearchForm
          handleTimeSlotSelect={async (chat) => {
            await dispatch(scheduleChat(chat));
          }}
        />
      </Page.Content>
    </Page.Container>
  );
};

export default ScheduleChat;
