import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { pathOr } from "ramda";
import "./error-page.scss";
import useTranslate from "hooks/use-translate-language";

const ErrorPage = ({ location, history }) => {
  const translate = useTranslate();
  const message = pathOr("", ["state", "message"], location);
  const subMessage = pathOr("", ["state", "subMessage"], location);
  const backTo = pathOr("", ["state", "backTo"], location);
  return (
    <div className="error-screen">
      <div className="error-container">
        <h1>{translate("sorry")}</h1>
        <h3>{message}</h3>
        <h4 style={{ marginBottom: 20 }}>{subMessage}</h4>
        {backTo && (
          <Button
            onClick={() => {
              history.push(backTo);
            }}
            variant="contained"
          >
            {translate("goBack")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ErrorPage;

ErrorPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
