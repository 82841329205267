import { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Menu, MenuItem, IconButton } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { Card } from "components/ui";
import { formatDate } from "utils/misc";
import { deletePatientDocument } from "actions/documents";
import CalanderIcon from "assets/images/calendar.png";
import ClockIcon from "assets/images/clock_gray.png";
import useTranslate from "hooks/use-translate-language";

const DocumentItem = ({
  document_id,
  updated_date,
  title,
  description,
  // upload_date,
  file,
}) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Card style={{ margin: 10 }} key={document_id}>
      <div className="document-header">
        <div className="document-title">
          <div className="document-title-text">{title}</div>
          <IconButton
            onClick={handleClick}
            aria-controls={`menu-${document_id}`}
            aria-label="settings"
            aria-haspopup="true"
          >
            <MoreVert />
          </IconButton>
          <Menu
            id={`menu-${document_id}`}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={(event) => {
                event.preventDefault();
                window.open(file);
                handleClose();
              }}
            >
              {translate("view")}
            </MenuItem>
            {/* <MenuItem onClick={handleClose}>Notes</MenuItem> */}
            <MenuItem
              onClick={async () => {
                await dispatch(deletePatientDocument(document_id));
                handleClose();
              }}
            >
              {translate("delete")}
            </MenuItem>
          </Menu>
        </div>
        <div className="document-description">
          <span
            className="link"
            onClick={() => {
              window.open(file);
            }}
          >
            {description}
          </span>
        </div>
      </div>
      <div className="document-footer">
        <img src={CalanderIcon} alt="" />
        <div className="document-datetime">{formatDate(updated_date)}</div>
        <div className="divider">|</div>
        <img src={ClockIcon} alt="" />
        <div className="document-datetime">
          {formatDate(updated_date, "h:mm A")}
        </div>
      </div>
    </Card>
  );
};

DocumentItem.propTypes = {
  document_id: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  upload_date: PropTypes.string,
  updated_date: PropTypes.string,
  file: PropTypes.string,
};

export default DocumentItem;
