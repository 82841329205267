import PropTypes from "prop-types";
import { SelectInput, TextInput } from "components/form";
import { FieldArray } from "formik";
import { useSelector } from "react-redux";
import selectors from "selectors";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/lab/Autocomplete";
import { formatDate } from "utils/misc";

const getInsuranceDisplayName = index => {
  switch (index) {
    case 0:
      return "Primary";
    case 1:
      return "Secondary";
    case 2:
      return "Tertiary";
    default:
      return "";
  }
};

const InsuranceForm = ({ values, setFieldValue, errors }) => {
  const companyInsuranceOptions = useSelector(
    selectors.getPatientInsuranceOptions
  );
  const guarantorRelationOptions = useSelector(
    selectors.getGuarantorRelationOptions
  );

  const disabled = !values?.changed_insurance;

  return (
    <FieldArray
      name="insurances"
      render={arrayHelpers => (
        <div>
          {values.insurances?.map((_, index) => (
            <div key={index} style={{ padding: 5 }}>
              <Autocomplete
                id="insurance-box"
                getOptionSelected={(option, value) => {
                  return (
                    option?.insurance_company_id == value?.insurance_company_id
                  );
                }}
                options={companyInsuranceOptions.map(x => ({
                  ...x,
                  insurance_company_id: x.value,
                  insurance_company_name: x.text
                }))}
                getOptionLabel={option => option.insurance_company_name}
                value={values.insurances[index]}
                onChange={(_, newValue) => {
                  setFieldValue(`insurances.${index}`, {
                    ...values.insurances[index],
                    ...newValue,
                    primary_order: index + 1
                  });
                }}
                disabled={disabled}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={errors.insurances?.[index]?.insurance_company_id}
                    helperText={
                      errors.insurances?.[index]?.insurance_company_id
                    }
                    InputLabelProps={{ shrink: true }}
                    label={getInsuranceDisplayName(index) + " Insurance"}
                    variant="outlined"
                    disabled={disabled}
                  />
                )}
              />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  alignItems: "center",
                  gridGap: 10
                }}
              >
                <TextInput
                  name={`insurances.${index}.policy_number`}
                  type="text"
                  label="Policy Number"
                  disabled={disabled}
                />
                <TextInput
                  name={`insurances.${index}.group_number`}
                  type="text"
                  label="Group Number"
                  disabled={disabled}
                />
                <SelectInput
                  name={`insurances.${index}.guarantor_relation`}
                  component="select"
                  label="Guarantor Relationship"
                  options={guarantorRelationOptions}
                  disabled={disabled}
                />
                <TextInput
                  name={`insurances.${index}.policy_start_date`}
                  type="date"
                  label="Policy Start Date"
                  InputLabelProps={{ shrink: true }}
                  disabled={disabled}
                  value={formatDate(
                    values.insurances[index].policy_start_date,
                    "YYYY-MM-DD"
                  )}
                />
                {/*<TextInput
                  name={`insurances.${index}.policy_end_date`}
                  type="date"
                  label="Policy End Date"
                  InputLabelProps={{ shrink: true }}
                  value={formatDate(
                    values.insurances[index].policy_end_date,
                    "YYYY-MM-DD"
                  )}
                  disabled={disabled}
                />*/}
                {index > 0 && (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <button onClick={() => arrayHelpers.remove(index)}>
                      Delete
                    </button>
                  </div>
                )}
              </div>
              {index == 0 && <hr style={{ margin: "20px 0px" }} />}
            </div>
          ))}
          {values.insurances.length == 1 && !disabled && (
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <button
                type="button"
                style={{ margin: 0, padding: "5px 15px" }}
                onClick={() =>
                  arrayHelpers.push({
                    insurance_company_id: "",
                    insurance_company_name: "",
                    primary_order: 2,
                    policy_number: "",
                    group_number: "",
                    guarantor_relation: "",
                    policy_start_date: ""
                    //policy_end_date: ""
                  })
                }
              >
                Add Secondary Insurance
              </button>
            </div>
          )}
        </div>
      )}
    />
  );
};

InsuranceForm.propTypes = {
  errors: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    changed_insurance: PropTypes.bool,
    insurances: PropTypes.arrayOf(
      PropTypes.shape({
        insurance_company_id: PropTypes.string,
        insurance_company_name: PropTypes.string,
        policy_start_date: PropTypes.string
        //policy_end_date: PropTypes.string
      })
    )
  })
};

export default InsuranceForm;
