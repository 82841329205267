export const Types = {
  ATTACH_LOCAL_MEDIA: "ATTACH_LOCAL_MEDIA",
  CONNECT_TO_ROOM_REQUEST: "CONNECT_TO_ROOM_REQUEST",
  CONNECT_TO_ROOM_SUCCESS: "CONNECT_TO_ROOM_SUCCESS",
  CONNECT_TO_ROOM_FAILURE: "CONNECT_TO_ROOM_FAILURE",
  DISCONNECT_FROM_ROOM_REQUEST: "DISCONNECT_FROM_ROOM_REQUEST",
  DISCONNECT_FROM_ROOM_SUCCESS: "DISCONNECT_FROM_ROOM_SUCCESS",
  DISCONNECT_FROM_ROOM_FAILURE: "DISCONNECT_FROM_ROOM_FAILURE",
  FETCH_TOKEN_REQUEST: "FETCH_TOKEN_REQUEST",
  FETCH_TOKEN_SUCCESS: "FETCH_TOKEN_SUCCESS",
  FETCH_TOKEN_FAILURE: "FETCH_TOKEN_FAILURE",
  LOG_ACTIVITY: "LOG_ACTIVITY",
  UPDATE_PARTICIPANTS: "UPDATE_PARTICIPANTS",
  UPDATE_TRACKS: "UPDATE_PARTICIPANTS"
};

export const attachLocalMedia = tracks => ({
  type: Types.ATTACH_LOCAL_MEDIA,
  tracks
});

export const connectToRoomRequest = () => ({
  type: Types.CONNECT_TO_ROOM_REQUEST
});

export const connectToRoomSuccess = room => ({
  type: Types.CONNECT_TO_ROOM_SUCCESS,
  room
});

export const connectToRoomFailure = errMsg => ({
  type: Types.CONNECT_TO_ROOM_FAILURE,
  errMsg
});

export const disconnectFromRoomRequest = room => ({
  type: Types.DISCONNECT_FROM_ROOM_REQUEST,
  room
});

export const disconnectFromRoomSuccess = () => ({
  type: Types.DISCONNECT_FROM_ROOM_SUCCESS
});

export const disconnectFromRoomFailure = errMsg => ({
  type: Types.DISCONNECT_FROM_ROOM_FAILURE,
  errMsg
});

export const logActivity = message => ({
  type: Types.LOG_ACTIVITY,
  message
});

export const updateParticipants = (participants, room) => ({
  type: Types.UPDATE_PARTICIPANTS,
  participants,
  room
});

export const updateTracks = tracks => ({
  type: Types.UPDATE_TRACKS,
  tracks
});
