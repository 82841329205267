import React from "react";
import PropTypes from "prop-types";
import { propOr } from "ramda";
const AlertBar = ({ children, color, style }) => (
  <div style={{ ...styles.base, ...propOr({}, color, styles), ...style }}>
    {children}
  </div>
);
AlertBar.defaultProp = {
  style: {},
};
AlertBar.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object,
  color: PropTypes.oneOf([
    "primary",
    "orange",
    "red",
    "brown",
    "magenta",
    "black",
  ]).isRequired,
};
export default AlertBar;
var styles = {
  base: {
    backgroundColor: "#9FE5FF",
    border: "1px solid #8ECBE1",
    fontSize: "1.2em",
    marginBottom: 15,
    boxShadow: "1px 2px 4px 2px #ccc",
    padding: 10,
  },
  primary: {
    backgroundColor: "#0074D9",
    border: "1px solid #8ECBE1",
    color: "white",
  },
  orange: {
    backgroundColor: "#E8910C",
    border: "1px solid #c5A231",
    color: "white",
  },
  red: {
    backgroundColor: "#FF4136",
    border: "1px solid #ff5311",
    color: "white",
  },
  magenta: {
    backgroundColor: "#8B008B",
    border: "1px solid #FF00FF",
    color: "white",
  },
  brown: {
    backgroundColor: "#A52A2A",
    border: "1px solid #CD853F",
    color: "white",
  },
  black: {
    backgroundColor: "black",
    border: "1px solid black",
    color: "white",
  },
  green: {
    backgroundColor: "#66bb6a",
    border: "1px solid #4caf50",
    color: "white",
  },
};
