import React from "react";
import moment from "moment";
import "./styles.scss"

export const EligibilityIndicator = ({ eligible, date }) => {
  if(eligible == true) return <div className={"eligible"}>Eligible</div>
    else if(eligible == false)
    {
      return(
      <div className={"notEligible"}>{moment(date).format(
          "MM/DD/YYYY"
        )}</div>
      )
    }
    else (typeof eligible == undefined)
    {
     return (
        null
      )
    }
};
