import { HOST } from "environment";
/* eslint-disable no-console */

async function apiRequest(path, { method = "GET", headers = {}, body } = {}) {
  return await fetch(`${HOST}/${path}`, {
    method,
    headers: { "content-type": "application/json", ...headers },
    body: body ? JSON.stringify(body) : undefined
  })
    .then(handleErrors)
    .then(handleResponse);
}

export default apiRequest;

const handleErrors = async response => {
  if (!response.ok) {
    throw new Error(response);
  }
  return response;
};

const handleResponse = async response => {
  const body = await response.json();
  return body;
};
