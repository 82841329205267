import { API } from "actions/api";
import { setDashboardInfo } from "reducers/dashboard";
import { setAskForConsentAsFalse } from "reducers/auth";
import { NotificationManager } from "react-notifications";

export const fetchDashboard = () => async dispatch => {
  try {
    const { success, message, dashboard } = await dispatch({
      type: API,
      route: "patient_portal/mobile/dashboard",
      method: "GET"
    });
    dispatch(setDashboardInfo(dashboard));
    return { success, message, dashboard };
  } catch (error) {
    console.error(error.message);
  }
};

export const setPatientConsent = data => async dispatch => {
  try {
    const response = await dispatch({
      type: API,
      route: "patient_portal/mobile/sms-consent",
      method: "PUT",
      body: data
    });

    if(response && response.success){
      NotificationManager.success(response.message);
      dispatch(setAskForConsentAsFalse(false));
    }
    else
      NotificationManager.error(response?.message, "Could not set SMS consent");
    
    return response;
  }
  catch (error) {
    console.error(error.message);
    NotificationManager.error(
      error.message,
      "Could not set SMS consent"
    );
  }
}
