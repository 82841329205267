import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { startChat } from "actions/chat";
import { useDispatch } from "react-redux";
import moment from "moment";
import momentTz from "moment-timezone";
import useTranslate from "hooks/use-translate-language";

const JoinChatButton = ({
  slot_id,
  master_chat_type,
  // chat_type,
  chat_type_text,
  is_product_demo,
  start_time,
  isConfirmed
}) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [seconds, setSeconds] = React.useState(0);
  const [isActive] = React.useState(true);
  const [isFetching, setIsFetching] = useState(false);

  React.useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);
  const isJoinable = moment().isAfter(moment(start_time)) && isConfirmed;
  return (
    <div>
      {isJoinable ? (
        <Button
          onClick={async () => {
            try {
              setIsFetching(true);
              await dispatch(
                startChat(slot_id, master_chat_type, is_product_demo)
              );
            } finally {
              setIsFetching(false);
            }
          }}
          color="primary"
          disabled={isFetching}
        >
          {translate("join")} {chat_type_text}
        </Button>
      ) : null
      }
    </div>
  );
};

JoinChatButton.propTypes = {
  chat_type: PropTypes.number,
  slot_id: PropTypes.string,
  start_time: PropTypes.string,
  chat_type_text: PropTypes.string,
  master_chat_type: PropTypes.string.isRequired,
  is_product_demo: PropTypes.bool,
};

export default JoinChatButton;
