import React from "react";
import Modal from "components/ui/modal";
import MultifactorModal from "./multifactor-modal.jsx";
import { useDispatch } from "react-redux";
import { logoutUser } from "actions/auth";

const MultifactorModalContainer = ({ open, userMFADetails }) => {
  const dispatch = useDispatch();

  const close = async () => {
    await dispatch(logoutUser());
  };

  return (
    <div>
      <Modal
        maxWidth="xs"
        fullWidth
        open={open}
        renderTrigger={() => null}
        startOpen={true}
        onClose={async () => {
          await close();
        }}
        disableOutsideClick={true}
        renderContent={() => (
          <MultifactorModal
            userMFADetails={userMFADetails}
            closeDialog={close}
          />
        )}
      />
    </div>
  );
};

export default MultifactorModalContainer;
