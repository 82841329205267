import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import authReducer from "./auth";
import userReducer from "./user";
import chatReducer from "./chat";
import dashboardReducer from "./dashboard";
import ordersReducer from "./orders";
import productsReducer from "./products";
import documentsReducers from "./documents";
import questionnairesReducers from "./questionnaires";
import notificationsReducer from "./notifications";
import signaturesReducer from "./signatures";
import maskFittingReducer from "./mask-fitting";
import twilioReducer from "./twilio";
import twilioChatReducer from "./twilio-chat";
import twilioVideoReducer from "./twilio-video";
import { combineReducers } from "redux";

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: [
    "authentication_token",
    "user_details",
    "app_permissions",
    "show_recall_prompt",
    "timezone"
  ]
};

const twilioChatPersistConfig = {
  key: "twilioChat",
  storage,
  whitelist: ["channel_sid"]
};

const notificationsPersistConfig = {
  key: "notifications",
  storage,
  whitelist: ["last_browse"]
};

const appReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  user: userReducer,
  dashboard: dashboardReducer,
  chat: chatReducer,
  orders: ordersReducer,
  products: productsReducer,
  documents: documentsReducers,
  questionnaires: questionnairesReducers,
  notifications: persistReducer(notificationsPersistConfig, notificationsReducer),
  signatures: signaturesReducer,
  maskFitting: maskFittingReducer,
  twilio: twilioReducer,
  twilioChat: persistReducer(twilioChatPersistConfig, twilioChatReducer),
  twilioVideo: twilioVideoReducer
});

export default appReducer;
