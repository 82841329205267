import PropTypes from "prop-types";
import { ListItem } from "components/ui";
import { propOr } from "ramda";
import { formatAddress } from "utils/misc";
import useTranslate from "hooks/use-translate-language";

const Physician = ({ physicianInfo }) => {
  const translate = useTranslate();
  return (
    <div className="profile-panel">
      <h4 className="profile-panel-header">{translate("physician")}</h4>
      <div className="profile-content">
        <ListItem
          label={translate("name")}
          value={propOr("", "name", physicianInfo)}
        />
        <ListItem
          label={translate("phone")}
          value={propOr("", "phone", physicianInfo)}
        />
        <ListItem
          label={translate("fax")}
          value={propOr("", "fax", physicianInfo)}
        />
        <ListItem
          label={translate("address")}
          value={formatAddress(propOr({}, "address", physicianInfo))}
        />
        <ListItem
          label={translate("notes")}
          value={propOr("", "note", physicianInfo)}
        />
      </div>
    </div>
  );
};

Physician.propTypes = {
  physicianInfo: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.shape({
      street_address: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
    }),
  }).isRequired,
};

export default Physician;
