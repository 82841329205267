import PropTypes from "prop-types";
import { Page } from "components/ui";
import { propOr } from "ramda";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import useTranslate from "hooks/use-translate-language";
import { useSelector } from "react-redux";
import selectors from "selectors";

const ProductInfo = ({ product_id, product }) => {
  const speaksSpanish = useSelector(selectors.getSpeaksSpanish);
  const translate = useTranslate();
  const history = useHistory();
  return (
    <Page.Container>
      <Page.Header
        title={propOr("Product", "name", product)}
        goBack="/products"
      />
      <div className="product-info">
        <div
          className="product-image"
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            height: 300,
          }}
        >
          <img
            src={product.product_photo ? product.product_photo : product.image}
            alt=""
            style={{ width: 300 }}
          />
        </div>
        <h3>{translate("productInformation")}</h3>
        <div className="product-data">
          {!speaksSpanish
            ? product.name
            : product.spanish_information ?? product.name}
        </div>
        <div className="product-manufacturer">
          {translate("manufacturedBy")}: {product.manufacturer}
        </div>
        <div className="product-data">
          {translate("purchaseDate")}: {product.purchase_date}
        </div>
        <div className="product-data">
          {translate("productType")}:{" "}
          {!speaksSpanish ? product.type : product.spanish_type ?? product.type}
        </div>
      </div>
      <div
        className="product-info-actions"
        style={{
          marginTop: 30,
          display: "flex",
          width: "100%",
          alignSelf: "center",
          justifySelf: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ marginRight: 20 }}>
          <Button
            variant="outlined"
            color="primary"
            type="button"
            onClick={() => {
              history.push(`/products/${product_id}/faqs`);
            }}
          >
            {translate("faqs")}
          </Button>
        </div>
        <div style={{ marginLeft: 20 }}>
          <Button
            variant="outlined"
            color="secondary"
            type="button"
            onClick={() => {
              history.push(`/products/${product_id}/videos`);
            }}
          >
            {translate("watchVideos")}
          </Button>
        </div>
      </div>
    </Page.Container>
  );
};

ProductInfo.propTypes = {
  product: PropTypes.object.isRequired,
  product_id: PropTypes.string.isRequired,
};

export default ProductInfo;
