import PropTypes from "prop-types";
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText
} from "@mui/material";
import { useFormikContext } from "formik";
import { path } from "ramda";

const getPath = name => name.split(".");

const CheckboxInput = ({ name, label, ...rest }) => {
  const { values, handleBlur, handleChange, errors, touched } =
    useFormikContext();
  const namePath = getPath(name);
  const error = path(namePath, touched) && path(namePath, errors);
  const value = path(namePath, values);
  return (
    <FormControl error={!!error}>
      <FormHelperText>{error}</FormHelperText>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={handleChange}
            onBlur={handleBlur}
            name={name}
            color="primary"
            {...rest}
          />
        }
        label={label}
      />
    </FormControl>
  );
};
export default CheckboxInput;

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string
};
