import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import selectors from "selectors";
import Twilio from "twilio-chat";

const useTwilioChat = ({ channel_sid }) => {
  /* -- TWILIO CLIENT -- */
  const twilioToken = useSelector(selectors.getTwilioToken);
  const userId = useSelector(selectors.getUserId);
  const [client, setClient] = useState(null);
  const [error, setError] = useState();
  const [connecting, setIsConnecting] = useState(false);
  /* create twilio client */
  useEffect(() => {
    setIsConnecting(true);
    Promise.all([
      Twilio.create(twilioToken).then(client => {
        setClient(client);
      })
    ]).catch(err => {
      setError(err.message);
      setIsConnecting(false);
    });
  }, [userId, twilioToken]);

  /* ----- CHAT CHANNEL ----- */
  const [channel, setChannel] = useState(null);
  const [messages, setMessages] = useState([]);
  const [connected, setConnected] = useState(false);
  const [members, setMembers] = useState([]);

  /* connect to channel */
  useEffect(() => {
    async function getChannelMembers(channel) {
      try {
        const members = await channel.getMembers();
        setMembers(members.map(({ identity }) => identity));
      } catch (err) {
        console.error("could not get channel members:", err.message);
      }
    }
    async function restoreMessages(channel) {
      try {
        const { items } = await channel.getMessages();
        const restoredMessages = items.map(message => ({
          ...message.state
        }));
        setMessages(() => [...restoredMessages]);
      } catch (err) {
        console.error("could not restore messages:", err.message);
      }
    }
    async function connectToChatChannel(channel_sid, client) {
      try {
        const channel = await client.getChannelBySid(channel_sid);
        if (channel.status !== "joined") {
          await channel.join();
        }
        setChannel(channel);
        await Promise.all([
          restoreMessages(channel),
          getChannelMembers(channel)
        ]);
        setConnected(true);
      } catch (e) {
        setError(e.message);
      } finally {
        setIsConnecting(false);
      }
    }
    if (client && channel_sid) {
      connectToChatChannel(channel_sid, client);
    }
  }, [client, channel_sid]);

  // handle channel events
  useEffect(() => {
    async function addMessage(message) {
      setMessages(messages => [...messages, { ...message.state }]);
    }
    if (channel) {
      console.debug("subscribing to chat channel");
      channel.on("messageAdded", addMessage);
      channel.on("memberJoined", member => {
        console.debug("member joined", member);
        setMembers(m => [...m, member.identity]);
      });
      channel.on("memberLeft", member => {
        console.debug("member left", member);
        setMembers(m => m.filter(id => id === member.identity));
      });
      channel.on("removed", () => {
        console.debug("text channel removed");
        setChannel(null);
        setConnected(false);
      });
      return () => {
        console.debug("unsubscribing to chat channel");
        channel.removeAllListeners();
        channel.leave();
      };
    }
  }, [channel]);

  const sendMessage = useCallback(
    msg => {
      if (channel) channel.sendMessage(msg);
      else {
        return Promise.reject("channel not defined");
      }
    },
    [channel, userId]
  );
  const leaveTwilioChat = useCallback(() => {
    if (channel) {
      console.debug("leaving text chat");
      return channel.leave();
    }
  }, [channel]);
  return {
    messages,
    sendMessage,
    connected,
    connecting,
    error,
    leaveTwilioChat,
    members
  };
};

export default useTwilioChat;
