export default {
  // Index
  waitingForHost: {
    english: "Waiting For Host To Start",
    spanish: "Esperando a que el anfitrión comience",
  },
  leave: {
    english: "Leave",
    spanish: "Licencia",
  },
};
