export default {
  // Index
  noQuestionnaires: {
    english: "You have no questionnaires",
    spanish: "No tienes cuestionarios",
  },
  ignore: {
    english: "Ignore",
    spanish: "Ignorar",
  },
  fillOutNow: {
    english: "Fill Out Now",
    spanish: "Complete ahora",
  },
  service: {
    english: "Service",
    spanish: "Servicio",
  },
  product: {
    english: "Product",
    spanish: "Producto",
  },
  // questionnaires listed in dashboard

  // Question
  no: {
    english: "No",
    spanish: "No",
  },
  yes: {
    english: "Yes",
    spanish: "Sí",
  },

  // Sign Questionnaire
  completeQuestionnaire: {
    english: "Complete Questionnaire",
    spanish: "Cuestionario completo",
  },
  questionnaireNotAvailable: {
    english: "Questionnaire not available",
    spanish: "Cuestionario no disponible",
  },
  // back listed in order-form
  // submit listed in profile
};
