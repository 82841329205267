import { useState, useEffect, useCallback } from "react";
import Video from "twilio-video";
import { useSelector } from "react-redux";
import selectors from "selectors";
import browserHistory from "browser-history";

const useTwilioVideo = ({
  room_name,
  publish_video_track,
  publish_audio_track
}) => {
  const twilioToken = useSelector(selectors.getTwilioToken);
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [connected, setIsConnected] = useState(false);
  const [connecting, setIsConnecting] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    const participantConnected = participant => {
      console.debug("adding participant", participant);
      setParticipants(prevParticipants => [...prevParticipants, participant]);
    };

    const participantDisconnected = participant => {
      console.debug("removing participant", participant);
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant)
      );
    };
    setIsConnecting(true);
    Video.connect(twilioToken, {
      name: room_name,
      video: publish_video_track,
      audio: publish_audio_track
    })
      .then(room => {
        setRoom(room);
        setIsConnected(true);
        setIsConnecting(false);
        setError();
        room.on("participantConnected", participantConnected);
        room.on("participantDisconnected", participantDisconnected);
        room.participants.forEach(participantConnected);
      })
      .catch(error => {
        console.debug("video connect error", error);
        setIsConnecting(false);
        setError(error.message);
      });

    return () => {
      setRoom(currentRoom => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            console.debug("stopping track");
            trackPublication.track.stop();
          });
          console.debug("disconnecting from video");
          currentRoom.disconnect();
          setIsConnected(false);
          setParticipants([]);
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [room_name, twilioToken, publish_audio_track, publish_video_track]);

  const leaveVideoRoom = useCallback(() => {
    if (room) {
      browserHistory.goBack("/");
      return room.disconnect();
    }
  }, [room]);

  return {
    participants,
    room,
    connected,
    connecting,
    error,
    setError,
    leaveVideoRoom
  };
};

export default useTwilioVideo;
