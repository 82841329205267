import { createSlice } from "@reduxjs/toolkit";

/* ========= Reducer ======== */
export const dashboard = createSlice({
  name: "dashboard",
  initialState: {},
  reducers: {
    setDashboardInfo: (state, { payload }) => payload
  }
});

/* ========= Actions ======== */
export const { setDashboardInfo } = dashboard.actions;

/* ========= SELECTORS ======== */
export const selectors = {
  getDashboardInfo: state => state.dashboard
};

export default dashboard.reducer;
