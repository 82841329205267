import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import "./spinner.scss";

const Spinner = ({ style, sx, size }) => (
  <div className="loading-container" style={style}>
    <CircularProgress size={size} sx={sx}/>
  </div>
);

export default Spinner;

Spinner.propTypes = { style: PropTypes.object, sx: PropTypes.object, size: PropTypes.string };

Spinner.defaultProps = { style: {} };
