import {
  TextInput,
  SelectInput,
  CheckboxInput,
  PhoneNumberInput
} from "components/form";
import useTranslate from "hooks/use-translate-language";
import { STATES } from "utils/enum";

const EditProfile = () => {
  const translate = useTranslate();
  return (
    <div>
      <h4>{translate("myInformation")}</h4>
      <TextInput
        label={translate("firstName")}
        name="patient_information.first_name"
      />
      <TextInput
        label={translate("lastName")}
        name="patient_information.last_name"
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          gridGap: 20
        }}
      >
        <PhoneNumberInput
          label={translate("primaryPhoneNumber")}
          name="patient_information.phone_number"
          type="tel"
        />
        <CheckboxInput
          name="patient_information.primary_phone_number_text_consent"
          label={translate("consent")}
        />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          gridGap: 20
        }}
      >
        <PhoneNumberInput
          label={translate("secondaryPhoneNumber")}
          name="patient_information.mobile_number"
          type="tel"
        />
        <CheckboxInput
          name="patient_information.secondary_phone_number_text_consent"
          label={translate("consent")}
        />
      </div>
      <div className="address">
        <TextInput
          label={translate("streetAddress")}
          name="patient_information.home_address.street_address"
        />
        <TextInput
          label={translate("streetAddress2")}
          name="patient_information.home_address.street_address2"
        />
        <TextInput
          label={translate("city")}
          name="patient_information.home_address.city"
        />
        <SelectInput
          label={translate("state")}
          name="patient_information.home_address.state"
          options={STATES}
        />
        <TextInput
          label={translate("zip")}
          name="patient_information.home_address.zip"
        />
        <SelectInput
          label={translate("primaryLanguage")}
          name="patient_information.speaks_spanish"
          options={translate("languageOptions")}
        />
      </div>
    </div>
  );
};

export default EditProfile;
