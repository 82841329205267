import React from "react";
import { Modal, Spinner } from "components/ui";
import { MenuItem, Button } from "@mui/material";
import { Formik, Form } from "formik";
import { TextInput } from "components/form";
import { changePassword } from "actions/user";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import useTranslate from "hooks/use-translate-language";

const PASSWORD_REQUIREMENTS =
  "Password must be at least 8 characters long with 1 number, 1 capital and 1 lowercase letter";
const PASSWORD_EXP = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

const ChangePasswordModal = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  return (
    <Modal
      renderTrigger={({ openModal }) => (
        <MenuItem
          onClick={() => {
            openModal();
          }}
        >
          {translate("changePassword")}
        </MenuItem>
      )}
      renderContent={({ closeModal }) => (
        <div
          style={{
            minHeight: 400,
            display: "flex",
            flexDirection: "column",
            padding: 20
          }}
        >
          <h1>{translate("resetPassword")}</h1>
          <Formik
            onSubmit={async values => {
              const { success } = await dispatch(changePassword(values));
              if (success) closeModal();
            }}
            initialValues={{
              current_password: "",
              password: "",
              password_confirmation: ""
            }}
            validationSchema={Yup.object().shape({
              current_password: Yup.string().required("Required."),
              password: Yup.string()
                .required("Required.")
                .matches(PASSWORD_EXP, PASSWORD_REQUIREMENTS),
              password_confirmation: Yup.string()
                .required("Required.")
                .oneOf([Yup.ref("password")], translate("passwordsDoNotMatch"))
            })}
          >
            {({ isSubmitting, isValid }) => (
              <Form>
                <TextInput
                  name="current_password"
                  label={translate("currentPassword")}
                  type="password"
                  tabIndex={0}
                />
                <TextInput
                  name="password"
                  label={translate("newPassword")}
                  tabIndex={1}
                  type="password"
                />
                <TextInput
                  name="password_confirmation"
                  label={translate("newPasswordConfirmation")}
                  tabIndex={2}
                  type="password"
                />
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    marginTop: 20,
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  {isSubmitting ? (
                    <Spinner />
                  ) : (
                    <Button
                      type="submit"
                      disabled={!isValid}
                      variant="contained"
                      color="primary"
                    >
                      {translate("submit")}
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    />
  );
};

export default ChangePasswordModal;
