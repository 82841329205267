import "core-js/stable"; // Loads all language features
import "core-js/modules/es.promise"; // The promise polyfill is imported ...
import { render } from "react-dom";
import "./index.scss";
import App from "./App";
import configureStore from "./configure-store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import "moment-timezone";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blue } from "@mui/material/colors";
import ErrorBoundary from "components/error-boundary";
import { LanguageProvider } from "hooks/use-translate-language";

const theme = createTheme({
  palette: {
    primary: {
      main: blue[500]
    }
  }
});
const store = configureStore();
const persistor = persistStore(store);

const container = document.getElementById("root");
// const root = createRoot(container);

export default render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <LanguageProvider>
            <App />
          </LanguageProvider>
          <NotificationContainer />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
  container
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
