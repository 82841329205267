import { HOST } from "environment";
import browserHistory from "browser-history";
import { prop } from "ramda";
import { Types } from "../actions/api";
import fetch from "isomorphic-fetch";
import { NotificationManager } from "react-notifications";

class ApiError extends Error {
  constructor(body, response, action, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    const message = prop("message", body);
    super(message, ...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = "ApiError";
    this.response = response;
    this.body = body;
    this.action = action;
  }
  notAuthorized = () =>
    !this.action?.publicRoute && this.response?.status === 401;
}
export const getRequestOptions = (
  method,
  payload,
  auth,
  publicRoute,
  headers = {
    "Content-Type": "application/json"
  }
) => {
  if (!publicRoute && !auth) {
    throw new Error("Not Logged In");
  }
  return {
    method,
    headers: {
      Authorization: `Bearer ${prop("authentication_token", auth)}`,
      ...headers
    },
    body: payload instanceof FormData ? payload : JSON.stringify(payload)
  };
};

const handleErrors = async (response, action) => {
  if (!response.ok) {
    const body = await response.json();
    throw new ApiError(body, response, action);
  }
  return response;
};

const handleResponse = async response => {
  const body = await response.json();
  if (Object.prototype.hasOwnProperty.call(body, "success") && !body.success) {
    throw new ApiError(body, response);
  }
  return body;
};

const logApiError = error => {
  if (process.env.NODE_ENV === "development") console.error(error);
};

const apiMiddleware = store => next => async action => {
  if (action.type !== Types.API) {
    next(action);
    return;
  } else {
    const { getState, dispatch } = store;
    const { route, method, body, headers, publicRoute = false } = action;
    try {
      const { auth } = getState();
      const requestOptions = getRequestOptions(
        method,
        body,
        auth,
        publicRoute,
        headers
      );
      // next({ type: Types.API_REQUEST, route, requestOptions });
      const payload = await fetch(`${HOST}/${route}`, requestOptions)
        .then(error => handleErrors(error, action))
        .then(handleResponse);
      // next({ type: Types.API_RESPONSE, route, method, payload });
      return payload;
    } catch (error) {
      logApiError(error);
      // next({ type: Types.API_ERROR, route, method, error });
      if (error.name === "ApiError" && error.notAuthorized()) {
        dispatch({ type: Types.API_NOT_AUTHORIZED });
        NotificationManager.error("Please login", "Your Session has expired");
        browserHistory.push("/login");
      }
      throw error;
    }
  }
};

export default apiMiddleware;
