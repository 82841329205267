import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import selectors from "selectors";
import { fetchPatientsEquipment } from "actions/products";
import { Page, Card } from "components/ui";
import { useHistory } from "react-router-dom";
import "./products.scss";
import useTranslate from "hooks/use-translate-language";

const Products = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const equipment = useSelector(selectors.getPatientsEquipment);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    setIsFetching(true);
    dispatch(fetchPatientsEquipment()).finally(() => {
      setIsFetching(false);
    });
  }, [dispatch]);
  return (
    <Page.Container>
      <Page.Header title={translate("myProducts")} goBack={"/"} />
      <Page.Content
        isFetching={isFetching}
        isEmpty={equipment.length === 0}
        emptyMessage={translate("noProducts")}
      >
        {equipment.map(eq => (
          <Product key={eq.product_id} {...eq} />
        ))}
      </Page.Content>
    </Page.Container>
  );
};

export default Products;

const Product = ({
  product_photo,
  product_id,
  image,
  name,
  spanish_information,
  manufacturer,
  // type,
  // autofit_selection,
  purchase_date
}) => {
  const speaksSpanish = useSelector(selectors.getSpeaksSpanish);
  const translate = useTranslate();
  const history = useHistory();
  return (
    <Card
      style={{ margin: "5px 0px" }}
      onClick={() => {
        history.push(`products/${product_id}`);
      }}
    >
      <div className="product-card">
        <img
          className="product-img"
          src={product_photo ? product_photo : image}
          alt=""
        />
        <div className="product-card-content">
          <div className="product-name">
            {!speaksSpanish ? name : spanish_information ?? name}
          </div>
          <div className="product-manufacturer">{manufacturer}</div>
          <div className="product-purchase-date">
            {translate("purchaseDate")}: {purchase_date}
          </div>
        </div>
      </div>
    </Card>
  );
};

Product.propTypes = {
  product_photo: PropTypes.string,
  product_id: PropTypes.string.isRequired,
  image: PropTypes.string,
  name: PropTypes.string,
  spanish_information: PropTypes.string,
  manufacturer: PropTypes.string,
  type: PropTypes.string,
  autofit_selection: PropTypes.bool,
  purchase_date: PropTypes.string
};
