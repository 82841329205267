export default {
  // Index
  tooManyCharacters: {
    english: "Sorry, too many characters",
    spanish: "Lo siento demasiados caracteres"
  },
  enterFirstName: {
    english: "Please enter first name",
    spanish: "Por favor ingrese su nombre"
  },
  enterLastName: {
    english: "Please enter last name",
    spanish: "Por favor ingrese su apellido"
  },
  // enterPhoneNumber listed in order-form
  enterValidPhoneNumber: {
    english: "Please enter a valid phone number",
    spanish: "Por favor ingrese un número de teléfono válido"
  },
  enterStreetAddress: {
    english: "Please enter street address",
    spanish: "Por favor ingrese la direccion"
  },
  enterState: {
    english: "Please enter state",
    spanish: "Por favor ingrese el estado"
  },
  enterZip: {
    english: "Please enter zip code",
    spanish: "Por favor ingrese el código postal"
  },
  enterValidZip: {
    english: "Please enter a valid zip code",
    spanish: "Por favor ingrese un código postal válido"
  },
  enterCity: {
    english: "Please enter city",
    spanish: "Por favor ingrese la ciudad"
  },
  enterValidFax: {
    english: "Please enter a valid fax number",
    spanish: "Ingrese un número de fax válido"
  },
  // finish listed in order-form
  // next listed in order-form
  // back listed in order-form

  // Edit-Profile
  myInformation: {
    english: "My Information",
    spanish: "Mi informacion"
  },
  firstName: {
    english: "First Name",
    spanish: "Frimer nombre"
  },
  lastName: {
    english: "Last Name",
    spanish: "Apellido"
  },
  consent: {
    english: "Consent",
    spanish: "Consentimiento"
  },
  languageOptions: {
    english: [
      { value: false, text: "English" },
      { value: true, text: "Español" }
    ],
    spanish: [
      { value: false, text: "English" },
      { value: true, text: "Español" }
    ]
  },
  // primaryPhoneNumber listed in profile
  // secondaryPhoneNumber listed in profile
  // streetAddress listed in order-form
  // city listed in order-form
  // state listed in order-form
  // zip listed in order-form
  // primaryLanguage listed in order-form

  // Edit-Insurance
  insuranceInformation: {
    english: "Insurance Information",
    spanish: "Información del seguro"
  },
  // primaryInsurance listed in profile
  // secondaryInsurance listed in profile

  // Edit-Physician
  physicianInformation: {
    english: "Physician Information",
    spanish: "Información del médico"
  },
  faxNumber: {
    english: "Fax Number",
    spanish: "Número de fax"
  }

  // name listed in profile
  // phoneNumber listed in order-form
  // streetAddress listed in order-form
  // city listed in order-form
  // state listed in order-form
  // zip listed in order-form
};
