export default {
  // Index
  // upcomingChats listed in dashboard
  chatHistory: {
    english: "Chat History",
    spanish: "Historial de chat",
  },
  noUpcomingChats: {
    english: "You have no upcoming chats",
    spanish: "No tienes próximos chats",
  },
  join: {
    english: "Join",
    spanish: "Entrar",
  },

  // Join-Chat-Btn
  startsIn: {
    english: "Starts in",
    spanish: "Empieza en",
  },
  days: {
    english: "days",
    spanish: "dias",
  },
  hours: {
    english: "Hrs",
    spanish: "Horas",
  },
  minutes: {
    english: "minutes",
    spanish: "minutos",
  },
  // Reschedule-Chat-Btn
  rescheduleChat: {
    english: "Reschedule Chat",
    spanish: "Reprogramar chat",
  },
  reschedule: {
    english: "Reschedule",
    spanish: "Reprogramar",
  },
  // Upcoming-Chat
  topic: {
    english: "Topic",
    spanish: "Tema",
  },
  description: {
    english: "Description",
    spanish: "Descripción",
  },
  with: {
    english: "With",
    spanish: "Con",
  },
  confirmationPending:{
    english: "Confirmation pending",
    spanish: "Confirmación pendiente",
  },
  // Comes from S3_TimeSlots MasterChatType
  // Text Chat listed in video-chat-history
  "Video Chat": {
    english: "Video Chat",
    spanish: "Chat de video",
  },
  "Product Demo": {
    english: "Product Demo",
    spanish: "Demostración del producto",
  },
  cancelAppointment:{
    english: "Cancel Appointment",
    spanish: "Cancelar cita"
  },
  cancelAppointmentConfirmationMessage: {
    english: "Are you sure you want to cancel the appointment?",
    spanish: "¿Está seguro que desea cancelar su cita?"
  },
  yes: { english: "Yes", spanish:"Sí" },
  statusCancelled: { english: "Canceled", spanish: "Cancelada" },
  startsNow: { english: "Starts now", spanish: 'Comienza ahora' }
};
