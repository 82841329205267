export default {
  loginToPlaceOrder: {
    english: "Login To Place Order",
    spanish: "Iniciar sesión para realizar un pedido"
  },
  confirmDateOfBirth: {
    english: "For security purposes, please confirm your date of birth.",
    spanish: "Por motivos de seguridad, confirme su fecha de nacimiento."
  },
  dateOfBirthError: {
    english: "Date of birth does not match",
    spanish: "La fecha de nacimiento no coincide"
  },
  tryAgainError: {
    english: "Birth dates don't match. Try again.",
    spanish: "Las fechas de nacimiento no coinciden. Intentar otra vez."
  }
};
