import { getOrderEligibleEquipment } from "./helpers";
import { formatDate } from "utils/misc";

export default (profile, company_order_settings) => {
  const patientEquipmentEligibility = profile?.eligibility ?? [];
  const eligibleEquipment = getOrderEligibleEquipment(
    patientEquipmentEligibility,
    company_order_settings
  );
  return {
    patient_id: profile?.user_id ?? "",
    requested_equipment: eligibleEquipment,
    issue_type: "",
    saw_physician_last: "",
    physician: {
      npi: profile?.physician_information?.npi ?? "",
      name: profile?.physician_information?.name ?? "",
      address: profile?.physician_information?.address ?? {
        street_address: "",
        city: "",
        zip: "",
        state: ""
      },
      phone: profile?.physician_information?.phone ?? "",
      fax: profile?.physician_information?.fax ?? ""
    },
    ordered_by: "patient",
    high_priority: false,
    other_issues: "",
    issues_with_pap_device: false,
    supply_period: company_order_settings?.supply_period_options
      ? String(
          company_order_settings?.supply_period_options[
            company_order_settings?.supply_period_options.length - 1
          ]
        )
      : "90",
    changed_insurance: null,
    insurance_card_provided: null,
    changed_physician: false,
    changed_address: false,
    address_change_type: "P",
    address: profile?.patient_information?.home_address ?? {
      street_address: "",
      city: "",
      zip: "",
      state: ""
    },
    caregiver_name: "",
    quantity_on_hand:
      "Only the singular items currently being used and needing replacement. No additional replacements on hand.",
    hours_per_night: "",
    nights_per_week: "",
    mask_dirty: true,
    mask_broken: true,
    mask_loose: true,
    headgear_loose: true,
    headgear_broken: true,
    seal_loose: true,
    seal_broken: true,
    filter_dirty: true,
    tubing_dirty: true,
    tubing_broken: true,
    waterchamber_dirty: true,
    waterchamber_broken: true,
    chinstrap_broken: true,
    chinstrap_loose: true,
    inpatient_facility: false,
    outside_purchase: false,
    changes_since_last_order: false,
    reason_for_no_mask: "",
    office_pickup: false,
    insurances:
      profile?.insurances?.map((insurance, index) => ({
        ...insurance,
        primary_order: index + 1,
        policy_number: insurance.plan_no,
        group_number: insurance.group,
        policy_start_date: formatDate(insurance.policy_start_date, "YYYY/MM/DD")
      })) ?? []
  };
};
