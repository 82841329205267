import * as R from "ramda";
import { getEquipmentQuestions } from "./options";
import * as Yup from "yup";

/* should have alteast one dysfunction for each requested equipment */
export const getIsValidDysfunctionAnswers = formValues => {
  if (!(formValues.insurances[0]?.requires_dysfunctions ?? true)) {
    return true;
  }

  const requiredQuestions = R.pipe(
    R.map(getEquipmentQuestions),
    R.flatten
  )(R.propOr([], "requested_equipment", formValues));

  const hasOneFaultForEachEquipment = data => questions =>
    questions.some(({ key }) => data[key] === true);

  return R.pipe(
    R.groupBy(R.prop("equipment")),
    R.mapObjIndexed(hasOneFaultForEachEquipment(formValues)),
    R.values,
    R.all(R.equals(true))
  )(requiredQuestions);
};

/* require atlest one requested equipment */
export const validateChooseSuppliesForm = ({
  errors,
  setFieldTouched,
  values
}) => {
  if (errors.requested_equipment || values.requested_equipment.length === 0) {
    setFieldTouched("requested_equipment", true);
    return false;
  }
  return true;
};

export const validateUsageForm = ({ errors, setFieldTouched }) => {
  if (
    errors.hours_per_night ||
    errors.nights_per_week ||
    errors.saw_physician_last
  ) {
    setFieldTouched("hours_per_night", true);
    setFieldTouched("nights_per_week", true);
    setFieldTouched("saw_physician_last", true);
    return false;
  }
  return true;
};

export const validatePhysicianForm = ({ errors, setFieldTouched }) => {
  const invalidAddress = (addressErrors = {}) =>
    addressErrors?.street_address ||
    addressErrors?.city ||
    addressErrors?.state ||
    addressErrors?.zip;
  if (
    errors.physician?.phone ||
    errors.physician?.name ||
    invalidAddress(errors.physician?.address)
  ) {
    setFieldTouched("physician.phone", true);
    setFieldTouched("physician.name", true);
    setFieldTouched("physician.address.street_address", true);
    setFieldTouched("physician.address.city", true);
    setFieldTouched("physician.address.state", true);
    setFieldTouched("physician.address.zip", true);
    return false;
  }
  return true;
};

export const validateInsuranceForm = ({ errors, setFieldTouched }) => {
  if (errors.changed_insurance) {
    setFieldTouched("changed_insurance", true);
    return false;
  } else return true;
};

export const validateDysfunctions = ({ values }) => {
  const hasDysfunctionForEveryEquipment = getIsValidDysfunctionAnswers(values);
  if (!hasDysfunctionForEveryEquipment) {
    return false;
  }
  return true;
};

// TODO should really validate only this form and display other errors somewhere
export const validateShippingDetails = ({ errors }) => {
  return !R.isEmpty(errors);
};

export const getOrderFormValidationSchema = (companyOrderFormOptions, translate) => {
  var saw_physician_validation = companyOrderFormOptions.saw_physician_last
    ? Yup.string().required(translate("pleaseSelectUsage"))
    : Yup.string();

  var usage_validation = companyOrderFormOptions.usage
    ? Yup.string().required(translate("pleaseSelectUsage"))
    : Yup.string();

  var updated_physician_validation = companyOrderFormOptions.updated_physician
    ? Yup.string().required(translate("required"))
    : Yup.string();

  const OrderFormValidationSchema = Yup.lazy(values =>
    Yup.object().shape({
      requested_equipment: Yup.array().required(translate("atLeastOne")),
      hours_per_night: usage_validation,
      nights_per_week: usage_validation,
      saw_physician_last: saw_physician_validation,
      changed_insurance: Yup.boolean().required(translate("required")),
      physician: Yup.object().shape({
        name: updated_physician_validation
      }),
      address: Yup.object().shape({
        street_address: Yup.string().required(translate("required")),
        city: Yup.string().required(translate("required")),
        state: Yup.string().required(translate("required")),
        zip: Yup.string().required(translate("required"))
      }),
      dysfunctions: Yup.string().test(
        "dysfunctions",
        translate("requiresADysfunction"),
        () => getIsValidDysfunctionAnswers(values)
      )
    })
  );
  return OrderFormValidationSchema;
}
