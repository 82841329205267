import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import moment from "moment";
import { rescheduleChat } from "actions/chat";
import { convertDateToLocal } from "utils/misc";
import { Modal } from "components/ui";
import TimeSlotSearchForm from "components/schedule-chat/time-slot-search-form";
import useTranslate from "hooks/use-translate-language";

const RescheduleChatButton = ({
  chat_id,
  master_chat_type,
  is_product_demo,
  can_reschedule,
  start_time,
  is_confirmed
}) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [isInPast, setIsInPast] = useState(() =>
    moment().isAfter(convertDateToLocal(start_time))
  );

  useEffect(() => {
    let interval = null;
    if (!isInPast) {
      interval = setInterval(() => {
        setIsInPast(moment().isAfter(convertDateToLocal(start_time)));
      }, 10000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isInPast, start_time]);
  if (!chat_id || !can_reschedule || is_product_demo) return null;
  if (isInPast) return null;
  return is_confirmed ? (
    <div>
      <Modal
        renderTrigger={({ openModal }) => (
          <Button
            onClick={() => {
              openModal();
            }}
            color="primary"
            style={{ marginLeft: 10 }}
          >
            {translate("reschedule")}
          </Button>
        )}
        renderContent={({ closeModal }) => (
          <div
            className="reschedule-chat-modal-container"
            style={{ padding: 10 }}
          >
            <h3 style={{ marginBottom: 20 }}>{translate("rescheduleChat")}</h3>
            <TimeSlotSearchForm
              handleTimeSlotSelect={async ({ slot_id, topic_category_id }) => {
                await dispatch(
                  rescheduleChat({ slot_id, topic_category_id, chat_id })
                );
                closeModal();
              }}
              chat_type={master_chat_type === "Text Chat" ? 1 : 2}
              current_chat_id={ chat_id }
            />
          </div>
        )}
      />
    </div>
  ) : null;
};

RescheduleChatButton.propTypes = {
  chat_type: PropTypes.number,
  slot_id: PropTypes.string,
  start_time: PropTypes.string,
  chat_type_text: PropTypes.string,
  master_chat_type: PropTypes.string.isRequired,
  is_product_demo: PropTypes.bool,
  can_reschedule: PropTypes.bool,
  chat_id: PropTypes.string.isRequired,
};

export default RescheduleChatButton;
