import Dashboard from "components/dashboard";
import Profile from "components/profile";
import EditProfile from "components/edit-profile";
import Orders from "components/orders";
import OrderDetails from "components/orders/order-details";
import OrderForm from "components/order-form";
import Products from "components/products";
import ProductDetails from "components/products/product-details";
import Notifications from "components/notifications";
import Questionnaires from "components/questionnaires";
import SignQuestionnaire from "components/questionnaires/sign-questionnaire";
import VideoChatHistory from "components/video-chat-history";
import Documents from "components/documents";
import ScheduleChat from "components/schedule-chat";
import Signatures from "components/signatures";
import UpcomingChats from "components/upcoming-chats";
import ChatNow from "components/chat-now";
import ProductDemo from "components/product-demo";
import VideoChat from "components/video-chat";
import TextChat from "components/text-chat";
import LoggedInRecallPage from "components/recall-notice/login-recall-page";
import Tracking from "components/tracking";
import KnowledgeBase from "components/knowledge-base";
import PendingAppointment from "components/pending-appointment/pending-appointment";
import Onboarding from "components/onboarding";
import NewAppOrderFormContainer from "components/order-form-new/app-order-container";

export default [
  {
    path: "/",
    component: Dashboard,
    exact: true
  },
  {
    path: "/dashboard",
    component: Dashboard,
    exact: true
  },
  {
    path: "/profile",
    component: Profile,
    exact: true
  },
  {
    path: "/profile/edit",
    component: EditProfile,
    exact: true
  },
  {
    path: "/order-form",
    component: OrderForm,
    exact: true
  },
  {
    path: "/supplies",
    component: NewAppOrderFormContainer,
    exact: true
  },
  {
    path: "/orders",
    component: Orders,
    exact: true
  },
  {
    path: "/orders/:order_id",
    component: OrderDetails
  },
  {
    path: "/tracking",
    component: Tracking,
    exact: true
  },
  {
    path: "/products",
    component: Products,
    exact: true
  },
  {
    path: "/products/:product_id",
    component: ProductDetails
  },
  {
    path: "/notifications",
    component: Notifications,
    exact: true
  },
  {
    path: "/questionnaires",
    component: Questionnaires,
    exact: true
  },
  {
    path: "/questionnaires/:questionnaire_id",
    component: SignQuestionnaire,
    exact: true
  },
  {
    path: "/onboarding",
    component: Onboarding,
    exact: true
  },
  {
    path: "/documents",
    component: Documents,
    exact: true
  },
  {
    path: "/signatures",
    component: Signatures,
    exact: true
  },
  {
    path: "/video-chat-history",
    component: VideoChatHistory,
    exact: true
  },
  {
    path: "/upcoming-chats",
    component: UpcomingChats,
    exact: true
  },
  {
    path: "/chat-now",
    component: ChatNow,
    exact: true
  },
  {
    path: "/product-demo",
    component: ProductDemo,
    exact: true
  },
  {
    path: "/video-chat",
    component: VideoChat,
    exact: true
  },
  {
    path: "/text-chat",
    component: TextChat,
    exact: true
  },
  {
    path: "/schedule-chat",
    component: ScheduleChat,
    exact: true
  },
  {
    path: "/recall",
    component: LoggedInRecallPage
  },
  {
    path: "/help",
    component: KnowledgeBase,
    exact: true
  },
  {
    path: "/new-appointment/:token",
    component: PendingAppointment,
    exact: true
  }
];
