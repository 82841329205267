import { useHistory, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import useTranslate from "hooks/use-translate-language";
import * as Sentry from "@sentry/browser";
import "./styles.scss";

const ThankYouPage = () => {
  const translate = useTranslate();
  const history = useHistory();
  const location = useLocation();

  const message = location.state?.message;
  const shouldPostMessage = location.state?.shouldPostMessage;
  const subMessage = location.state?.subMessage;
  const backTo = location.state?.backTo;

  // Push message to rotech/tekstream portal web app and mobile app to signal that modal window/iframe should close
  function rotechPortalPushMessage() {
    try {
      const message = {
        source: "S3Portal",
        message: "Order Form Submitted"
      };

      if (window && window.parent) {
        window.parent.postMessage(message, "*");
      }

      if (window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage.postMessage(message, "*");
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }
  return (
    <div className="thanks-screen">
      <div className="thanks-container">
        <h1>{translate("thankYou")}</h1>
        <h3>{message}</h3>
        <h4 className="sub-message">{subMessage}</h4>
        <div className="close-btn-container">
          {shouldPostMessage && (
            <Button
              onClick={rotechPortalPushMessage}
              variant="contained"
              color="primary"
            >
              Close
            </Button>
          )}
        </div>
        {backTo && (
          <Button
            onClick={() => {
              if (backTo == "previous") history.goBack();
              else history.push(backTo);
            }}
            variant="contained"
            color="primary"
          >
            {translate("goBack")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ThankYouPage;
