import { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import selectors from "selectors";
import { fetchPatientProfile, updatePatientProfile } from "actions/user";
import { omit, path } from "ramda";
import { Button, MobileStepper, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Page, Spinner } from "components/ui";
import getInitialValues from "./initialValues";
import EditProfile from "./edit-profile";
import EditPhysician from "./edit-physician";
import EditInsurance from "./edit-insurance";
import { useHistory } from "react-router-dom";
import "./edit-profile.scss";
import useTranslate from "hooks/use-translate-language";
import * as Yup from "yup";
import { isValidPhoneNumber, isValidZip, isValidZipEditProfile } from "utils/validation";

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

const EditProfileScreen = () => {
  const translate = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();
  const profile = useSelector(selectors.getPatientProfile);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    if (!profile.user_id) setIsFetching(true);
    dispatch(fetchPatientProfile()).finally(() => {
      setIsFetching(false);
    });
  }, [dispatch]);
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();
  const theme = useTheme();
  const numberOfSteps = 3;

  const EditProfileFormValidation = Yup.object().shape({
    patient_information: Yup.object().shape({
      first_name: Yup.string()
        .max(100, translate("tooManyCharacters"))
        .required(translate("enterFirstName")),
      last_name: Yup.string()
        .max(100, translate("tooManyCharacters"))
        .required(translate("enterLastName")),
      phone_number: Yup.string()
        .required(translate("enterPhoneNumber"))
        .test(
          "valid-number",
          translate("enterValidPhoneNumber"),
          isValidPhoneNumber
        ),
      mobile_number: Yup.string().test(
        "valid-mobile-number",
        translate("enterValidPhoneNumber"),
        isValidPhoneNumber
      ),
      home_address: Yup.object().shape({
        street_address: Yup.string().required(translate("enterStreetAddress")),
        state: Yup.string().required(translate("enterState")),
        zip: Yup.string()
          .required(translate("enterZip"))
          .test("valid-zip", translate("enterValidZip"), isValidZip),
        city: Yup.string().required(translate("enterCity"))
      })
    }),
    patient_insurances: Yup.array()
      .of(
        Yup.object({
          insurance_company_id: Yup.string().required("Required")
        })
      )
      .required("insurance is required"),
    physician_information: Yup.object().shape({
      name: Yup.string().required("Please enter physician name"),
      fax: Yup.string().test(
        "valid-pysician-fax-number",
        translate("enterValidFax"),
        isValidPhoneNumber
      ),
      phone: Yup.string().test(
        "valid-physician-number",
        translate("enterValidPhoneNumber"),
        isValidPhoneNumber
      ),
      address: Yup.object().shape({
        zip: Yup.string().test(
          "valid-physician-zip",
          translate("enterValidZip"),
          isValidZipEditProfile
        )
      })
    })
  });

  return (
    <Page.Container isFetching={isFetching}>
      <Page.Header title={translate("editProfile")} goBack="/profile" />
      <Page.Content isFetching={isFetching}>
        <div className="edit-profile-container">
          <Formik
            initialValues={getInitialValues(profile)}
            validationSchema={EditProfileFormValidation}
            onSubmit={async values => {
              const data = {
                patient_information: {
                  ...omit(
                    ["primary_phone_number", "secondary_phone_number"],
                    values.patient_information
                  ),
                  // phone_number: values.primary_phone_number,
                  phone_numbers: [
                    path(["patient_information", "phone_number"], values),
                    path(["patient_information", "mobile_number"], values)
                  ]
                },
                physician_information: values.physician_information,
                patient_insurances: values.patient_insurances
              };
              await dispatch(updatePatientProfile(data));
            }}
            enableReinitialize={true}
          >
            {formProps => (
              <div className="edit-profile-container">
                {activeStep === 0 && <EditProfile {...formProps} />}
                {activeStep === 1 && (
                  <EditInsurance
                    {...formProps}
                    insurance_types={profile.insurance_types}
                  />
                )}
                {activeStep === 2 && <EditPhysician {...formProps} />}
                <div className="edit-profile-form-stepper">
                  <MobileStepper
                    variant="dots"
                    steps={numberOfSteps}
                    position="static"
                    activeStep={activeStep}
                    className={classes.root}
                    nextButton={
                      formProps.isSubmitting ? (
                        <Spinner />
                      ) : (
                        <Button
                          size="small"
                          onClick={() => {
                            if (activeStep === 2) {
                              formProps.handleSubmit();
                            } else
                              setActiveStep(
                                prevActiveStep => prevActiveStep + 1
                              );
                          }}
                          disabled={activeStep === numberOfSteps}
                        >
                          {activeStep === numberOfSteps - 1
                            ? translate("finish")
                            : translate("next")}
                          {theme.direction === "rtl" ? (
                            <KeyboardArrowLeft />
                          ) : (
                            <KeyboardArrowRight />
                          )}
                        </Button>
                      )
                    }
                    backButton={
                      activeStep === 0 ? (
                        <Button
                          size="small"
                          text="Cancel"
                          onClick={() => history.goBack()}
                        >
                          {translate("back")}
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          onClick={() =>
                            setActiveStep(prevActiveStep => prevActiveStep - 1)
                          }
                          disabled={activeStep === 0}
                        >
                          {theme.direction === "rtl" ? (
                            <KeyboardArrowRight />
                          ) : (
                            <KeyboardArrowLeft />
                          )}
                          {translate("back")}
                        </Button>
                      )
                    }
                  />
                </div>
              </div>
            )}
          </Formik>
        </div>
      </Page.Content>
    </Page.Container>
  );
};

export default EditProfileScreen;
