export default {
  // Index
  // myProducts listed in dasboard
  noProducts: {
    english: "You have no products",
    spanish: "No tienes productos",
  },
  purchaseDate: {
    english: "Purchase Date",
    spanish: "Fecha de compra",
  },
  // Product-FAQs
  productFaqs: {
    english: "Product FAQs",
    spanish: "Preguntas frecuentes sobre productos",
  },
  noProductFaqs: {
    english: "Sorry, currently this product has no FAQs.",
    spanish:
      "Lo sentimos, actualmente no hay preguntas frecuentes para este producto.",
  },
  // Product-Info
  productInformation: {
    english: "Product Information",
    spanish: "Información del Producto",
  },
  manufacturedBy: {
    english: "Manufactured by",
    spanish: "Fabricado por",
  },
  productType: {
    english: "Product Type",
    spanish: "Tipo de producto",
  },
  faqs: {
    english: "FAQs",
    spanish: "Preguntas frecuentes",
  },
  watchVideos: {
    english: "Watch Videos",
    spanish: "Ver videos",
  },
  // Product-Videos
  productVideos: {
    english: "Product Videos",
    spanish: "Vídeos de productos",
  },
  noProductMedia: {
    english: "No Media for this product currently exists",
    spanish: "Actualmente no existen medios para este producto",
  },
  // view is listed in signatures
};
