import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { prop } from "ramda";
import useTranslate from "hooks/use-translate-language";

const Message = ({ author, body, userId, attributes, timestamp }) => {
  if (!author) return <LogMsg body={body} />;
  return userId === author ? (
    <UserMsg
      body={body}
      timestamp={timestamp}
      authorName={prop("authorName", attributes)}
    />
  ) : (
    <ParticipantMsg
      body={body}
      timestamp={timestamp}
      authorName={prop("authorName", attributes)}
    />
  );
};

Message.propTypes = {
  author: PropTypes.string,
  body: PropTypes.string.isRequired,
  userId: PropTypes.string,
  attributes: PropTypes.object,
  timestamp: PropTypes.instanceOf(Date),
};

export default Message;

const ParticipantMsg = ({ authorName, timestamp, body }) => (
  <div className={`message participant-msg`}>
    <div className="msg-details">
      <div className="msg-timestamp">
        {moment(timestamp).format("hh:mm a ")}
      </div>
      <div className="divider">-</div>
      <div className="msg-author">{authorName}</div>
    </div>
    <div className="msg-body">{body}</div>
  </div>
);

ParticipantMsg.propTypes = {
  authorName: PropTypes.string,
  body: PropTypes.string.isRequired,
  timestamp: PropTypes.instanceOf(Date),
};

const UserMsg = ({ timestamp, body }) => {
  const translate = useTranslate();
  return (
    <div className={`message my-msg`}>
      <div className="msg-details">
        <div className="msg-author">{translate("me")}</div>
        <div className="divider">-</div>
        <div className="msg-timestamp">
          {moment(timestamp).format("hh:mm a ")}
        </div>
      </div>
      <div className="msg-body">{body}</div>
    </div>
  );
};

UserMsg.propTypes = {
  authorName: PropTypes.string,
  body: PropTypes.string.isRequired,
  timestamp: PropTypes.instanceOf(Date),
};

const LogMsg = ({ body }) => (
  <div className="message log">
    <div className="msg-body">{body}</div>
  </div>
);

LogMsg.propTypes = {
  body: PropTypes.string.isRequired,
};
