import PropTypes from "prop-types";
import { Button, MobileStepper, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CheckboxInput, RadioInput } from "components/form";
import { Spinner } from "components/ui";
import { Formik, Form } from "formik";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchPatientsEquipment } from "actions/products";
import useTranslate from "hooks/use-translate-language";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

const AcknowledgementForm = ({ profile, handleSubmit, tokenString }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const translate = useTranslate();
  const classes = useStyles();
  const numberOfSteps = 2;
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    dispatch(fetchPatientsEquipment(tokenString));
  }, []);

  const required = translate("required");

  return (
    <Formik
      initialValues={{
        patientId: profile.user_id,
        hasMachine: null,
        recallStatus: null,
        hasAcknowledged: null,
        acceptance: null,
        speaksSpanish: profile.speaks_spanish,
        platform: "Patient Portal"
      }}
      onSubmit={handleSubmit}
      validate={values => {
        let errors = {};
        if (values.hasMachine == "false" && values.hasAcknowledged) {
          return {};
        }
        if (!values.hasMachine) {
          errors.hasMachine = required;
        }
        if (!values.recallStatus) {
          errors.recallStatus = required;
        }
        if (!values.hasAcknowledged) {
          errors.hasAcknowledged = required;
        }
        return errors;
      }}
    >
      {({ values, setFieldValue, isSubmitting, submitForm }) => (
        <Form>
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            {/* Page 1 */}
            {activeStep === 0 && (
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  padding: 10,
                  borderRadius: 5
                }}
              >
                <p style={{ fontSize: 18, color: "red" }}>
                  {translate("listOfMachinesAffected")}
                </p>
                <RadioInput
                  name="hasMachine"
                  label={translate("haveAnyRecalledDevices")}
                  options={translate("hasEquipmentOptions")}
                  onChange={e => {
                    setFieldValue("hasMachine", e.target.value);
                    if (e.target.value == "false")
                      setFieldValue("recallStatus", "Not Affected");
                    else setFieldValue("recallStatus", "");
                  }}
                />
                {/* <SelectPhilipsProducts */}
                {/*   recalledPatientEquipment={recalledPatientEquipment} */}
                {/* /> */}
              </div>
            )}
            {/* Page 2 */}
            {activeStep === 1 && (
              <div>
                {values.hasMachine == "true" && (
                  <div
                    style={{
                      backgroundColor: "#f2f2f2",
                      padding: 10,
                      borderRadius: 5
                    }}
                  >
                    <p style={{ fontSize: 18 }}>
                      {translate("acceptRiskQuestion")}
                    </p>
                    <RadioInput
                      name="recallStatus"
                      label=""
                      options={[
                        { text: translate("yesAccept"), value: "Completed" },
                        {
                          text: translate("askAgainLater"),
                          value: "Pending"
                        }
                      ]}
                    />
                  </div>
                )}
                <div style={{ marginBottom: 20, marginTop: 15 }} />
                <CheckboxInput
                  name="hasAcknowledged"
                  label={translate("informedOfRecall")}
                  onChange={() => {
                    setFieldValue("hasAcknowledged", !values.hasAcknowledged);
                  }}
                />
                <div style={{ marginBottom: 20, marginTop: 15 }} />
              </div>
            )}
          </div>
          <div className="edit-profile-form-stepper">
            <MobileStepper
              variant="dots"
              steps={numberOfSteps}
              position="static"
              activeStep={activeStep}
              className={classes.root}
              nextButton={
                isSubmitting ? (
                  <Spinner />
                ) : (
                  <Button
                    size="small"
                    onClick={() => {
                      if (activeStep !== 1) {
                        setActiveStep(prevActiveStep => prevActiveStep + 1);
                      } else {
                        submitForm();
                      }
                    }}
                    disabled={!values.hasMachine}
                    type={activeStep === numberOfSteps ? "submit" : "button"}
                  >
                    {activeStep === numberOfSteps - 1 ? "Finish" : "Next"}
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                )
              }
              backButton={
                activeStep === 0 ? (
                  <Button
                    size="small"
                    onClick={() => history.push("/dashboard")}
                  >
                    {translate("cancel")}
                  </Button>
                ) : (
                  <Button
                    size="small"
                    onClick={() =>
                      setActiveStep(prevActiveStep => prevActiveStep - 1)
                    }
                    disabled={isSubmitting}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    {translate("back")}
                  </Button>
                )
              }
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

AcknowledgementForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  tokenString: PropTypes.string
};

export default AcknowledgementForm;
