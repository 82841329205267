import { useState, useEffect } from "react";
import selectors from "selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPatientDocuments,
  fetchDocumentCategories,
} from "actions/documents";
import { Page, Modal } from "components/ui";
import DocumentItem from "./document";
import UploadPatientDocumentForm from "./upload-document-form";
import { IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import "./documents.scss";
import useTranslate from "hooks/use-translate-language";

const Documents = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const documents = useSelector(selectors.getPatientDocuments);
  const [isFetching, setIsFetching] = useState(true);
  useEffect(() => {
    const loadData = async () => {
      try {
        setIsFetching(true);
        await dispatch(fetchPatientDocuments());
        await dispatch(fetchDocumentCategories());
      } finally {
        setIsFetching(false);
      }
    };
    loadData();
  }, [dispatch]);

  return (
    <Page.Container>
      <Page.Header
        title={translate("myDocuments")}
        goBack
        action={
          <Modal
            renderTrigger={({ openModal }) => (
              <IconButton
                onClick={openModal}
                aria-controls="add-document"
                aria-label="add-document"
                aria-haspopup="true"
                color="primary"
                size="small"
              >
                <Add />
              </IconButton>
            )}
            renderContent={UploadPatientDocumentForm}
          />
        }
      />
      <Page.Content
        isEmpty={documents.length === 0}
        emptyMessage={translate("noDocuments")}
        isFetching={isFetching}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            padding: 5,
            flexDirection: "column",
          }}
        >
          {documents.map((doc) => (
            <DocumentItem key={doc.document_id} {...doc} />
          ))}
        </div>
      </Page.Content>
    </Page.Container>
  );
};

export default Documents;
