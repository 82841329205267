export default {
  // Thank-You
  connecting: {
    english: "Connecting",
    spanish: "Conectando",
  },
  chatRoomClosed: {
    english: "Chat Room Closed",
    spanish: "Sala de chat cerrada",
  },
  // textChat is in twilio-chat
};
