export default {
  // Index
  frequentlyAskedQuestions: {
    english: "Frequently Asked Questions",
    spanish: "Preguntas frecuentes"
  },
  aboutMySupplies: {
    english: "About my supplies",
    spanish: "Sobre mis suministros"
  },
  maskLeakAir: {
    english: "Why does my mask leak air?",
    spanish: "¿Por qué mi máscara pierde aire?"
  },
  maskLeakAirAnswer: {
    english:
      "You should first try to tighten the bottom straps on the headgear. If this does not fix your problem then you will need to order a new seal/pillow replacement. The seal is worn out. The amount of time you wear it, the natural oils on your face, and regular washing will wear out the seal on the mask over time.",
    spanish:
      "Primero debe intentar apretar las correas inferiores del arnés. Si esto no soluciona su problema, deberá solicitar un nuevo reemplazo de sello / almohada. El sello está desgastado. La cantidad de tiempo que la use, los aceites naturales en su cara y el lavado regular desgastarán el sello de la mascarilla con el tiempo."
  },
  gettingReddness: {
    english: "Why am I getting a redness from my mask?",
    spanish: "¿Por qué mi máscara enrojece mi piel?"
  },
  gettingReddnessAnswer: {
    english:
      "You could be wearing your mask too tight, not cleaning it well enough, or it may be wearing out. Try to clean your mask first. If your mask is still leaving a rash/redness then you will need to replace your seal.",
    spanish:
      "Es posible que esté usando la mascarilla demasiado ajustada, que no la limpie lo suficientemente bien o que se esté desgastando. Intenta limpiar tu mascarilla primero. Si su mascarilla todavía deja una erupción / enrojecimiento, deberá reemplazar su sello."
  },
  changeToDifferentMask: {
    english: "How can I change to a different mask?",
    spanish: "¿Cómo puedo cambiar a una máscara diferente?"
  },
  changeToDifferentMaskAnswer: {
    english:
      "You will need to talk with your Sleep Coach to verify that you can change to another mask. If you are eligible, then you would need to be set up for a fitting and get your new mask.",
    spanish:
      "Deberá hablar con su entrenador de sueño para verificar que puede cambiar a otra máscara. Si es elegible, deberá programar una cita para que un terapeuta respiratorio le coloque una nueva mascarilla."
  },
  waterInTubing: {
    english: "Why is there water in my tubing?",
    spanish: "¿Por qué hay agua en mi tubo?"
  },
  waterInTubingAnswer: {
    english:
      "You may have to insulate the tubing during the winter months to prevent condensation. An insulation cover can be ordered from your sleep coach.",
    spanish:
      "Es posible que deba aislar la tubería durante los meses de invierno para evitar la condensación. Puede solicitar una funda aislante a su entrenador del sueño."
  },
  cleaningEquipment: {
    english: "Cleaning equipment",
    spanish: "Equipo de limpieza"
  },
  cleanMySupplies: {
    english: "How do I clean my supplies?",
    spanish: "¿Cómo limpio mis suministros?"
  },
  cleanMySuppliesDailyAnswer: {
    english:
      "Wash the mask system with warm, soapy water. Please make sure that you use unscented soap. We recommend plain Dawn dish soap. Rinse well and allow to air dry out of direct sunlight.",
    spanish:
      'Lave el sistema de mascarilla con agua tibia y jabón. Asegúrate de usar jabón sin perfume. Recomendamos el jabón para platos que se llama "Dawn." Enjuague bien y seque al aire. No exponga la luz directa.'
  },
  cleanMySuppliesWeeklyAnswer: {
    english:
      "Separate and hand wash the mask components and headgear in warm, soapy water. Do not use soaps with added scents. Rinse the components well and allow them to air dry out of direct sunlight.",
    spanish:
      "Separe y lave a mano los componentes de la mascarilla y el arnés con agua tibia y jabón. No use jabones con aromas añadidos. Enjuague bien los componentes y déjelos secar al aire. No exponga la luz directa."
  },
  daily: {
    english: "Daily",
    spanish: "Diario"
  },
  weekly: {
    english: "Weekly",
    spanish: "Semanal"
  },
  precautionsToTake: {
    english: "What precautions should I take?",
    spanish: "¿Qué precauciones debo tomar?"
  },
  precautionsToTakeAnswer: {
    english:
      "Do not use solutions containing vinegar, bleach, chlorine, alcohol, aromatics, moisturizers, antibacterial agents or scents to clean any part of the system or air tubing. These solutions may cause damage and reduce the life of the product. Do not expose any part of the system or tubing to direct sunlight as it may deteriorate the product. If any visible deterioration of a component is apparent (cracking, tears, etc.) the component should be discarded and replaced.",
    spanish:
      "No use soluciones que contengan vinagre, lejía, cloro, alcohol, aromáticos, humectantes, agentes antibacterianos o aromas para limpiar cualquier parte del sistema o del tubo de aire. Estas soluciones pueden causar daños y reducir la vida útil del producto. No exponga ninguna parte del sistema o tubería a la luz solar directa, ya que puede deteriorar el producto. Si cualquier deterioro visible de un componente es aparente (grietas, rasgaduras, etc.), el componente debe desecharse y reemplazarse."
  },
  headgearMayBeWashed: {
    english: "Note: The headgear may be washed without being taken apart.",
    spanish: "Nota: El arnés se puede lavar sin desarmar."
  },
  reorderingSupplies: {
    english: "Reordering Supplies",
    spanish: "Reordenación de suministros"
  },
  howOftenToOrder: {
    english: "How often do I need to order supplies?",
    spanish: "¿Con qué frecuencia debo pedir suministros?"
  },
  howOftenToOrderAnswer: {
    english:
      "It is important to replace your PAP supplies every 3-6 months. Masks, tubing, and filters wear out over time and your equipment may not be as effective as possible. This also aids in keeping infections down to a minimum.",
    spanish:
      "Es importante reemplazar sus suministros de PAP cada 3 a 6 meses. Las máscaras, los tubos y los filtros se desgastan con el tiempo y es posible que su equipo no sea lo más eficaz posible. Esto también ayuda a reducir al mínimo las infecciones."
  },
  howOftenToOrderMethods: {
    english:
      "We offer several methods of contact to remind you of your replacement supplies: Automated Call, Email Notification, Post Card, and Text Message. If you have not been receiving notifications please make sure that your sleep coach has your correct information and that you have chosen a form of contact.",
    spanish:
      "Ofrecemos varios métodos de contacto para recordarle sus suministros de repuesto: automático de llamadas, notificación por correo electrónico, postal y mensajes de texto. Si no ha recibido notificaciones, asegúrese de que su entrenador de sueño tenga la información correcta y de que haya elegido una forma de contacto."
  }
};
