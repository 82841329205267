import React from "react";
import PropTypes from "prop-types";
// import { useSelector } from "react-redux";
// import selectors from "selectors";
import { Modal } from "components/ui";
import { formatDate } from "utils/misc";
import { Button } from "@mui/material";
import DuplicateOrderForm from "./duplicate-order-form";
import useTranslate from "hooks/use-translate-language";

const DuplicatePreviousOrder = ({ token }) => {
  const translate = useTranslate();
  //disable this until we have time to fix issues with this feature
  const availableOrder = false;//useSelector(selectors.getAvailableOrderToDuplicate);
  if (availableOrder)
    return (
      <Modal
        fullScreen
        renderTrigger={({ openModal }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%"
            }}
          >
            <div
              style={{
                padding: 10,
                marginBottom: 15,
                display: "flex",
                background: "#f1f1f1",
                border: "1px solid #ccc",
                borderRadius: "5px",
                width: "100%",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                paddingLeft: 20,
                paddingRight: 20
              }}
            >
              <div
                style={{
                  display: "flex",
                  paddingBottom: 25,
                  alignItems: "flex-start",
                  justifyContent: "center",
                  flexDirection: "column"
                }}
              >
                <div>
                  <span style={{ fontWeight: "bold", paddingRight: 5 }}>
                    {translate("orderedOn")}:
                  </span>
                  {formatDate(availableOrder.order_date)}
                </div>
                <div>
                  <span style={{ fontWeight: "bold", paddingRight: 5 }}>
                    {translate("supplies")}:
                  </span>
                  {availableOrder.requested_equipment
                    .map(x => translate(x))
                    .join(", ")}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: 10,
                  width: "100%"
                }}
              >
                <Button color="primary" variant="contained" onClick={openModal}>
                  {translate("reorderPastSupplies")}
                </Button>
              </div>
            </div>
          </div>
        )}
        renderContent={({ closeModal }) => (
          <DuplicateOrderForm
            orderId={availableOrder._id}
            handleClose={closeModal}
            token={token}
          />
        )}
      />
    );
  return null;
};

export default DuplicatePreviousOrder;

DuplicatePreviousOrder.propTypes = { token: PropTypes.string };
