import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { Spinner } from "components/ui";
import { Button } from "@mui/material";
import { PASSWORD_EXP, PASSWORD_REQUIREMENTS } from "utils/validation";
import Logo from "assets/images/icon_trans.png";
import { TextInput } from "components/form";
import { useDispatch } from "react-redux";
import { resetExpiredPassword } from "actions/auth";
import useTranslate from "hooks/use-translate-language";

const ExpiredPassword = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const { state = {} } = useLocation();
  return (
    <div className="login-screen reset-password">
      <div className="login-screen-header">
        <img src={Logo} className="login-logo" alt="logo" />
        <div>{translate("passwordExpired")}</div>
      </div>
      <Formik
        initialValues={{
          ...state,
          password: "",
          password_confirmation: ""
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().required("email required"),
          current_password: Yup.string().required("current password required"),
          password: Yup.string()
            .required("Required.")
            .matches(PASSWORD_EXP, PASSWORD_REQUIREMENTS),
          password_confirmation: Yup.string()
            .required("Required.")
            .oneOf([Yup.ref("password")], translate("passwordsDoNotMatch"))
        })}
        onSubmit={async values => {
          await dispatch(resetExpiredPassword(values));
        }}
        enableReinitialize={true}
      >
        {({ isSubmitting, isValid }) => (
          <Form className="reset-password-form">
            <TextInput
              type="password"
              name="password"
              label={translate("newPassword")}
            />
            <TextInput
              type="password"
              name="password_confirmation"
              label={translate("confirmNewPassword")}
              style={{ marginBottom: 30 }}
            />
            {isSubmitting ? (
              <Spinner />
            ) : (
              <Button
                variant="contained"
                color="primary"
                id="reset-password-button"
                type="submit"
                disabled={!isValid}
              >
                {translate("changePassword")}
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ExpiredPassword;
