import { createSlice } from "@reduxjs/toolkit";
import { assoc, prop, propOr, pipe } from "ramda";
import { createSelector } from "reselect";

/* ========= Reducer ======== */
export const chat = createSlice({
  name: "chat",
  initialState: {
    history: [],
    upcoming: [],
    topics: [],
    available_time_slots: [],
    chatNow: {}
  },
  reducers: {
    setChatHistory: (state, { payload }) => assoc("history", payload, state),
    setUpcomingChats: (state, { payload }) => assoc("upcoming", payload, state),
    setChatTopics: (state, { payload }) => assoc("topics", payload, state),
    setAvailableTimeSlots: (state, { payload }) =>
      assoc("available_time_slots", payload, state),
    setChatNowDetails: (state, { payload }) => assoc("chatNow", payload, state)
  }
});

/* ========= Actions ======== */
export const {
  setChatHistory,
  setUpcomingChats,
  setChatTopics,
  setAvailableTimeSlots,
  setChatNowDetails
} = chat.actions;

/* ========= SELECTORS ======== */
export const selectors = {
  getChatHistory: createSelector([prop("chat")], propOr([], "history")),
  getUpcomingChats: createSelector(
    [prop("chat")],
    pipe(
      propOr([], "upcoming") //chats =>
      // chats.sort(
      //   (a, b) =>
      //     new Date(nth(0, a.chats).chat_details.date_time).getTime() -
      //     new Date(nth(0, b.chats).chat_details.date_time).getTime()
      // )
    )
  ),
  getChatTopics: createSelector([prop("chat")], propOr([], "topics")),
  getAvailableTimeSlots: createSelector(
    [prop("chat")],
    propOr([], "available_time_slots")
  ),
  getChatNowDetails: createSelector([prop("chat")], propOr({}, "chatNow"))
};

export default chat.reducer;
