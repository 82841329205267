export default {
  // Signatures
  mySignatures: {
    english: "My Signatures",
    spanish: "Mis firmas",
  },
  noDocuments: {
    english: "You have no documents",
    spanish: "No tienes documentos",
  },
  unsigned: {
    english: "Unsigned",
    spanish: "no firmado",
  },
  signNow: {
    english: "Sign Now",
    spanish: "Firmar ahora",
  },
  signed: {
    english: "Signed",
    spanish: "Firmado",
  },
  view: {
    english: "View",
    spanish: "Vista",
  },
};
