import { selectors as authSelectors } from "./reducers/auth";
import { selectors as dashboardSelectors } from "./reducers/dashboard";
import { selectors as documentSelectors } from "./reducers/documents";
import { selectors as signatureSelectors } from "./reducers/signatures";
import { selectors as orderSelectors } from "./reducers/orders";
import { selectors as productSelectors } from "./reducers/products";
import { selectors as notificationSelectors } from "./reducers/notifications";
import { selectors as userSelectors } from "./reducers/user";
import { selectors as chatSelectors } from "./reducers/chat";
import { selectors as questionnaireSelectors } from "./reducers/questionnaires";
import { selectors as maskFittingSelectors } from "./reducers/mask-fitting";
import { selectors as twilioSelectors } from "./reducers/twilio";
import { selectors as twilioChatSelectors } from "./reducers/twilio-chat";
import { selectors as twilioVideoSelectors } from "./reducers/twilio-video";

export default {
  ...authSelectors,
  ...dashboardSelectors,
  ...documentSelectors,
  ...signatureSelectors,
  ...orderSelectors,
  ...productSelectors,
  ...notificationSelectors,
  ...userSelectors,
  ...chatSelectors,
  ...questionnaireSelectors,
  ...maskFittingSelectors,
  ...twilioSelectors,
  ...twilioChatSelectors,
  ...twilioVideoSelectors
};
