import PropTypes from "prop-types";

const SideBarItem = ({ icon, text, onClick }) => {
  return (
    <div className="side-bar-item" onClick={onClick}>
      {icon && <img src={icon} alt="logout" />}
      <span>{text}</span>
    </div>
  );
};

export default SideBarItem;

SideBarItem.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string,
  onClick: PropTypes.func
};
