import { useEffect, useState } from "react";
import { Page } from "components/ui";
import { useDispatch, useSelector } from "react-redux";
import selectors from "selectors";
import { fetchPatientProfile } from "actions/user";
import { submitOrder } from "actions/orders";
import { Formik } from "formik";
import getInitialValues from "./initial-values";
import { getOrderFormValidationSchema } from "./validation";
import OrderFormContainer from "./order-form";
import "./order-form.scss";
import useTranslate from "hooks/use-translate-language";

const OrderForm = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const profile = useSelector(selectors.getPatientProfile);
  const [isFetching, setIsFetching] = useState(false);

  const companyOrderFormOptions = useSelector(
    selectors.getCompanyOrderFormOptions
  );

  useEffect(() => {
    setIsFetching(true);
    //dispatch(fetchPatientOrders());
    dispatch(fetchPatientProfile()).finally(() => {
      setIsFetching(false);
    });
  }, [dispatch]);
  const [activeStep, setActiveStep] = useState(0);

  const OrderFormValidationSchema = getOrderFormValidationSchema(
    companyOrderFormOptions,
    translate
  );

  return (
    <Page.Container style={{ margin: 0 }}>
      <Page.Header
        title={translate("orderSupplies")}
        goBack="/"
        closeText="areYouSureYouWantToCancel"
      />
      <Page.Content isFetching={isFetching}>
        <Formik
          initialValues={{
            ...getInitialValues(profile, companyOrderFormOptions),
            order_create_method: "Patient Portal"
          }}
          onSubmit={async values => {
            await dispatch(submitOrder(values));
          }}
          initialTouched={{
            caregiver_name: true,
            changed_insurance: true,
            requested_equipment: true
          }}
          validationSchema={OrderFormValidationSchema}
          enableReinitialize
        >
          {() => (
            <OrderFormContainer
              profile={profile}
              setActiveStep={setActiveStep}
              activeStep={activeStep}
            />
          )}
        </Formik>
      </Page.Content>
    </Page.Container>
  );
};

export default OrderForm;
