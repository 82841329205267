import PropTypes from "prop-types";
import { ListItem } from "components/ui";
import { propOr, pipe, find, prop } from "ramda";
import { formatDate, formatAddress, formatPhone } from "utils/misc";
import useTranslate from "hooks/use-translate-language";

const Info = ({ patientInfo }) => {
  const translate = useTranslate();
  const getPhoneNumber = (primary, phone_numbers) =>
    pipe(
      propOr([], "phone_numbers"),
      find(x => x.primary == primary),
      prop("phone_number"),
      formatPhone
    )(phone_numbers);
  return (
    <div className="profile-panel">
      <h4 className="profile-panel-header">
        {`${propOr("", "first_name", patientInfo)} ${propOr(
          "",
          "last_name",
          patientInfo
        )}`}
      </h4>
      <div className="profile-content">
        <ListItem label={translate("email")} value={patientInfo.email} />
        <ListItem
          label={translate("primaryPhoneNumber")}
          value={getPhoneNumber(true, patientInfo)}
        />
        <ListItem
          label={translate("secondaryPhoneNumber")}
          value={getPhoneNumber(false, patientInfo)}
        />
        <ListItem
          label={translate("address")}
          value={formatAddress(propOr({}, "home_address", patientInfo))}
        />
        <ListItem
          label={translate("eligibleForSupplies")}
          value={formatDate(patientInfo.insurance_eligibility_date)}
        />
        <ListItem
          label={translate("primaryLanguage")}
          value={translate("englishOrSpanish")}
        />
      </div>
    </div>
  );
};

Info.propTypes = { patientInfo: PropTypes.object.isRequired };

export default Info;
