import { Formik } from "formik";
import * as Yup from "yup";
import { login } from "actions/auth";
import { Spinner } from "components/ui";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Button } from "@mui/material";
import Logo from "assets/images/icon_trans.png";
import { useHistory } from "react-router-dom";
import selectors from "selectors";
import { translateUsingBrowser } from "utils/misc";
import secureLocalStorage from "react-secure-storage";
import MultifactorModalContainer from "./multifactor-modal-container";

const getCompanyNameInCookie = () => {
  try {
    const cookieData = decodeURIComponent(document.cookie);
    return JSON.parse(cookieData)?.company_name ?? "";
  } catch (e) {
    return "";
  }
};

export const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loginError = useSelector(selectors.getLoginError);
  const loginMessage = useSelector(selectors.getLoginMessage);
  const companyName = getCompanyNameInCookie();
  const userDetails = useSelector(selectors.getUserDetails);
  const userMFADetails = useSelector(selectors.getUserMFADetails);

  const getFieldError = (field, errors, touched) => {
    return touched[field] ? errors[field] : "";
  };

  const rememberMeValue = secureLocalStorage.getItem("rememberMe") 

  return (
    <div className="login-screen">
      <MultifactorModalContainer
        open={userMFADetails.MFAAuthRequired || false}
        userMFADetails={userMFADetails}
      />

      <div className="login-screen-header">
        <div>{companyName}</div>
        <img src={Logo} className="login-logo" alt="logo" />
        <div>{translateUsingBrowser("login")}</div>
      </div>
      <Formik
        initialValues={{
          email: rememberMeValue ?? "",
          password: "",
          rememberMe: rememberMeValue != null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required(translateUsingBrowser("emailIsRequired"))
            .email(translateUsingBrowser("enterValidEmail")),
          password: Yup.string().required(
            translateUsingBrowser("passwordIsRequired")
          ),
          rememberMe: Yup.boolean()
        })}
        onSubmit={async values => {
          await dispatch(login(values));
        }}
      >
        {({
          isSubmitting,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          values
        }) => (
          <form className="login-form" onSubmit={handleSubmit}>
            <TextField
              id="email"
              label={translateUsingBrowser("email")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              name="email"
              error={!!getFieldError("email", errors, touched)}
              helperText={getFieldError("email", errors, touched)}
              type="email"
              style={{ marginBottom: 20, flex: 1 }}
              autoComplete="new-password" //only way to disable autoComplete
            />
            <TextField
              id="password"
              label={translateUsingBrowser("password")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              name="password"
              error={!!getFieldError("password", errors, touched)}
              helperText={getFieldError("password", errors, touched)}
              type="password"
              autoComplete="new-password" //only to disable autoComplete
            />
            <div style={{ display: "flex", alignItems: "center", gap: "4px", marginTop: "18px" }} >
              <input
                style={{ height: "18px", width: "18px" }}
                value={values.rememberMe}
                id="rememberMe"
                name="rememberMe"
                type="checkbox"
                checked={values.rememberMe}
                onChange={handleChange}
              />
              <label>Remember Me</label>
            </div>
            <div className="login-info">
              <div className="login-message">{loginMessage}</div>
              <div className="login-error">{loginError}</div>
            </div>
            {isSubmitting ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: 120
                }}
              >
                <Spinner />
              </div>
            ) : (
              <div className="login-form-actions">
                <Button
                  variant="contained"
                  color="primary"
                  id="login-button"
                  type="submit"
                >
                  {translateUsingBrowser("login")}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  id="login-button"
                  type="button"
                  onClick={() => {
                    history.push("/register");
                  }}
                >
                  {translateUsingBrowser("firstTimeLogin")}
                </Button>
              </div>
            )}
          </form>
        )}
      </Formik>
      <div className="login-info-text">
        <Button
          onClick={() => {
            history.push("/forgot-password");
          }}
        >
          {translateUsingBrowser("forgotPassword")}
        </Button>
        <span style={{ paddingTop: 20, fontSize: 12, color: "#777" }}>
          * {translateUsingBrowser("agreeToTerms")}
        </span>
        <Button
          onClick={() => {
            window.open("https://www.papresupply.com/terms-of-use");
          }}
        >
          {translateUsingBrowser("termsOfUse")}
        </Button>
      </div>
    </div>
  );
};

export default Login;
