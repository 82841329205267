export default {
  // Video-Chat-History
  videoChatHistory: {
    english: "Video/Chat History",
    spanish: "Historial de video/chat",
  },
  noChatHistory: {
    english: "You have no video/chat history",
    spanish: "No tienes historial de video/chat",
  },
  textChatWith: {
    english: "Text Chat with",
    spanish: "Chat de texto con",
  },
  videoChatWith: {
    english: "Video Chat with",
    spanish: "Chat de video con",
  },
  to: {
    english: "to",
    spanish: "a",
  },
  momentLanguage: {
    english: "en",
    spanish: "es",
  },
  // Comes from history_chats route - chat_details.title
  "Text Chat": {
    english: "Text Chat",
    spanish: "Chat de texto",
  },
  "CPAP Supplies": {
    english: "CPAP Supplies",
    spanish: "Suministros de CPAP",
  },
  "CPAP Setup": {
    english: "CPAP Setup",
    spanish: "Configuración de CPAP",
  },
  "CPAP Machine": {
    english: "CPAP Machine",
    spanish: "Máquina de CPAP",
  },
  "Chat Now": {
    english: "Chat Now",
    spanish: "Chatea ahora",
  },
};
