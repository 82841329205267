import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import selectors from "selectors";
import { updateLanguage } from "actions/auth";

const useLanguageCheck = () => {
  const speaksSpanish = useSelector(selectors.getSpeaksSpanish);
  const browserLang = window.navigator.language;
  const dispatch = useDispatch();
  useEffect(() => {
    //Patient's browser is set to Spanish
    if (localStorage.getItem("answeredLanguageCheck")) return;

    if (!speaksSpanish && browserLang.startsWith("es")) {
      if (confirm("¿Quiere actualizar su perfil de paciente al español?")) {
        dispatch(updateLanguage(true));
      }
      localStorage.setItem("answeredLanguageCheck", true);
    }
    if (speaksSpanish && browserLang.startsWith("en")) {
      if (confirm("Do you want to update your patient profile to English?")) {
        dispatch(updateLanguage(false));
      }
      localStorage.setItem("answeredLanguageCheck", true);
    }
  }, [speaksSpanish]);
};

export default useLanguageCheck;
