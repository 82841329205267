import PropTypes from "prop-types";
import useTranslate from "hooks/use-translate-language";
import { SelectInput, TextInput } from "components/form";
import { FieldArray } from "formik";
import { useSelector } from "react-redux";
import selectors from "selectors";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/lab/Autocomplete";
import { formatDate } from "utils/misc";

const getInsuranceDisplayName = index => {
  switch (index) {
    case 0:
      return "Primary";
    case 1:
      return "Secondary";
    case 2:
      return "Tertiary";
    default:
      return "";
  }
};

const EditInsurance = ({ values, setFieldValue, errors }) => {
  const translate = useTranslate();
  const companyInsuranceOptions = useSelector(
    selectors.getPatientInsuranceOptions
  );
  const guarantorRelationOptions = useSelector(
    selectors.getGuarantorRelationOptions
  );
  return (
    <div>
      <h4>{translate("insuranceInformation")}</h4>
      <div style={{ marginTop: 20 }}>
        <FieldArray
          name="patient_insurances"
          render={arrayHelpers => (
            <div>
              {values.patient_insurances?.map((_, index) => (
                <div key={index} style={{ padding: 5 }}>
                  <Autocomplete
                    id="insurance-box"
                    getOptionSelected={(option, value) => {
                      return (
                        option?.insurance_company_id ==
                        value?.insurance_company_id
                      );
                    }}
                    options={companyInsuranceOptions.map(x => ({
                      ...x,
                      insurance_company_id: x.value,
                      insurance_company_name: x.text
                    }))}
                    getOptionLabel={option => option.insurance_company_name}
                    value={values.patient_insurances[index]}
                    onChange={(_, newValue) => {
                      setFieldValue(`patient_insurances.${index}`, {
                        ...values.patient_insurances[index],
                        ...newValue,
                        primary_order: index + 1
                      });
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={
                          errors.patient_insurances?.[index]
                            ?.insurance_company_id
                        }
                        helperText={
                          errors.patient_insurances?.[index]
                            ?.insurance_company_id
                        }
                        InputLabelProps={{ shrink: true }}
                        label={getInsuranceDisplayName(index) + " Insurance"}
                        variant="outlined"
                      />
                    )}
                  />
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                      alignItems: "center",
                      gridGap: 10
                    }}
                  >
                    <TextInput
                      name={`patient_insurances.${index}.plan_no`}
                      type="text"
                      label="Policy Number"
                    />
                    <TextInput
                      name={`patient_insurances.${index}.group`}
                      type="text"
                      label="Group Number"
                    />
                    <SelectInput
                      name={`patient_insurances.${index}.guarantor_relation`}
                      component="select"
                      label="Guarantor Relationship"
                      options={guarantorRelationOptions}
                    />
                    <TextInput
                      name={`patient_insurances.${index}.policy_start_date`}
                      type="date"
                      label="Policy Start Date"
                      InputLabelProps={{ shrink: true }}
                      value={formatDate(
                        values.patient_insurances[index].policy_start_date,
                        "YYYY-MM-DD"
                      )}
                    />
                    {/*<TextInput
                  name={`insurances.${index}.policy_end_date`}
                  type="date"
                  label="Policy End Date"
                  InputLabelProps={{ shrink: true }}
                  value={formatDate(
                    values.insurances[index].policy_end_date,
                    "YYYY-MM-DD"
                  )}
                  disabled={disabled}
                />*/}
                    {index > 0 && (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <button onClick={() => arrayHelpers.remove(index)}>
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                  {index == 0 && <hr style={{ margin: "20px 0px" }} />}
                </div>
              ))}
              {values.patient_insurances?.length == 1 && (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <button
                    type="button"
                    style={{ margin: 0, padding: "5px 15px" }}
                    onClick={() =>
                      arrayHelpers.push({
                        insurance_company_id: "",
                        insurance_company_name: "",
                        primary_order: 2,
                        policy_number: "",
                        group_number: "",
                        guarantor_relation: "",
                        policy_start_date: ""
                        //policy_end_date: ""
                      })
                    }
                  >
                    Add Secondary Insurance
                  </button>
                </div>
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
};

EditInsurance.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default EditInsurance;
