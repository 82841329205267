export default {
  // Index
  stopReceivingEmails: {
    english: "Would you like to stop receiving emails from us for 30 days?",
    spanish:
      "¿Le gustaría dejar de recibir nuestros correos electrónicos durante 30 días?",
  },
  confirm: {
    english: "Confirm",
    spanish: "Confirmar",
  },
  sorryLinkNoLongerValid: {
    english: "Sorry, link is no longer valid",
    spanish: "Lo sentimos, el enlace ya no es válido",
  },
};
