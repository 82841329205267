import React from "react";
import Modal from "components/ui/modal";
import ReactPlayer from "react-player/lazy";
import "./video-player.scss";
import { IconButton, DialogTitle } from "@mui/material";
import { Close } from "@mui/icons-material";
import PropTypes from "prop-types";

const VideoPlayerModal = ({ open, url, handleClose, videoTileTitle }) => {
    
  return (
    <Modal
      className="video-modal"
      fullWidth={true}
      maxWidth={"xl"}
      open={open}
      renderTrigger={() => null}
      startOpen={true}
      onClose={async () => {
        handleClose();
      }}
      aria-labelledby="customized-dialog-title"
      renderContent={() => (
        <div style={{position: "relative"}}>
            <DialogTitle sx={{ backgroundColor: "white" }} id="customized-dialog-title">
                {videoTileTitle}
            </DialogTitle>
            <div className="player-wrapper">
                <ReactPlayer
                    width="100%"
                    height="100%"
                    controls={true}
                    config={{
                        file: {
                            attributes: {
                            controlsList: "nodownload"
                            }
                        }
                    }}
                    url={url}
                />
            </div>
            <IconButton
                aria-label="close"
                onClick={() => handleClose()}
                sx={{
                    position: 'absolute',
                    right: "8px",
                    top: "10px",
                    color: "black"
                }}
            >
            <Close />
            </IconButton>
        </div>
      )}
    />
  );
};

VideoPlayerModal.propTypes = {
    open: PropTypes.bool,
    url: PropTypes.string,
    handleClose: PropTypes.func,
    videoTileTitle: PropTypes.string
};

export default VideoPlayerModal;

