export default {
  // Dashboard and Order Form banners
  dashboardRecallText: {
    english:
      "There has been a recall on Philips machines for health concerns and you may be affected.",
    spanish:
      "Se han retirado del mercado las máquinas Philips por motivos de salud y es posible que usted se vea afectado.",
  },
  learnMore: {
    english: "Learn More",
    spanish: "Aprende más",
  },
  orderFormRecallText: {
    english:
      "For your safety, you cannot complete an order until you have acknowledged the Philips machine recall.",
    spanish:
      "Por su seguridad, no puede completar un pedido hasta que haya confirmado el retiro del mercado de la máquina Philips.",
  },
  acknowlegeHere: {
    english: "Acknowlege Here",
    spanish: "Reconocer aquí",
  },
  // Recall Pages
  recallNotice: {
    english: "Recall Notice",
    spanish: "Aviso de retirada",
  },
  hasEquipmentOptions: {
    english: [
      { text: "Yes, I have a recalled model", value: "true" },
      { text: "No, I do not have a recalled model", value: "false" },
    ],
    spanish: [
      { text: "Sí, tengo un modelo retirado", value: "true" },
      { text: "No, no tengo un modelo retirado", value: "false" },
    ],
  },

  // Acknowledgement-Form

  // Required in order-form
  cancel: {
    english: "Cancel",
    spanish: "Cancelar",
  },
  submit: {
    english: "Submit",
    spanish: "Enviar",
  },
  informedOfRecall: {
    english:
      "By checking the box, you acknowledge that you were informed of the Philips recall.",
    spanish:
      "Al marcar la casilla, reconoce que se le informó sobre el retiro del mercado de Philips.",
  },
  haveAnyRecalledDevices: {
    english: "Do you have one of these impacted models?",
    spanish: "",
  },
  youOwnThisMachine: {
    english: "According to our records, you own this recalled machine:",
    spanish: "According to our records, you own this recalled machine:",
  },
  youOwnTheseMachines: {
    english: "According to our records, you own these recalled machines:",
    spanish:
      "Según nuestros registros, eres propietario de estas máquinas retiradas:",
  },
  submitToPhilipsForYou: {
    english:
      "Would you like us to submit your recalled machines to Philips for you?",
    spanish:
      "¿Le gustaría que le enviáramos sus máquinas retiradas del mercado a Philips?",
  },
  philipsRecallText: {
    english:
      "You agree Philips may hold your data to meet its legal obligations in the field of product safety, to investigate reported problems and to improve its products. your information may be shared with regulatory authorities and other entities of the Philips group, including in the U.S. where a different level of protection applies. Philips has put in place appropriate contractual protections as required by law. For more information about this or to exercise your right of access, rectification, restriction, erasure and portability please contact Philips’s Data Protection Officer by submitting your request at:",
    spanish:
      "Acepta que Philips puede conservar sus datos para cumplir con sus obligaciones en el campo de la seguridad de los productos, para investigar reportó problemas y para mejorar sus productos. tu la información se puede compartir con las autoridades reguladoras y otras entidades del grupo Philips, incluso en EE. UU. donde se aplica un nivel de protección diferente. Philips ha puesto en el lugar las protecciones contractuales apropiadas según lo requerido por ley. Para más información sobre esto o para ejercer su derecho de acceso, rectificación, restricción, supresión y portabilidad póngase en contacto con el responsable de protección de datos de Philips enviando su solicitud en:.",
  },
  lodgeComplaint: {
    english:
      "You also have a right to lodge a complaint with the Supervisory Authority for data protection. Philips stores your information for the duration imposed by applicable laws.",
    spanish:
      "También tiene derecho a presentar una reclamación ante la Autoridad supervisora ​​de protección de datos. Philips almacena su información durante el tiempo que imponen las leyes aplicables.",
  },
  readTheConsentCheck: {
    english:
      "By checking the box, you acknowledge you have read the consent and agree to proceed.",
    spanish:
      "Al marcar la casilla, reconoce que ha leído el consentimiento y acepta continuar.",
  },
  addRecalledMachines: {
    english:
      "Please add all of your recalled machines and as many many serial numbers as you have.",
    spanish:
      "Agregue todas las máquinas retiradas del mercado y tantos números de serie como tenga.",
  },
  addAMachine: {
    english: "Add a machine",
    spanish: "Agregar una máquina",
  },
  acceptRiskQuestion: {
    english:
      "Philips Healthcare is advising you to contact your physician regarding your therapy as well as talk with Philips directly about the recent recall. Philips can be reached at 877-907-7508. What would you like to do today?",
    spanish:
      "Philips Healthcare le recomienda que se comunique con su médico con respecto a su terapia, así como que hable directamente con Philips sobre el retiro reciente. Puede comunicarse con Philips al 877-907-7508. ¿Qué te gustaría hacer hoy?",
  },
  yesAccept: {
    english: "Move forward with placing orders for supplies",
    spanish: "Acepto el riesgo asociado con el pedido de suministros",
  },
  askAgainLater: {
    english:
      "I would like to hold on ordering supplies until I speak with my physician",
    spanish:
      "Me gustaría retener el pedido de suministros hasta que hable con mi médico",
  },
  // Recalled-Philips-Products
  listOfMachinesAffected: {
    english:
      "Philips healthcare recently issued a recall affecting cpap and bilevel pap machines. The impacted models are the Dreamstation, the System One, and the Dreamstation Go travel pap.",
    spanish:
      "Philips healthcare emitió recientemente un retiro del mercado que afecta a las máquinas cpap y bilevel pap. Los modelos afectados son Dreamstation, System One y Dreamstation Go travel pap.",
  },
  // Current-Equipment
  serialNumber: {
    english: "Serial Number",
    spanish: "Número de serie",
  },
  ozoneOrActivatedOxygen: {
    english: "Was ozone or activated oxygen used to sterilize the device?",
    spanish:
      "¿Se ha utilizado ozono u oxígeno activado para esterilizar el dispositivo?",
  },

  // Philips-Questions-Provider
  "questionnaireResponses.first_name": {
    english: "What is your first name?",
    spanish: "¿Cuál es tu primer nombre?",
  },
  "questionnaireResponses.last_name": {
    english: "What is your last name?",
    spanish: "¿Cual es tu apellido?",
  },
  "questionnaireResponses.home_address": {
    english: "What is your address?",
    spanish: "¿Cual es tu dirección?",
  },
  "questionnaireResponses.phone_number": {
    english: "What is your phone number?",
    spanish: "¿Cuál es tu número de teléfono?",
  },
  "questionnaireResponses.phone_type": {
    english: "What type of phone number was provided?",
    spanish: "¿Qué tipo de número de teléfono se proporcionó?",
  },
  "questionnaireResponses.phone_type_options": {
    english: [
      { option_text: "Cell", option_id: "Cell" },
      { option_text: "Home", option_id: "Home" },
    ],
    spanish: [
      { option_text: "Célula", option_id: "Cell" },
      { option_text: "Casa", option_id: "Home" },
    ],
  },
  "questionnaireResponses.email": {
    english: "What is your email address?",
    spanish: "¿Cuál es tu dirección de correo electrónico?",
  },
  "questionnaireResponses.additional_info": {
    english:
      "If you would like to receive additional information please select how you would like to receive the information.",
    spanish:
      "Si desea recibir información adicional, seleccione cómo le gustaría recibir la información.",
  },
  "questionnaireResponses.additional_info_options": {
    english: [
      { option_text: "Email", option_id: "Email" },
      { option_text: "Text", option_id: "Text" },
      { option_text: "Phone Call", option_id: "Phone Call" },
    ],
    spanish: [
      { option_text: "Correo electrónico", option_id: "Email" },
      { option_text: "Texto", option_id: "Home" },
      { option_text: "Phone Call", option_id: "Phone Call" },
    ],
  },
};
