import { useDispatch, useSelector } from "react-redux";
import selectors from "selectors";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextField, Button } from "@mui/material";
import { registerAccount } from "actions/auth";
import { Spinner } from "components/ui";
import { isValidEmail } from "utils/validation";
import Logo from "assets/images/icon_trans.png";
import { translateUsingBrowser } from "utils/misc";
import { PhoneNumberInput } from "components/form";

const RegisterValidationScheme = Yup.object().shape({
  first_name: Yup.string()
    .max(100, translateUsingBrowser("tooManyCharacters"))
    .required(translateUsingBrowser("enterFirstName")),
  last_name: Yup.string()
    .max(100, translateUsingBrowser("tooManyCharacters"))
    .required(translateUsingBrowser("enterLastName")),
  email_adress: Yup.string()
    .required(translateUsingBrowser("pleaseEnterEmail"))
    .test("valid-email", translateUsingBrowser("enterValidEmail"), value =>
      isValidEmail(value)
    ),
  phone_number: Yup.string().required(
    translateUsingBrowser("enterPhoneNumber")
  ),
  dob: Yup.string().required(translateUsingBrowser("dateOfBirth"))
});

export const Register = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const registrationError = useSelector(selectors.getUserRegistrationError);

  const getFieldError = (field, errors, touched) => {
    return touched[field] ? errors[field] : "";
  };

  return (
    <div className="login-screen">
      <div className="login-screen-header">
        <img src={Logo} className="login-logo" alt="logo" />
        <div>{translateUsingBrowser("register")}</div>
      </div>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email_adress: "",
          phone_number: "",
          dob: ""
        }}
        validationSchema={RegisterValidationScheme}
        onSubmit={async values => {
          await dispatch(registerAccount(values));
        }}
      >
        {({
          isSubmitting,
          handleChange,
          handleSubmit,
          errors,
          isValid,
          touched,
          handleBlur,
          values
        }) => (
          <form className="login-form" onSubmit={handleSubmit}>
            <TextField
              id="first_name"
              label={translateUsingBrowser("firstName")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.first_name}
              name="first_name"
              error={!!getFieldError("first_name", errors, touched)}
              helperText={getFieldError("first_name", errors, touched)}
              type="text"
              style={{ marginBottom: 20 }}
            />
            <TextField
              id="last_name"
              label={translateUsingBrowser("lastName")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.last_name}
              name="last_name"
              error={!!getFieldError("last_name", errors, touched)}
              helperText={getFieldError("last_name", errors, touched)}
              type="text"
              style={{ marginBottom: 20 }}
            />
            <TextField
              id="email_adress"
              label={translateUsingBrowser("email")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              name="email_adress"
              error={!!getFieldError("email_adress", errors, touched)}
              helperText={getFieldError("email_adress", errors, touched)}
              type="email"
              style={{ marginBottom: 20 }}
            />
            <PhoneNumberInput
              id="phone"
              label={translateUsingBrowser("phoneNumber")}
              name="phone_number"
              type="text"
              style={{ marginBottom: 20 }}
            />
            <TextField
              id="dob"
              label={translateUsingBrowser("dateOfBirth")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.dob}
              name="dob"
              error={!!getFieldError("dob", errors, touched)}
              helperText={getFieldError("dob", errors, touched)}
              type="date"
              style={{ marginBottom: 20 }}
              InputLabelProps={{ shrink: true }}
            />
            <div className="login-error">{registrationError}</div>
            {isSubmitting ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: 120
                }}
              >
                <Spinner />
              </div>
            ) : (
              <div className="login-form-actions">
                <Button
                  variant="contained"
                  color="primary"
                  id="register-btn"
                  type="submit"
                  disabled={!isValid}
                >
                  {translateUsingBrowser("registerAccount")}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  {translateUsingBrowser("backToLogin")}
                </Button>
              </div>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Register;
