import { createContext, useState } from "react";
import Login from "./login";
import ForgotPassword from "./forgot-password";
import Register from "./register";
import { Switch, Route } from "react-router-dom";
import "./login-pages.scss";

export const LoginContext = createContext();

const LoginPages = () => {
  const [loginError, setLoginError] = useState("");
  const [registrationError, setRegistrationError] = useState("");
  const [loginMessage, setLoginMessage] = useState("");
  return (
    <LoginContext.Provider
      value={{
        loginError,
        setLoginError,
        registrationError,
        setRegistrationError,
        loginMessage,
        setLoginMessage
      }}
    >
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/register" component={Register} />
      </Switch>
    </LoginContext.Provider>
  );
};

export default LoginPages;
