import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { assoc, prop, propOr, pathOr } from "ramda";

/* ========= Reducer ======== */
export const user = createSlice({
  name: "user",
  initialState: { profile: {}, form_attributes: {} },
  reducers: {
    setPatientProfile: (state, { payload }) => assoc("profile", payload, state),
    setPatientFormAttributes: (state, { payload }) =>
      assoc("form_attributes", payload, state)
  }
});

/* ========= Actions ======== */
export const { setPatientProfile, setPatientFormAttributes } = user.actions;

/* ========= SELECTORS ======== */
const getPatientProfile = createSelector([prop("user")], propOr({}, "profile"));

const getPatientCompanyPhoneNumber = createSelector(
  [getPatientProfile],
  pathOr("", ["patient_company_details", "phone"])
);

const getPatientInfo = createSelector(
  [getPatientProfile],
  propOr({}, "patient_information")
);
const getPatientEligibility = createSelector(
  [getPatientProfile],
  propOr([], "eligibility")
);

const getPatientInsuranceOptions = createSelector(
  [pathOr({}, ["user", "form_attributes"])],
  propOr([], "insurance_companies")
);

const getGuarantorRelationOptions = createSelector(
  [pathOr({}, ["user", "form_attributes"])],
  propOr([], "guarantor_options")
);

const getCompanyOrderFormOptions = createSelector(
  [pathOr({}, ["user", "form_attributes"])],
  propOr([], "company_order_settings")
);

export const selectors = {
  getPatientProfile,
  getPatientInfo,
  getPatientEligibility,
  getPatientCompanyPhoneNumber,
  getPatientInsuranceOptions,
  getGuarantorRelationOptions,
  getCompanyOrderFormOptions
};

export default user.reducer;
