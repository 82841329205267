import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import selectors from "selectors";

const Participant = ({ participant, members }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const videoRef = useRef();
  const audioRef = useRef();
  const userId = useSelector(selectors.getUserId);

  const trackpubsToTracks = trackMap =>
    Array.from(trackMap.values())
      .map(publication => publication.track)
      .filter(track => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = track => {
      if (track.kind === "video") {
        setVideoTracks(videoTracks => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks(audioTracks => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = track => {
      if (track.kind === "video") {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      console.debug("attaching video track");
      videoTrack.attach(videoRef.current);
      return () => {
        console.debug("dettaching video track");
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      console.debug("attaching audio track");
      audioTrack.attach(audioRef.current);
      return () => {
        console.debug("dettaching audio track");
        audioTrack.detach();
      };
    }
  }, [audioTracks]);
  const participantsName =
    members &&
    members.length > 0 &&
    members.filter(m => m.id === participant.identity) &&
    members.filter(m => m.id === participant.identity).length > 0 &&
    members.filter(m => m.id === participant.identity)[0].name;
  return (
    <div
      className={`video-container ${
        userId === participant.identity ? "local" : "remote"
      }`}
    >
      <p
        className={`name ${
          userId === participant.identity ? "local" : "remote"
        }`}
      >
        {participantsName}
      </p>
      <video ref={videoRef} autoPlay={true} className="video-output" />
      <audio ref={audioRef} autoPlay={true} muted={false} />
    </div>
  );
};

Participant.propTypes = {
  participant: PropTypes.shape({
    videoTracks: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)), //weak map
    audioTracks: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)), //weak map
    identity: PropTypes.string,
    removeAllListeners: PropTypes.func,
    on: PropTypes.func
  }),
  members: PropTypes.arrayOf(PropTypes.object),
  localParticipant: PropTypes.bool
};

export default Participant;
