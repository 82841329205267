import { createSlice } from "@reduxjs/toolkit";
import {
  prop,
  assocPath,
  mergeLeft,
  values,
  indexBy,
  propOr,
  assoc
} from "ramda";
import { createSelector } from "reselect";

/* ========= Reducer ======== */
export const products = createSlice({
  name: "products",
  initialState: {},
  reducers: {
    setPatientsEquipment: (state, { payload }) =>
      indexBy(prop("product_id"), payload),
    setEquipmentFaqs: (state, { payload }) =>
      assocPath([payload.product_id, "faqs"], prop("faqs", payload), state),
    setEquipmentVideos: (state, { payload }) =>
      assocPath([payload.product_id, "videos"], prop("media", payload), state),
    setEquipmentDetails: (state, { payload }) =>
      assoc(
        payload.product_id,
        mergeLeft(
          payload.product_details,
          propOr({}, payload.product_id, state)
        ),
        state
      )
  }
});

/* ========= Actions ======== */
export const {
  setPatientsEquipment,
  setEquipmentFaqs,
  setEquipmentVideos,
  setEquipmentDetails
} = products.actions;

/* ========= SELECTORS ======== */

const getPatientsEquipment = createSelector([prop("products")], values);
const getProductById = createSelector(
  [prop("products"), (_, product_id) => product_id],
  (equipment, product_id) => propOr({}, product_id, equipment)
);
const getProductFaqs = createSelector([getProductById], propOr([], "faqs"));
const getProductVideos = createSelector([getProductById], propOr([], "videos"));

export const selectors = {
  getPatientsEquipment,
  getProductById,
  getProductFaqs,
  getProductVideos
};

export default products.reducer;
