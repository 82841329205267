import PropTypes from "prop-types";
import { Page } from "components/ui";
import { Divider } from "@mui/material";
import { propOr } from "ramda";
import useTranslate from "hooks/use-translate-language";

const ProductFAQs = ({ product_id, product }) => {
  const translate = useTranslate();
  const faqs = propOr([], "faqs", product);
  return (
    <Page.Container>
      <Page.Header
        title={translate("productFaqs")}
        goBack={`/products/${product_id}`}
      />
      <Page.Content
        isEmpty={faqs.length === 0}
        emptyMessage={translate("noProductFaqs")}
      >
        {faqs.map(({ faq_id, question, answer }) => (
          <div
            style={{
              padding: 15,
            }}
            key={faq_id}
          >
            <h3>{question}</h3>
            <p
              style={{
                marginTop: 5,
                fontFamily: "Poppins-SemiBold",
                fontSize: 14,
                color: "#141c36b3",
              }}
            >
              {answer}
            </p>
            <Divider />
          </div>
        ))}
      </Page.Content>
    </Page.Container>
  );
};

ProductFAQs.propTypes = {
  product: PropTypes.object.isRequired,
  product_id: PropTypes.string.isRequired,
};

export default ProductFAQs;
