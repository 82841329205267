import PropTypes from "prop-types";
import { ListItem } from "components/ui";
import useTranslate from "hooks/use-translate-language";
import { formatDate } from "utils/misc";

const Insurance = ({ insurances }) => {
  const translate = useTranslate();
  return (
    <div className="profile-panel">
      <h4 className="profile-panel-header">{translate("insurances")}</h4>
      <div
        className="profile-content"
        style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: 5 }}
      >
        {insurances.map((x, i) => (
          <div key={i}>
            <h3>{i == 0 ? "Primary" : "Secondary"} Insurance</h3>
            <div style={{ paddingLeft: 10 }}>
              <ListItem label="Insurance" value={x.insurance_company_name} />
              <ListItem label="Group Number" value={x.group} />
              <ListItem label="Policy Number" value={x.plan_no} />
              <ListItem
                label="Guarantor Relation"
                value={x.guarantor_relation}
              />
              <ListItem
                label="Policy Start Date"
                value={formatDate(x.policy_start_date)}
              />
              <ListItem
                label="Policy End Date"
                value={formatDate(x.policy_end_date)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

Insurance.propTypes = {
  insurances: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Insurance;
