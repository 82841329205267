import PropTypes from "prop-types";
import SideBarItem from "./side-bar-item";
import LogoutIcon from "assets/images/logout.png";
import ProfileIcon from "assets/images/my_profile.png";
import NotificationIcon from "assets/images/notification.png";
import HelpIcon from "assets/images/questionnaires.png";
import { logoutUser } from "actions/auth";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Drawer, Hidden, Divider } from "@mui/material";
import UserWelcome from "./user-welcome";
import "./side-bar.scss";
import useTranslate from "hooks/use-translate-language";

const SideBar = ({ window, mobileOpen, handleDrawerToggle }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const translate = useTranslate();
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleItemClick = location => {
    if (mobileOpen) {
      handleDrawerToggle();
    }
    history.push(location);
  };

  const drawer = (
    <div className="side-bar">
      <div className="side-bar-content">
        <UserWelcome />
        <Divider />
        <SideBarItem
          text={translate("myProfile")}
          icon={ProfileIcon}
          onClick={(event) => {
            handleItemClick("/profile", event);
          }}
        />
        <SideBarItem
          text={translate("notifications")}
          icon={NotificationIcon}
          onClick={(event) => {
            handleItemClick("/notifications", event);
          }}
        />
        <SideBarItem
          text="Help"
          icon={HelpIcon}
          onClick={event => {
            handleItemClick("/help", event);
          }}
        />
        <Divider />
      </div>
      <Divider />
      <div className="logout-container">
        <SideBarItem
          icon={LogoutIcon}
          text={translate("logout")}
          onClick={() => {
            dispatch(logoutUser());
          }}
        />
      </div>
    </div>
  );
  return (
    <nav className="drawer">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: "drawerPaper",
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      {/* <Hidden xsDown implementation="css"> */}
      {/*   <Drawer */}
      {/*     classes={{ */}
      {/*       paper: "drawerPaper" */}
      {/*     }} */}
      {/*     variant="permanent" */}
      {/*     open */}
      {/*   > */}
      {/*     {drawer} */}
      {/*   </Drawer> */}
      {/* </Hidden> */}
    </nav>
  );
};

export default SideBar;

SideBar.propTypes = {
  window: PropTypes.object,
  mobileOpen: PropTypes.bool,
  handleDrawerToggle: PropTypes.func.isRequired,
};
