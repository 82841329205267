import { createSlice } from "@reduxjs/toolkit";
import { assoc, prop, propOr, pathOr, assocPath } from "ramda";
import { createSelector } from "reselect";

const initialState = {
  authentication_token: "",
  timezone: "",
  fcmToken: "",
  user_details: {},
  user_mfa_details: {},
  app_permissions: {},
  loginMessage: "",
  loginError: "",
  forgotPasswordError: "",
  forgotPasswordMessage: "",
  registerAccountError: "",
  registerAccountMessage: "",
  closeRecallDialog: false
};
/* ========= Reducer ======== */
export const auth = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setFcmToken: (state, { payload }) => assoc("fcmToken", payload, state),
    loginSuccess: (
      state,
      {
        payload: {
          user_details,
          authentication_token,
          app_permissions,
          show_recall_prompt,
          timezone,
          user_timezone_last_login
        }
      }
    ) => ({
      ...initialState,
      app_permissions,
      authentication_token,
      user_details,
      show_recall_prompt,
      timezone,
      user_timezone_last_login
    }),
    loginMFARequired: (state, { payload: { user_details } }) => ({
      ...initialState,
      user_mfa_details: user_details
    }),
    loginError: (state, { payload }) => ({
      ...initialState,
      loginError: payload
    }),
    logout: () => ({ ...initialState }),
    forgotPasswordSuccess: (state, { payload: { message } }) =>
      assoc("loginMessage", message, initialState),
    forgotPasswordError: (state, { payload: loginError }) =>
      assoc("forgotPasswordError", loginError, state),
    registerAccountSuccess: (state, { payload: { message } }) =>
      assoc("registerAccountMessage", message, state),
    registerAccountError: (state, { payload }) =>
      assoc("registerAccountError", payload, state),
    setLanguage: (state, { payload }) =>
      assocPath(["user_details", "speaks_spanish"], Boolean(payload), state),
    resetRecallPrompt: assocPath(["show_recall_prompt"], false),
    closeRecallDialog: assocPath(["closeRecallDialog"], true),
    setAskForConsentAsFalse: (state, { payload }) => 
      assocPath(["user_details", "consent_required"], Boolean(payload), state)
  }
});

/* ========= Actions ======== */
export const {
  setFcmToken,
  loginSuccess,
  loginMFARequired,
  loginError,
  logout,
  forgotPasswordSuccess,
  forgotPasswordError,
  registerAccountError,
  registerAccountSuccess,
  setLanguage,
  resetRecallPrompt,
  closeRecallDialog,
  setAskForConsentAsFalse
} = auth.actions;

/* ========= SELECTORS ======== */
const select_auth = state => state.auth;

export const selectors = {
  getUserAuthValid: createSelector(
    [select_auth],
    auth => !!prop("authentication_token", auth)
  ),
  getLoginMessage: createSelector([select_auth], prop("loginMessage")),
  getLoginError: createSelector([select_auth], prop("loginError")),
  getForgotPasswordError: createSelector(
    [select_auth],
    prop("forgotPasswordError")
  ),
  getUserRegistrationError: createSelector(
    [select_auth],
    prop("registerAccountError")
  ),
  getAppPermissions: createSelector(
    [select_auth],
    propOr({}, ["app_permissions"])
  ),
  getUserDetails: createSelector([select_auth], propOr({}, "user_details")),
  getUserMFADetails: createSelector(
    [select_auth],
    propOr({}, "user_mfa_details")
  ),
  getUserId: createSelector(
    [select_auth],
    pathOr("", ["user_details", "user_id"])
  ),
  getUserName: createSelector(
    [select_auth],
    pathOr("", ["user_details", "name"])
  ),
  getAuthenticationToken: createSelector(
    [select_auth],
    propOr("", "authentication_token")
  ),
  getTwilioToken: createSelector(
    [select_auth],
    pathOr("", ["user_details", "twilio_token"])
  ),
  getSpeaksSpanish: createSelector(
    [select_auth],
    pathOr("", ["user_details", "speaks_spanish"])
  ),
  // If you close the dialog, still want users to see the banner
  getShowRecallPrompt: createSelector(
    [select_auth],
    pathOr("", ["show_recall_prompt"])
  ),
  getRecallDialogClosed: createSelector(
    [select_auth],
    pathOr("", ["closeRecallDialog"])
  ),
  getUsersCompany: createSelector(
    [select_auth],
    pathOr("", ["user_details", "company_name"])
  ),
  getUserTextConsent: createSelector(
    [select_auth],
    pathOr("", ["user_details"])
  ),
  getUserTimeZone: createSelector(
    [select_auth],
    pathOr("", ["timezone"])
  ),
  getLastLogin: createSelector(
    [select_auth],
    propOr("", "user_timezone_last_login")
  )
};

export default auth.reducer;
