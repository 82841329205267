export default {
  onboarding: { english: "Onboarding", spanish: "Onboarding" },
  orderSupplies: { english: "Order Supplies", spanish: "Pedir suministros" },
  scheduleChat: { english: "Schedule Chat", spanish: "Programar chat" },
  upcomingChats: { english: "Upcoming Chats", spanish: "Próximos Chats" },
  myProducts: { english: "My Products", spanish: "Mis productos" },
  myOrders: { english: "My Orders", spanish: "Mis ordenes" },
  myDocuments: { english: "My Documents", spanish: "Mis documentos" },
  signatures: { english: "Signatures", spanish: "Firmas" },
  tracking: { english: "Tracking", spanish: "Seguimiento" },
  onlineChat: { english: "Online Chat", spanish: "Chat en línea" },
  questionnaires: { english: "Questionnaires", spanish: "Cuestionarios" },
  total: { english: "Total", spanish: "Total" },
  chatWithRep: {
    english: "Chat with a representative",
    spanish: "Chatear con un representante"
  },

  orders: { english: "Orders", spanish: "ordenes" },
  documents: { english: "Documents", spanish: "documentos" },
  products: { english: "Products", spanish: "productos" },
  textConsent: {
      english: "Consent to receive SMS messages!",
      spanish: "Consentimiento para recibir mensajes SMS!",
  },
  textConsentMessage: {
    english: "By checking this box you consent to receive text messages on your mobile. You can unsubscribe later from Your Profile configuration",
    spanish: "Al marcar esta casilla, usted accede a recibir mensajes de texto en su teléfono. Podrá desubscribirse desde la configuración de su perfil."
  },
  phoneNumberLabel: { english: "Your mobile:", spanish: "Su teléfono" }
};
