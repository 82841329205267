import React from "react";
import { Modal, Spinner } from "components/ui";
import { MenuItem, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotificationSettings,
  updateNotificationSettings,
} from "actions/notifications";
import { Formik, Form } from "formik";
import { CheckboxInput } from "components/form";
import selectors from "selectors";
import useTranslate from "hooks/use-translate-language";

const NotificationSettingsModal = () => {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = React.useState(false);
  const translate = useTranslate();
  const notificationSettings = useSelector(
    selectors.getPatientNotificationSettings
  );
  React.useEffect(() => {
    setIsFetching(true);
    dispatch(fetchNotificationSettings()).finally(() => {
      setIsFetching(false);
    });
  }, [dispatch]);
  return (
    <Modal
      renderTrigger={({ openModal }) => (
        <MenuItem
          onClick={() => {
            openModal();
          }}
        >
          {translate("notificationSettings")}
        </MenuItem>
      )}
      renderContent={({ closeModal }) => (
        <div
          style={{
            minHeight: 400,
            display: "flex",
            padding: 5,
            flex: 1,
            flexDirection: "column",
          }}
        >
          <h1 style={{paddingLeft: "10px"}}>{translate("notificationSettings")}</h1>
          {isFetching ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flex: 1,
                justifyContent: "center",
              }}
            >
              <Spinner />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                padding: 10,
              }}
            >
              <div>{translate("typeOfNotifications")}</div>
              <Formik
                onSubmit={async (values) => {
                  await dispatch(updateNotificationSettings(values));
                  closeModal();
                }}
                initialValues={{ ...notificationSettings }}
              >
                {({ isSubmitting }) => (
                  <Form
                    style={{
                      margin: "0px 20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <CheckboxInput
                        name="sms"
                        label={translate("smsMessages")}
                      />
                      <CheckboxInput name="email" label={translate("email")} />
                      <CheckboxInput
                        name="push_notifications"
                        label={translate("pushNotifications")}
                      />
                    </div>
                    <h5>{translate("options")}</h5>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <CheckboxInput
                        name="options.video_chat"
                        label={translate("videoChats")}
                      />
                      <CheckboxInput
                        name="options.text_chat"
                        label={translate("textChats")}
                      />
                      <CheckboxInput
                        name="options.questionnaires"
                        label={translate("questionnaires")}
                      />
                      <CheckboxInput
                        name="options.file_upload"
                        label={translate("fileUploads")}
                      />
                      <CheckboxInput
                        name="options.profile_update"
                        label={translate("profileUpdates")}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {isSubmitting ? (
                        <Spinner />
                      ) : (
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                        >
                          {translate("saveChanges")}
                        </Button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default NotificationSettingsModal;
