import { createSlice } from "@reduxjs/toolkit";
import { assoc, assocPath, prop, propOr, path } from "ramda";
import { createSelector } from "reselect";

/* ========= Reducer ======== */
export const twilio = createSlice({
  name: "twilio",
  initialState: {
    chat: {
      windowOpen: false,
      connecting: false,
      active: false
    }
  },
  reducers: {
    chatLaunched: state =>
      assoc(
        "chat",
        {
          ...state.chat,
          windowOpen: true,
          connecting: true,
          active: true
        },
        state
      ),
    toggleChatWindow: state =>
      assocPath(
        ["chat", "windowOpen"],
        !path(["chat", "windowOpen"], state),
        state
      ),
    setChatActive: (state, { payload }) => assoc("chatActive", payload, state)
  }
});

/* ========= Actions ======== */
export const { setChatActive, chatLaunched, toggleChatWindow } = twilio.actions;

/* ========= SELECTORS ======== */
const getTwilioChat = createSelector([prop("twilio")], propOr({}, "chat"));

export const selectors = {
  getTwilioChatActive: createSelector([getTwilioChat], prop("active")),
  getChatWindowOpen: createSelector([getTwilioChat], prop("windowOpen"))
};

export default twilio.reducer;
