import { setPatientSignatures } from "reducers/signatures";
import { API } from "actions/api";

export const fetchPatientSignatures = () => async dispatch => {
  try {
    const { signature_documents } = await dispatch({
      type: API,
      route: "patient_portal/mobile/signature_documents",
      method: "GET"
    });
    dispatch(setPatientSignatures(signature_documents));
    return signature_documents;
  } catch (error) {
    console.error(error.message);
  }
};
