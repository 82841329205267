import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import selectors from "selectors";
import { fetchPatientOrders } from "actions/orders";
import { Page, Card } from "components/ui";
import { formatDateTimeLocal } from "utils/misc";
import { toLower, isEmpty } from "ramda";
import "./orders.scss";
import { useHistory } from "react-router";
import useTranslate from "hooks/use-translate-language";

const statusColor = status => {
  switch (toLower(status)) {
    case "new":
      return "#2596D8";
    case "rejected":
      return "#F44336";
    case "completed":
      return "#43A047";
    default:
      return "#141C36";
  }
};

const Orders = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const orders = useSelector(selectors.getPatientOrders);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    setIsFetching(true);
    dispatch(fetchPatientOrders()).finally(() => setIsFetching(false));
  }, [dispatch]);
  return (
    <Page.Container>
      <Page.Header title={translate("myOrders")} goBack />
      <Page.Content
        isFetching={isFetching}
        isEmpty={isEmpty(orders)}
        emptyMessage={translate("noPreviousOrders")}
      >
        <div className="orders-container">
          <div className="orders-header">
            <div>{translate("itemsRequested")}</div>
            <div>{translate("status")}</div>
          </div>
          {orders.map(order => (
            <Card
              key={order._id}
              onClick={() => {
                history.push(`orders/${order._id}`);
              }}
              style={{
                padding: 10,
                margin: "10px 0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flex: 1,
                cursor: "pointer"
              }}
            >
              <div className="order-info">
                <div className="supplies-ordered">
                  {order.requested_equipment.map(x => translate(x)).join(", ")}
                </div>
                <div className="order-date">
                  {formatDateTimeLocal(order.order_date)}
                </div>
                {/* <div className="ship-date"> */}
                {/*   Ship Date: {path(["shipping_detail", "ship_date"], order)} */}
                {/* </div> */}
              </div>
              <div
                className="order-status"
                style={{ color: statusColor(order.status) }}
              >
                {translate(toLower(order.status))}
              </div>
            </Card>
          ))}
        </div>
      </Page.Content>
    </Page.Container>
  );
};

export default Orders;
