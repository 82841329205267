import { prop } from "ramda";
import { createSelector } from "reselect";
import { Types } from "actions/twilio-chat";

const initialState = {
  error: undefined,
  channel_sid: "",
  isReady: false,
  isOpen: false,
  messages: [],
  participants: [],
  chatDetails: {},
  notify: false,
  // pendingChats: {},
  isConnecting: false,
  isConnected: false
};

const twilioChatReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "auth/logout":
      return {
        ...initialState
      };
    case Types.INITIALIZE_TWILIO_CHAT_CLIENT:
      return {
        ...initialState,
        isConnecting: true,
        isOpen: true,
        channel_sid: action.payload
      };
    case Types.TWILIO_CHAT_READY:
      return { ...state, isReady: true };
    case Types.TWILIO_CHAT_STARTED:
      return { ...state, isConnected: true, isConnecting: false, notify: true };
    case Types.CLOSE_TWILIO_CHAT:
      return { ...initialState };
    case Types.TWILIO_CHAT_MESSAGE_ADDED:
      return {
        ...state,
        notify: true,
        messages: [...state.messages, payload]
      };
    case Types.TWILIO_CHAT_RESTORE_MESSAGES:
      return {
        ...state,
        messages: [...state.messages, ...payload]
      };
    case Types.TOGGLE_CHAT_WINDOW:
      return {
        ...state,
        notify: action.payload === true ? state.notify : false,
        isOpen: action.payload
      };
    case Types.TWILIO_CHAT_ENDED:
      return { ...state, isConnected: false };
    case Types.TWILIO_CHAT_PARTICIPENT_JOINED:
      return {
        ...state,
        participants: [...state.participants, action.payload]
      };
    case Types.TWILIO_CHAT_PARTICIPENT_LEFT:
      return {
        ...state,
        participants: state.participants.filter(id => id !== action.payload)
      };
    default:
      return state;
  }
};

/* ========= SELECTORS ======== */
const getTwilioChat = prop("twilioChat");

export const selectors = {
  getTwilioChatActive: createSelector([getTwilioChat], prop("isReady")),
  getChatIsOpen: createSelector([getTwilioChat], prop("isOpen")),
  getChatIsConnected: createSelector([getTwilioChat], prop("isConnected")),
  getChatIsConnecting: createSelector([getTwilioChat], prop("isConnecting")),
  getChatMessages: createSelector([getTwilioChat], prop("messages")),
  getChatChannelSid: createSelector([getTwilioChat], prop("channel_sid")),
  getChatParticipants: createSelector([getTwilioChat], prop("participants")),
  getChatNotifyStatus: createSelector([getTwilioChat], prop("notify"))
};

export default twilioChatReducer;
