import React from "react";
import PropTypes from "prop-types";
import selectors from "selectors";
import { connect } from "react-redux";
import { prop } from "ramda";

const HasPermission = ({ appPermissions, permission, children }) => {
  if (prop(permission, appPermissions)) {
    return <>{children}</>;
  } else {
    return null;
  }
};

HasPermission.defaultProps = {
  appPermissions: {}
};

HasPermission.propTypes = {
  permission: PropTypes.oneOf([
    "orderSupplies",
    "documents",
    "myOrders",
    "signatures",
    "myProducts",
    "questionaires",
    "scheduleChat",
    "maskFitting",
    "chatNow",
    "maskFitting",
    "onboarding"
  ]).isRequired,
  appPermissions: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default connect(state => ({
  appPermissions: selectors.getAppPermissions(state)
}))(HasPermission);
