import { useState } from "react";
import PropTypes from "prop-types";
import { Spinner } from "components/ui";
import { fromTo } from "utils/misc";
import { pathOr } from "ramda";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Button,
  MenuItem,
  Select,
  InputLabel,
  TextField
} from "@mui/material";
import selectors from "selectors";
import { useSelector } from "react-redux";
import useTranslate from "hooks/use-translate-language";

const AvailableTimeSlots = ({
  handleTimeSlotSelect,
  availableSlotsResults,
  chat_type,
  date,
}) => {
  const speaksSpanish = useSelector(selectors.getSpeaksSpanish);
  const translate = useTranslate();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [topicCategory, set_TopicCategory] = useState("");
  const topic_categories = useSelector(selectors.getChatTopics);
  const [description, setDescription] = useState("");
  const [selectedSlotHasChatType, setSelectedSlotHasChatType] = useState(true);
  const [selectedSlotChatType, setSelectedSlotChatType] = useState();

  const slots = availableSlotsResults ? availableSlotsResults.slots : null;

  const checkIfSlotHasChatType = (slot_id) => {
    const hasChatType = slots.find(x => x.slot_id == slot_id).master_chat_type != 0;
    setSelectedSlotHasChatType(hasChatType);
    setSelectedSlotChatType();
  }


  if (availableSlotsResults)
    return (
      <div
        style={{
          marginTop: 25,
          width: "100%",
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          height: "auto",
        }}
      >
        {availableSlotsResults.slots ? (
          <div
            style={{
              padding: 20,
              display: "flex",
              flexDirection: "column",
              height: "auto",
              border: "1px solid #ccc",
            }}
          >

            <FormControl component="fieldset">
              <FormLabel component="legend">
                {translate("chooseATimeSlot")}
              </FormLabel>
              <RadioGroup
                aria-label="time-slot"
                name="time-slot"
                value={selectedTimeSlot}
                onChange={({ target }) => {
                  setSelectedTimeSlot(target.value);
                  checkIfSlotHasChatType(target.value);
                }}
              >
                {availableSlotsResults.slots.map((slot) => (
                  <FormControlLabel
                    value={slot.slot_id}
                    control={<Radio color="primary" />}
                    label={`${slot.master_chat_type != 0 ? slot.master_chat_type + ' - ' : ''} ${fromTo(
                      slot.start_time,
                      slot.end_time,
                      translate("to")
                    )} | ${pathOr("", ["dme_user", "name"], slot)}`}
                    key={slot.slot_id}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <FormControl
              style={{
                width: "25%",
                marginBottom: '20px',
                marginTop: '20px'
              }}
            >
              <InputLabel>{translate("chooseATopic")}</InputLabel>
              <Select
                value={topicCategory}
                onChange={({ target }) => set_TopicCategory(target.value)}
              >
                {topic_categories.map(
                  ({ topic_id, topic_name, spanish_topic_name }) => (
                    <MenuItem key={topic_id} value={topic_id}>
                      {!speaksSpanish
                        ? topic_name
                        : spanish_topic_name ?? topic_name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            {
              !selectedSlotHasChatType &&
              <FormControl style={{ width: "25%" }} >
                <InputLabel>{translate("chooseAChatType")}</InputLabel>
                <Select value={selectedSlotChatType} onChange={({ target }) => setSelectedSlotChatType(target.value)} >
                  <MenuItem key={1} value={1}>Text Chat</MenuItem>
                  <MenuItem key={2} value={2}>Video Chat</MenuItem>
                </Select>
              </FormControl>
            }
            <FormControl component="fieldset">
              <TextField
                label={translate("enterDescription")}
                value={description}
                onChange={(value) => setDescription(value.target.value)}
                margin="normal"
              />
            </FormControl>

            {isFetching ? (
              <Spinner />
            ) : (
              <Button
                disabled={!selectedTimeSlot || !topicCategory || (!selectedSlotHasChatType && !selectedSlotChatType)}
                color="primary"
                onClick={async () => {
                  try {
                    setIsFetching(true);
                    await handleTimeSlotSelect({
                      chat_type,
                      topic_category_id: topicCategory,
                      date,
                      slot_id: selectedTimeSlot,
                      description: description,
                      selected_chat_type: selectedSlotChatType
                    });
                  } finally {
                    setIsFetching(false);
                  }
                }}
              >
                {translate("submit")}
              </Button>
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              color: "red",
            }}
          >
            {translate("noFoundTimeSlots")}
          </div>
        )}
      </div>
    );
  else return null;
};

AvailableTimeSlots.propTypes = {
  availableSlotsResults: PropTypes.shape({
    message: PropTypes.string,
    slots: PropTypes.arrayOf(PropTypes.object),
  }),
  chat_type: PropTypes.number,
  topic_category_id: PropTypes.string,
  handleTimeSlotSelect: PropTypes.func,
  date: PropTypes.string,
};

export default AvailableTimeSlots;
