import { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Spinner } from "components/ui";
import { pathOr } from "ramda";
import PhoneNumberConsent from "./phone-number-consent-input";
import { formatPhone } from "utils/misc";
import { updateTextConsent } from "actions/user";
import "./text-conset-form.scss";
import useTranslate from "hooks/use-translate-language";

const TextConsentForm = ({ profile, token, speaksSpanish }) => {
  const translate = useTranslate();
  const [primary_phone_number_text_consent, setPrimaryPhoneConsent] =
    useState(false);
  const dispatch = useDispatch();
  const [isUpdatingAccount, setIsUpdatingAccount] = useState(false);
  const [secondary_phone_number_text_consent, setSecondaryNumberConsent] =
    useState(false);
  const phoneNumbers = pathOr(
    [],
    ["patient_information", "phone_numbers"],
    profile
  ).filter(({ phone_number }) => phone_number);
  const companyPhoneNumber = pathOr(
    "",
    ["patient_company_details", "phone"],
    profile
  );
  const companyName = pathOr("", ["patient_company_details", "name"], profile);
  const patient_name = `${pathOr(
    "",
    ["patient_information", "first_name"],
    profile
  )} ${pathOr("", ["patient_information", "last_name"], profile)}`;
  return (
    <div className="text-consent">
      <div className="brand">My Sleep Coach</div>
      <div className="form">
        <header>
          <h3 className="loginH3">
            {translate("welcome")} {patient_name}
            {/* <MdPersonOutline size={16} style={{ paddingLeft: 5 }} /> */}
          </h3>
        </header>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            try {
              setIsUpdatingAccount(true);
              await dispatch(
                updateTextConsent(
                  {
                    phone_number: phoneNumbers[0]?.phone_number,
                    mobile_number: phoneNumbers[1]?.phone_number,
                    primary_phone_number_text_consent,
                    secondary_phone_number_text_consent,
                  },
                  token,
                  speaksSpanish
                )
              );
              //   history.push("/home");
            } finally {
              setIsUpdatingAccount(false);
            }
          }}
        >
          <div
            style={{
              padding: "5px 10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                marginBottom: "1rem",
              }}
            >
              {translate("thanksForAllowing")} {companyName}{" "}
              {translate("verifyTextConsent")}
            </p>
            {phoneNumbers[0] && (
              <PhoneNumberConsent
                setConsentValue={setPrimaryPhoneConsent}
                value={primary_phone_number_text_consent}
                {...phoneNumbers[0]}
              />
            )}

            {phoneNumbers[1] && (
              <PhoneNumberConsent
                setConsentValue={setSecondaryNumberConsent}
                value={secondary_phone_number_text_consent}
                {...phoneNumbers[1]}
              />
            )}
          </div>
          {!isUpdatingAccount ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <button
                style={{ margin: 5 }}
                type="button"
                onClick={async () => {
                  try {
                    setIsUpdatingAccount(true);
                    await dispatch(
                      updateTextConsent(
                        {
                          phone_number: phoneNumbers[0]?.phone_number,
                          mobile_number: phoneNumbers[1]?.phone_number,
                          primary_phone_number_text_consent: true,
                          secondary_phone_number_text_consent: true,
                        },
                        token,
                        speaksSpanish
                      )
                    );
                  } finally {
                    setIsUpdatingAccount(false);
                  }
                }}
              >
                {translate("agreeToReceiveText")}
              </button>
              <button
                style={{ margin: 5 }}
                type="button"
                onClick={async () => {
                  try {
                    setIsUpdatingAccount(true);
                    await dispatch(
                      updateTextConsent(
                        {
                          phone_number: phoneNumbers[0]?.phone_number,
                          mobile_number: phoneNumbers[1]?.phone_number,
                          primary_phone_number_text_consent: false,
                          secondary_phone_number_text_consent: false,
                        },
                        token,
                        speaksSpanish
                      )
                    );
                  } finally {
                    setIsUpdatingAccount(false);
                  }
                }}
              >
                {translate("declineToReceiveText")}
              </button>
              <button type="submit" style={{ margin: 5 }}>
                {translate("save")}
              </button>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner />
            </div>
          )}
        </form>
        <p
          style={{
            fontSize: "1em",
            fontStyle: "italic",
            margin: 5,
          }}
        >
          {translate("numberOnFileIncorrect")} {formatPhone(companyPhoneNumber)}
        </p>
        <p
          style={{
            fontSize: "1em",
            fontStyle: "italic",
            color: "red",
            margin: 5,
          }}
        >
          *{translate("textConsentDisclaimer")}
        </p>
      </div>
    </div>
  );
};

TextConsentForm.propTypes = {
  profile: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  speaksSpanish: PropTypes.bool.isRequired,
};

export default TextConsentForm;
