import PropTypes from "prop-types";
import { connect } from "react-redux";
import selectors from "selectors";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextField, Button } from "@mui/material";
import { forgotPassword } from "actions/auth";
import { Spinner } from "components/ui";
import Logo from "assets/images/icon_trans.png";
import { translateUsingBrowser } from "utils/misc";

export const ForgotPassword = ({ forgotPassword, forgotPasswordError }) => {
  const history = useHistory();
  const getFieldError = (field, errors, touched) => {
    return touched[field] ? errors[field] : "";
  };

  return (
    <div className="login-screen">
      <div className="login-screen-header">
        <img src={Logo} className="login-logo" alt="logo" />
        <div>Forgot Password</div>
      </div>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required(translateUsingBrowser("emailIsRequired"))
            .email(translateUsingBrowser("enterValidEmail")),
        })}
        onSubmit={async ({ email }) => {
          await forgotPassword({ email });
        }}
      >
        {({
          isSubmitting,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          values,
        }) => (
          <form className="login-form" onSubmit={handleSubmit}>
            <TextField
              id="email"
              label={translateUsingBrowser("email")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              name="email"
              error={!!getFieldError("email", errors, touched)}
              helperText={getFieldError("email", errors, touched)}
              type="email"
              style={{ marginBottom: 20 }}
            />
            <div className="login-error">{forgotPasswordError}</div>
            {isSubmitting ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: 120,
                }}
              >
                <Spinner />
              </div>
            ) : (
              <div className="login-form-actions">
                <Button
                  variant="contained"
                  color="primary"
                  id="login-button"
                  type="submit"
                >
                  {translateUsingBrowser("sendResetPasswordEmail")}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  {translateUsingBrowser("backToLogin")}
                </Button>
              </div>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default connect(
  (state) => ({ forgotPasswordError: selectors.getForgotPasswordError(state) }),
  {
    forgotPassword,
  }
)(ForgotPassword);

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  forgotPasswordError: PropTypes.string,
};
