import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { assoc, propOr } from "ramda";
/* ========= Reducer ======== */
export const documents = createSlice({
  name: "documents",
  initialState: { categories: [], patient_documents: [] },
  reducers: {
    setDocumentCategories: (state, { payload }) =>
      assoc("categories", payload, state),
    setPatientDocuments: (state, { payload }) =>
      assoc("patient_documents", payload, state)
  }
});

/* ========= Actions ======== */
export const { setPatientDocuments, setDocumentCategories } = documents.actions;

/* ========= SELECTORS ======== */
const select_documents = state => state.documents;

export const selectors = {
  getPatientDocuments: createSelector(
    [select_documents],
    propOr([], "patient_documents")
  ),
  getDocumentCategories: createSelector(
    [select_documents],
    propOr([], "categories")
  )
};

export default documents.reducer;
