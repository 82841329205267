import PropTypes from "prop-types";
import { Button, IconButton } from "@mui/material";
import useTranslate from "hooks/use-translate-language";
import { Close } from "@mui/icons-material";
import browserHistory from "browser-history";

const ConfirmPageClose = (
  { closeModal },
  closeText = "confirmCancel",
  goBack = "/"
) => {
  const translate = useTranslate();
  return (
    <div style={{ display: "flex", flexDirection: "column", padding: 20 }}>
      <h3 style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
        {translate(closeText)}
        <IconButton
          onClick={closeModal}
          aria-controls="close-document"
          aria-label="cancel-order"
          aria-haspopup="true"
          size="small"
          type="button"
        >
          <Close />
        </IconButton>
      </h3>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          onClick={() => {
            closeModal();
          }}
        >
          No
        </Button>
        <Button
          onClick={() => {
            if (typeof goBack === "string") {
              browserHistory.push(goBack);
            } else {
              browserHistory.goBack();
            }
          }}
        >
          Yes
        </Button>
      </div>
    </div>
  );
};

ConfirmPageClose.propTypes = {
  closeText: PropTypes.string,
  closeModal: PropTypes.func.isRequired
};

export default ConfirmPageClose;
