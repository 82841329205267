export default {
    declineAppointmentConfirmation: {
      english: "Are you sure you want to reject this appointment?",
      spanish: "¿Está seguro de que desea rechazar la cita?",
    },
    declineAppointmentSummary: {
      english: "Decline Appointment",
      spanish: "Rechazar Cita"
    },
    declineAppointmentActionText: {
      english: "Decline",
      spanish: "Rechazar"
    }    
  };