import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Spinner } from "components/ui";
import {
  TextField
} from "@mui/material";
import { fetchChatTopics, fetchAvailableTimeslots } from "actions/chat";
import { useDispatch } from "react-redux";
import moment from "moment";
import AvailableTimeSlots from "./available-time-slots";
import useTranslate from "hooks/use-translate-language";

const TimeSlotSearchForm = ({ handleTimeSlotSelect, chat_type, current_chat_id }) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    dispatch(fetchChatTopics());
  }, [dispatch]);
  const [availableSlotsResults, setAvailableTimeSlotsResult] = useState(null);
  const [chatType] = useState(chat_type);
  const [currentChatId] = useState(current_chat_id);
  const [date, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
  useEffect(() => {
    setIsFetching(true);
    dispatch(fetchAvailableTimeslots({ date, chatType, currentChatId }))
      .then((result) => setAvailableTimeSlotsResult(result))
      .finally(() => {
        setIsFetching(false);
      });
  }, [date, chatType, dispatch]);
  return (
    <div>
      <TextField
        label={translate("selectADate")}
        value={date}
        margin="normal"
        style={{ width: "100%" }}
        type="date"
        InputProps={{
          inputProps: { min: moment().format("YYYY-MM-DD") },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={({ target }) => {
          setSelectedDate(target.value);
        }}
      />
      <div
        style={{
          marginTop: 20,
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isFetching ? (
          <Spinner />
        ) : (
          <AvailableTimeSlots
            availableSlotsResults={availableSlotsResults}
            handleTimeSlotSelect={handleTimeSlotSelect}
          />
        )}
      </div>
    </div>
  );
};

TimeSlotSearchForm.propTypes = {
  handleTimeSlotSelect: PropTypes.func.isRequired,
  chat_type: PropTypes.number,
};

TimeSlotSearchForm.defaultProps = {
  chat_type: 1,
};

export default TimeSlotSearchForm;
