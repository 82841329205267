import React, { useState } from "react";
import useTranslate from "hooks/use-translate-language";
import { useDispatch } from "react-redux";
import { Spinner } from "components/ui";
import { CheckboxInput } from "components/form";
import PhoneInput from 'react-phone-input-2'
import { Formik, Form } from "formik";
import 'react-phone-input-2/lib/style.css'
import { Button } from "@mui/material";
import { setPatientConsent } from "actions/dashboard";
import { setAskForConsentAsFalse } from "reducers/auth";

const AskForTextConsent = ({ phone_number, closeDialog }) => {
    const translate = useTranslate();
    const [phoneNumber, setPhoneNumber] = useState(phone_number);
    const [consentGranted, setConsentGranted] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const dispatch = useDispatch();


    const isValidPhone = (number, consent_granted) => {
        if(consent_granted){
            if(number.length == 10){
                setErrorMessage("");
                return true;
            }
            else{
                setErrorMessage("If you consent, phone number must be completed");
                return false;
            }
        }else{
            if(number.length == 10 || number.length == 0){
                setErrorMessage("");
                return true;
            }
            else{
                setErrorMessage("In you don´t consent, phone number must be completed or empty");
                return false;
            }
        }
    }

    const onSubmit = async (values) => {
        let phoneNumberValid = isValidPhone(values.phone_number, values.consent_granted);
        if (phoneNumberValid) {
            const data = { PhoneNumber: values.phone_number, ConsentGranted: values.consent_granted };
            const response = await dispatch(setPatientConsent(data));
            
            if(response && response.success){
                closeDialog();
            }
        }
    }

    const onCancel = () => {
        closeDialog();
        dispatch(setAskForConsentAsFalse(false))
    }

    return <div >
        <span className="text-consent" style={{ color: '#24abfb' }}> <u> {translate("textConsent")} </u></span>
        <br />
        <Formik
            initialValues={{ phone_number: phoneNumber, consent_granted: consentGranted }}
            onSubmit={async values => await onSubmit(values)}
            enableReinitialize={true}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form className="text-consent-form-container">
                    <span style={{alignSelf: "start"}}> {translate("phoneNumberLabel")} </span>
                    <PhoneInput
                        placeholder="Enter phone number"
                        country={'us'}
                        value={phoneNumber}
                        onChange={phone => {
                            setPhoneNumber(phone)
                            isValidPhone(phone, values.consent_granted)
                        }}
                        name="phone_number"
                        disableDropdown={true}
                        disableCountryCode={true}
                        buttonStyle={{display: "none"}}
                        inputStyle={{textAlign: "center", padding: "0px"}}
                    />
                    <br />
                    <div>
                        <CheckboxInput
                            name="consent_granted"
                            label={translate("textConsentMessage")}
                            value={consentGranted}
                            onChange={() => {
                                isValidPhone(values.phone_number, !consentGranted)
                                setFieldValue("consent_granted", !consentGranted)
                                setConsentGranted(!consentGranted) 
                            }}
                        />
                    </div>
                    <br />
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <p style={{ color: '#ff0000', textAlign: "center" }}>
                            {errorMessage}
                        </p>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={{ display: 'inline-block', marginRight: '50px' }}
                            disabled={isSubmitting}
                        >
                            Confirm {isSubmitting && <Spinner size="20px" sx={{ color: '#ffffff' }} />}
                        </Button>
                        <Button
                            style={{ display: 'inline-block' }}
                            variant="contained"
                            color="primary"
                            onClick={() => onCancel()}
                            disabled={isSubmitting}
                        >
                            Cancel
                        </Button>
                    </div>
                    <br />
                </Form>
            )}
        </Formik>
    </div>
}

export default AskForTextConsent;
