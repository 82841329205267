export default {
  // Index
  scheduleAMeeting: {
    english: "Schedule A Meeting",
    spanish: "Programar una reunión",
  },
  // Available-Time-Slots
  chooseATopic: {
    english: "Choose A Topic",
    spanish: "Escoge un tema",
  },
  chooseATimeSlot: {
    english: "Choose a time slot",
    spanish: "Escoge un tema",
  },
  noFoundTimeSlots: {
    english: "Sorry, we could not find any Time Slots.",
    spanish: "Lo sentimos, no pudimos encontrar ninguna franja horaria.",
  },
  // submit is from profile

  // Time-Slot-Search-Form
  selectADate: {
    english: "Select A Date",
    spanish: "Seleccione una fecha",
  },
  enterDescription: {
    english: "Enter description",
    spanish: "Ingrese una descripción"
  },
  chooseAChatType: {
    english: "Enter the Chat type",
    spanish: "Ingrese el tipo de chat"
  }
};
