import { setPatientOrders } from "reducers/orders";
import { API } from "actions/api";
import browserHistory from "browser-history";
import { NotificationManager } from "react-notifications";
import * as R from "ramda";
import { orderEquipmentQuestions } from "components/order-form/options";

// accept optional headers for email order token authorization
export const fetchPatientOrders = headers => async dispatch => {
  try {
    const { orders } = await dispatch({
      type: API,
      route: "patient_portal/mobile/orders",
      method: "GET",
      publicRoute: headers ? true : false,
      headers
    });
    dispatch(setPatientOrders(orders));
    return orders;
  } catch (error) {
    console.error(error.message);
  }
};
const transformEquipmentName = equipment => {
  switch (equipment) {
    case "nondisposable filter":
    case "disposable filter":
      return "filter";
    default:
      return equipment;
  }
};

// accept optional headers for email order token authorization
export const submitOrder = (order, headers) => async dispatch => {
  console.log(order.insurances);
  const requiresDysfunctions = order.insurances.some(
    x => x?.requires_dysfunctions ?? true
  );

  const strippedKeys = requiresDysfunctions
    ? R.pipe(
        R.filter(
          ({ equipment }) => !order.requested_equipment.includes(equipment)
        ),
        R.map(({ key }) => key)
      )(orderEquipmentQuestions)
    : orderEquipmentQuestions.map(x => x.key);

  const payload = R.omit(strippedKeys, order);
  payload.requires_dysfunctions = requiresDysfunctions;
  try {
    const requiresDysfunctions = order.insurances.some(
      x => x?.requires_dysfunctions ?? true
    );
    const strippedKeys = requiresDysfunctions
      ? R.pipe(
          R.filter(
            ({ equipment }) =>
              !order.requested_equipment
                .map(transformEquipmentName)
                .includes(equipment)
          ),
          R.map(({ key }) => key)
        )(orderEquipmentQuestions)
      : orderEquipmentQuestions.map(x => x.key);

    const payload = R.omit(strippedKeys, order);
    payload.requires_dysfunctions = requiresDysfunctions;
    try {
      const { success, message } = await dispatch({
        type: API,
        route: "patient_portal/mobile/create-order",
        method: "POST",
        headers,
        publicRoute: headers ? true : false,
        body: {
          ...payload,
          requested_equipment: R.uniq(order.requested_equipment)
        }
      });
      if (success) {
        if (!headers) {
          NotificationManager.success(message, "Order Submitted");
          browserHistory.push("/");
        } else {
          //email order
          browserHistory.push({
            pathname: "/thanks",
            state: {
              message,
              shouldPostMessage: order.order_create_method === "External Portal"
            }
          });
          NotificationManager.success(message, "Order Submitted");
        }
      } else {
        browserHistory.push({
          pathname: "/thanks",
          state: {
            message,
            shouldPostMessage: order.order_create_method === "External Portal"
          }
        });
        NotificationManager.success(message, "Order Submitted");
      }
    } catch (error) {
      console.error(error.message);
      NotificationManager.error(error.message, "Order Error");
    }
  } catch (er) {
    console.error(er);
    NotificationManager.error(er.message, "Order Error");
  }
};

export const fetchPatientOrderProfile =
  (orderId, headers) => async dispatch => {
    try {
      const { orderProfile } = await dispatch({
        type: API,
        route: `patient_portal/mobile/orders/${orderId}`,
        headers,
        publicRoute: headers ? true : false,
        method: "GET"
      });
      return orderProfile;
    } catch (error) {
      console.error(error.message);
    }
  };
