import { Card } from "components/ui";
import { useSelector } from "react-redux";
import selectors from "selectors";

const UpcomingChatError = () => {
    const speaksSpanish = useSelector(selectors.getSpeaksSpanish);

    return (
        <Card style={{ margin: 8 }}>
        <div className="upcoming-chat-date-container" style={{ background: "rgb(204, 2, 2)" }}>
        </div>
            <div className="upcoming-chat-container">
                <div className="chat-header" style={{marginTop: "13px"}}>
                    <span>
                        ERROR
                    </span>
                </div>

            </div>
            <div className="upcoming-chat-content">
                {speaksSpanish ?
                    "Se han detectado errores con una cita. Por favor contacte a soporte." :
                    "We found issues with an appointment. Please contact support."
                }
            </div>  
             </Card>
    )
}

export default UpcomingChatError