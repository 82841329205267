import { createSlice } from "@reduxjs/toolkit";
import { pathOr, assoc } from "ramda";

/* ========= Reducer ======== */
export const notifications = createSlice({
  name: "notifications",
  initialState: { notifications: [], settings: {}, newNotificationsAmount: 0, notificationScreenVisited: false, lastLogin: null },
  reducers: {
    setPatientNotifications: (state, { payload }) =>
      assoc("notifications", payload, state),
    setPatientNotificationSettings: (state, { payload }) =>
      assoc("settings", payload, state),
    setNewNotificationsAmount: (state, { payload }) =>
      assoc("newNotificationsAmount", payload, state),
    setNotificationScreenVisited: (state, { payload }) =>
      assoc("notificationScreenVisited", payload, state),
    setPatientLastLogin: (state, { payload }) =>
      assoc("last_login", payload, state),
    setPatientLastBrowse: (state, { payload }) =>
    assoc("last_browse", payload, state),
  }
});

/* ========= Actions ======== */
export const {
  setPatientNotifications,
  setPatientNotificationSettings,
  setNewNotificationsAmount,
  setNotificationScreenVisited,
  setPatientLastLogin,
  setPatientLastBrowse
} = notifications.actions;

/* ========= SELECTORS ======== */
export const selectors = {
  getPatientNotifications: pathOr([], ["notifications", "notifications"]),
  getPatientNotificationSettings: pathOr({}, ["notifications", "settings"]),
  getNewNotificationsAmount: pathOr(0, ["notifications", "newNotificationsAmount"]),
  getNotificationScreenVisited: pathOr(false, ["notifications", "notificationScreenVisited"]),
  getNotificationsPatientLastLogin: pathOr(null, ["notifications", "last_login"]),
  getNotificationsLastBrowse: pathOr(null, ["notifications", "last_browse"]),

};

export default notifications.reducer;
