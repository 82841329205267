import React from "react";
import Modal from "components/ui/modal";
import AskForTextConsent from "./ask-for-text-consent";

const AskForTextConsentModal = ({phone_number}) => {
    
    return <div>
        <Modal
            maxWidth="xs"
            fullWidth
            renderTrigger={() => null}
            startOpen={true}
            renderContent={({ closeModal }) => (<AskForTextConsent phone_number={phone_number} closeDialog={closeModal}/>)}
          />      
    </div>
}

export default AskForTextConsentModal;
