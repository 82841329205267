import { createSlice } from "@reduxjs/toolkit";
import { propOr } from "ramda";

/* ========= Reducer ======== */
export const signatures = createSlice({
  name: "signatures",
  initialState: [],
  reducers: {
    setPatientSignatures: (state, { payload }) => payload
  }
});

/* ========= Actions ======== */
export const { setPatientSignatures } = signatures.actions;

/* ========= SELECTORS ======== */
export const selectors = {
  getPatientSignatures: propOr([], "signatures")
};

export default signatures.reducer;
