import { API } from "actions/api";
import { setPatientQuestionnaires } from "reducers/questionnaires";
import { NotificationManager } from "react-notifications";
import browserHistory from "browser-history";

export const fetchPatientQuestionnaires = () => async dispatch => {
  try {
    const result = await dispatch({
      type: API,
      route: "patient_portal/mobile/questionnaires",
      method: "GET"
    });
    dispatch(setPatientQuestionnaires(result));
    return result;
  } catch (error) {
    console.error(error.message);
  }
};

export const fetchQuestionnaireDetails = (assignment_id, token = '') => async dispatch => {
  try {
    let questionnaire_api = {
      type: API,
      route: `patient_portal/mobile/questionnaires/${assignment_id}`,
      method: "GET"
    }
    if (token != '') {
      questionnaire_api.headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };
    }
    console.log(questionnaire_api);
    const result = await dispatch(questionnaire_api);
    return result;
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const ignoreQuestionnaire = assignment_id => async dispatch => {
  try {
    const result = await dispatch({
      type: API,
      route: `patient_portal/mobile/questionnaires/assignment/${assignment_id}/ignore`,
      method: "PUT",
      body: {}
    });
    dispatch(fetchPatientQuestionnaires());
    return result;
  } catch (error) {
    console.error(error.message);
  }
};

export const submitQuestionnaire = (body, token = '') => async dispatch => {
  try {
    let questionnaire_api = {
      type: API,
      route: "patient_portal/mobile/questionnaires",
      method: "POST",
      body
    };
    if (token != '') {
      questionnaire_api.headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };
    }
    const { message } = await dispatch(questionnaire_api);
    NotificationManager.success(message);
    browserHistory.push({
      pathname: "/thanks",
      state: { message, backTo: "/questionnaires" }
    });
  } catch (error) {
    console.error(error.message);
    NotificationManager.error(error.message);
  }
};
