export default {
  haveAQuestion: { english: "Have a question?", spanish: "Tengo una pregunta" },
  chatNow: { english: "Chat Now", spanish: "Chatea ahora" },
  requestTextChat: {
    english: "Request text chat with a representative",
    spanish: "Solicite un chat de texto con un representante",
  },
  chatNowNotAvailable: {
    english: "Chat now is not currently available",
    spanish: "El chat no está disponible actualmente",
  },
  pleaseScheduleChat: {
    english: "Please schedule a chat or try again between",
    spanish: "Programa un chat o vuelve a intentarlo entre",
  },
  mondayThruFriday: {
    english: "8:00AM and 5:00PM Monday-Friday",
    spanish: "8:00 a.m. y 5:00 p.m. De lunes a viernes",
  },
};
