import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "@mui/material";
import { find } from "ramda";
import useTranslate from "hooks/use-translate-language";

const Equipment = ({ requested_equipment, supply_period }) => {
  const translate = useTranslate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 15,
        flex: 1,
        justifyContent: "center",
      }}
    >
      <div className="order-form-prompt">
        {translate("youWillReceive")}{" "}
        <span className="day-supply" style={{ fontWeight: "bold" }}>
          {supply_period}
        </span>{" "}
        {translate("daySupplyOfItems")}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          background: "white",
          padding: 3,
          marginTop: 10,
          marginBottom: 20,
        }}
      >
        {requested_equipment.map((equipment) => (
          <div style={{ display: "flex", padding: 5 }} key={equipment}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox checked={true} readOnly color="primary" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 36,
              }}
            >
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  letterSpacing: "0.00938em",
                }}
              >
                {
                  find(
                    ({ value }) => value === equipment,
                    translate("supplyOptions")
                  )?.text
                }
              </div>
              <div
                style={{
                  color: "#787878",
                  fontFamily: "Poppins-Regular",
                  marginBottom: 5,
                }}
              >
                {
                  find(
                    ({ value }) => value === equipment,
                    translate("supplyOptions")
                  )?.description
                }
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="order-form-warning">{translate("orderFormWarning")}</div>
    </div>
  );
};

Equipment.propTypes = {
  requested_equipment: PropTypes.arrayOf(PropTypes.string).isRequired,
  supply_period: PropTypes.number,
};

export default React.memo(Equipment);
