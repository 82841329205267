export const Types = {
  INITIALIZE_TWILIO_CHAT_CLIENT: "INITIALIZE_TWILIO_CHAT_CLIENT",
  TWILIO_CHAT_READY: "TWILIO_CHAT_READY",
  START_TWILIO_CHAT: "START_TWILIO_CHAT",
  CLOSE_TWILIO_CHAT: "CLOSE_TWILIO_CHAT",
  END_TWILIO_CHAT: "END_TWILIO_CHAT",
  TWILIO_CHAT_STARTED: "TWILIO_CHAT_STARTED",
  TWILIO_CHAT_ENDED: "TWILIO_CHAT_ENDED",
  TWILIO_SEND_MESSAGE: "TWILIO_SEND_MESSAGE",
  TWILIO_CHAT_SET_CHANNEL: "TWILIO_CHAT_SET_CHANNEL",
  TWILIO_CHAT_REMOVE_CHANNEL: "TWILIO_CHAT_REMOVE_CHANNEL",
  TWILIO_CHAT_MESSAGE_ADDED: "TWILIO_CHAT_MESSAGE_ADDED",
  TWILIO_CHAT_RESTORE_MESSAGES: "TWILIO_CHAT_RESTORE_MESSAGES",
  TWILIO_CHAT_ERROR: "TWILIO_CHAT_ERROR",
  TWILIO_CHAT_PARTICIPENT_JOINED: "TWILIO_CHAT_PARTICIPENT_JOINED",
  TWILIO_CHAT_PARTICIPENT_LEFT: "TWILIO_CHAT_PARTICIPENT_LEFT",
  PENDING_CHAT_RECIEVED: "PENDING_CHAT_RECIEVED",
  PENDING_CHATS_RECIEVED: "PENDING_CHATS_RECIEVED",
  TOGGLE_CHAT_WINDOW: "TOGGLE_CHAT_WINDOW"
};
export const startTwilioChat = channel_sid => ({
  type: Types.INITIALIZE_TWILIO_CHAT_CLIENT,
  payload: channel_sid
});

export const leaveTwilioChat = (keepOpen = false) => ({
  type: Types.END_TWILIO_CHAT,
  payload: keepOpen
});

export const sendTwilioChatMsg = message => ({
  type: Types.TWILIO_SEND_MESSAGE,
  payload: message
});

export const toggleChatWindow = isOpen => ({
  type: Types.TOGGLE_CHAT_WINDOW,
  payload: isOpen
});

export const closeTwilioChat = () => ({
  type: Types.CLOSE_TWILIO_CHAT
});
