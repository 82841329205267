import { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import selectors from "selectors";
import { Button } from "@mui/material";
import { fetchPatientProfile } from "actions/user";
import { Page } from "components/ui";
import TextConsentForm from "./text-conset-form";
import useTranslate from "hooks/use-translate-language";
import queryString from "query-string";
import { decodeJwt } from "utils/misc";
import { setLanguage } from "reducers/auth";
import { checkToken } from "actions/auth";

const TextConsent = () => {
  const translate = useTranslate();

  const location = useLocation();
  const tokenString = queryString.parse(location.search).token;
  const decoded = decodeJwt(tokenString);
  const speaksSpanish = decoded?.speaks_spanish;
  // Check for legacy token for backwards compatibility
  const { token } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();
  const [isFetching, setIsFetchingProfile] = useState(false);
  const profile = useSelector(selectors.getPatientProfile);
  useEffect(() => {
    setIsFetchingProfile(true);
    dispatch(
      fetchPatientProfile({ Authorization: `Bearer ${tokenString}` })
    ).finally(() => {
      setIsFetchingProfile(false);
    });
  }, [tokenString, dispatch]);
  useEffect(() => {
    dispatch(setLanguage(speaksSpanish));
  }, []);
  const [validToken, setIsValidToken] = useState(null);
  useEffect(() => {
    dispatch(checkToken(tokenString || token)).then((result) => {
      setIsValidToken(result);
    });
  }, [tokenString, dispatch]);

  return (
    <Page.Container style={{ height: "100vh", marginTop: 0, paddingTop: 0 }}>
      <Page.Content isFetching={isFetching}>
        {validToken !== true ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2 style={{ color: "black" }}>{translate("sorry")}</h2>
            <h5 style={{ color: "black" }}>
              {translate("errorAuthenticatingAccount")}
            </h5>
            <h5 style={{ color: "black", marginBottom: 30 }}>
              {translate("linkNoLongerValid")}
            </h5>
            <Button
              onClick={() => {
                history.push("/login");
              }}
              variant="contained"
            >
              {translate("loginToUpdateAccount")}
            </Button>
          </div>
        ) : (
          <TextConsentForm
            profile={profile}
            token={tokenString || token}
            speaksSpanish={speaksSpanish}
          />
        )}
      </Page.Content>
    </Page.Container>
  );
};

export default TextConsent;
