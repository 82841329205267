import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Page } from "components/ui";
import { isEmpty } from "ramda";
import "./tracking.scss";
import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled
} from "@mui/material";
import { API } from "actions/api";
import { formatDate } from "utils/misc";
import { useHistory } from "react-router";
import useTranslate from "hooks/use-translate-language";

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F3F3F3"
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

const Tracking = () => {
  const [trackingInfo, setTrackingInfo] = useState([]);
  const translate = useTranslate();
  const [isFetching, setIsFetching] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const fetchTrackingInfo = async () => {
    try {
      const { trackingInfoList } = await dispatch({
        type: API,
        route: "patient_portal/mobile/tracking",
        method: "GET"
      });
      await setTrackingInfo(trackingInfoList);
      setIsFetching(false);
    } catch (error) {
      console.error(error.message);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchTrackingInfo();
  }, []);

  return (
    <Page.Container>
      <Page.Header title={translate("tracking")} goBack />
      <Page.Content
        isFetching={isFetching}
        isEmpty={isEmpty(trackingInfo)}
        emptyMessage={translate("noTrackingNumbers")}
      >
        <div className="tracking-container">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead
                sx={{
                  backgroundColor: "#D1D1D1"
                }}
              >
                <TableRow>
                  <TableCell>{translate("trackingNumber")}</TableCell>
                  <TableCell align="left">
                    {translate("invoiceNumber")}
                  </TableCell>
                  <TableCell align="left">
                    {translate("shippingDate")}
                  </TableCell>
                  <TableCell align="left">{translate("carrier")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trackingInfo.map(row => (
                  <StyledTableRow key={row.tracking_number}>
                    <TableCell component="th" scope="row">
                      {row.tracking_number}
                    </TableCell>
                    <TableCell align="left">
                      <span
                        className={`${row.invoice_number ? "link" : ""}`}
                        onClick={() => {
                          history.push(`/orders/${row.order_id}`);
                        }}
                      >
                        {row.invoice_number || "-"}
                      </span>
                    </TableCell>
                    <TableCell align="left">
                      {formatDate(row.shipping_date)}
                    </TableCell>
                    <TableCell align="left">{row.carrier}</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Page.Content>
    </Page.Container>
  );
};

export default Tracking;
