import { useContext, createContext, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import selectors from "selectors";
import Translations from "translations";
// import * as Sentry from "@sentry/react";
export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const language = useSelector(selectors.getSpeaksSpanish)
    ? "spanish"
    : "english";
  const translate = useCallback(
    key => {
      try {
        return Translations[key][language];
      } catch (e) {
        console.log(`Could not find translation for ${key}`);
        // Sentry.captureException(`Could not find translation for ${key}`);
        return key;
      }
    },
    [language]
  );
  return (
    <LanguageContext.Provider value={{ translate: translate }}>
      {children}
    </LanguageContext.Provider>
  );
};

LanguageProvider.propTypes = { children: PropTypes.node };

const useTranslate = () => {
  const { translate } = useContext(LanguageContext);
  return translate;
};

export default useTranslate;
