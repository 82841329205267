import { useState, useEffect } from "react";
import { Page, Spinner } from "components/ui";
import { Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import selectors from "selectors";
import { startChatNow } from "actions/chat";
import { startTwilioChat } from "actions/twilio-chat";
import moment from "moment";
import useTranslate from "hooks/use-translate-language";

const isBetweenChatNowHours = function () {
  const current = moment.tz("US/Central");
  if (current.day() === 6 || current.day() === 0) {
    return false;
  }
  const format = "hh:mm:ss";
  const time = moment(moment.tz("US/Central"), format),
    beforeTime = moment("08:00:00", format),
    afterTime = moment("17:00:00", format);
  if (time.isBetween(beforeTime, afterTime)) {
    return true;
  } else {
    return false;
  }
};

const ChatNow = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const isConnecting = useSelector(selectors.getChatIsConnecting);
  const chatActive = useSelector(selectors.getTwilioChatActive);
  const connected = useSelector(selectors.getChatIsConnected);
  const [canUseChatNow, setCanUseChatNow] = useState(isBetweenChatNowHours());
  useEffect(() => {
    const interval = setInterval(() => {
      setCanUseChatNow(isBetweenChatNowHours);
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  async function launchChatNow() {
    try {
      setIsFetching(true);
      const { channel_sid } = await dispatch(startChatNow());
      await dispatch(startTwilioChat(channel_sid));
    } finally {
      setIsFetching(false);
    }
  }
  return (
    <Page.Container>
      <Page.Header title={translate("chatNow")} goBack />
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {canUseChatNow ? (
          <>
            <h2 style={{ marginBottom: 20 }}>{translate("haveAQuestion")}</h2>
            <h3 style={{ marginBottom: 20 }}>{translate("requestTextChat")}</h3>
            {isFetching || isConnecting ? (
              <Spinner />
            ) : (
              <Button
                disabled={chatActive && connected}
                onClick={() => {
                  launchChatNow();
                }}
                variant="contained"
                color="primary"
              >
                {translate("chatNow")}
              </Button>
            )}
          </>
        ) : (
          <>
            <h2 style={{ fontSize: 18, padding: 10 }}>
              {translate("chatNowNotAvailable")}
            </h2>
            <h3
              style={{
                fontSize: 14,
                color: "#333",
              }}
            >
              {translate("pleaseScheduleChat")}
            </h3>
            <h5 style={{ fontSize: 14, color: "#333" }}>
              {translate("mondayThruFriday")} (CST)
            </h5>
          </>
        )}
      </div>
    </Page.Container>
  );
};

export default ChatNow;
