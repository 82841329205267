import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import selectors from "selectors";
import Message from "./message";

const MessageList = ({ messages }) => {
  const messageListRef = React.useRef();
  const userId = useSelector(selectors.getUserId);

  React.useEffect(() => {
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
  }, [messages]);

  return (
    <div className="messages-container" ref={messageListRef}>
      {messages.map((msg, i) => (
        <Message key={i} {...msg} userId={userId} />
      ))}
    </div>
  );
};

MessageList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default MessageList;
