import { API } from "actions/api";

export const fetchOnboarding = () => async dispatch => {
  try {
    const tiles = await dispatch({
      type: API,
      route: "patient_portal/mobile/onboarding",
      method: "GET"
    });
    return tiles;
  } catch (error) {
    console.error(error.message);
  }
};
