import {
  setChatHistory,
  setUpcomingChats,
  setChatTopics,
  setChatNowDetails
} from "reducers/chat";
import { fetchPatientNotifications } from "actions/notifications";
import { API } from "actions/api";
import browserHistory from "browser-history";
import { NotificationManager } from "react-notifications";

export const fetchChatHistory = () => async dispatch => {
  try {
    const { chats } = await dispatch({
      type: API,
      route: "patient_portal/mobile/history_chats",
      method: "GET"
    });
    dispatch(setChatHistory(chats));
    return { chats };
  } catch (error) {
    console.error(error.message);
  }
};

export const scheduleChat = ({
  chat_type,
  topic_category_id,
  product_id,
  date,
  slot_id,
  description,
  selected_chat_type
}) => async dispatch => {
  try {
    const { success, message } = await dispatch({
      type: API,
      route: "patient_portal/mobile/schedule_chat",
      method: "POST",
      body: { chat_type, topic_category_id, product_id, date, slot_id, description, selected_chat_type }
    });
    if (success) {
      NotificationManager.success(message);
      browserHistory.push({
        pathname: "/upcoming-chats"
      });
    } else NotificationManager.error(message);
  } catch (error) {
    NotificationManager.error(error.message);
  }
};

export const rescheduleChat = body => async dispatch => {
  try {
    const { success, message } = await dispatch({
      type: API,
      route: "patient_portal/mobile/chat_reschedule",
      method: "PUT",
      body
    });
    if (success) {
      NotificationManager.success(message);
      dispatch(fetchUpcomingChats());
    } else NotificationManager.error(message);
  } catch (error) {
    NotificationManager.error(error.message);
  }
};

export const fetchAvailableTimeslots = ({
  date,
  chatType,
  currentChatId
}) => async dispatch => {
  try {
    const result = await dispatch({
      type: API,
      route: "patient_portal/mobile/available_slots",
      method: "PUT",
      body: { date, chat_type: chatType, current_chat_id: currentChatId }
    });
    return result;
  } catch (error) {
    console.error(error.message);
  }
};

export const fetchUpcomingChats = () => async dispatch => {
  try {
    const { chats = [] } = await dispatch({
      type: API,
      route: "patient_portal/mobile/upcoming_chats",
      method: "GET"
    });
    dispatch(setUpcomingChats(chats));
  } catch (error) {
    console.error(error.message);
  }
};

export const fetchChatDetails = chatId => async dispatch => {
  try {
    const result = await dispatch({
      type: API,
      route: `patient_portal/mobile/chats/${chatId}`,
      method: "GET"
    });
    console.log(result);
  } catch (error) {
    console.error(error.message);
  }
};

export const fetchChatDetailsWithoutLoging = (chatId, headers) => async dispatch => {
  try {
    const result = await dispatch({
      type: API,
      route: `patient_portal/mobile/chats/${chatId}`,
      method: "GET",
      headers
    });
    return result;
  } catch (error) {
    console.error(error.message);
  }
};

export const startChat = (
  slot_id,
  master_chat_type,
  // is_product_demo
) => async dispatch => {
  try {
    const { success, chats_to_connect_to } = await dispatch({
      type: API,
      route: "patient_portal/mobile/start_chat",
      method: "POST",
      body: { slot_id }
    });
    if (success) {
      switch (master_chat_type) {
        case "Text Chat":
          browserHistory.push({
            pathname: "/text-chat",
            state: { ...chats_to_connect_to }
          });
          break;
        case "Video Chat":
          browserHistory.push({
            pathname: "/video-chat",
            state: { ...chats_to_connect_to }
          });
          break;
        case "Product Demo":
          browserHistory.push({
            pathname: "/product-demo",
            state: { ...chats_to_connect_to }
          });
          break;

        default:
          break;
      }
    }
    return chats_to_connect_to;
  } catch (error) {
    NotificationManager.error(error.message, "Could not join chat");
    console.error(error.message);
  }
};

export const fetchChatResponse = body => async dispatch => {
  try {
    const { success, message } = await dispatch({
      type: API,
      route: "patient_portal/mobile/chat_response",
      method: "POST",
      body
    });
    if (success) NotificationManager.success(message);
    else NotificationManager.error(message);
  } catch (error) {
    NotificationManager.error(error.message);
  }
};

export const startChatNow = () => async dispatch => {
  try {
    const result = await dispatch({
      type: API,
      route: "patient_portal/mobile/chat_now",
      method: "POST",
      body: {}
    });
    dispatch(setChatNowDetails(result));
    return result;
  } catch (error) {
    console.error(error.message);
  }
};

export const fetchChatTopics = () => async dispatch => {
  try {
    const { topic_categories } = await dispatch({
      type: API,
      route: "patient_portal/mobile/appointment_topic_categories",
      method: "GET"
    });
    dispatch(setChatTopics(topic_categories));
    return topic_categories;
  } catch (error) {
    console.error(error.message);
  }
};

export const respondToChatNotification = (
  chat_id,
  status_response,
  headers
) => async dispatch => {
    try {
        const result = await dispatch({
            type: API,
            route: "patient_portal/mobile/chat_response",
            method: "POST",
            headers: headers,
            body: { chat_id, status_response }
        });

        if(result && result.success){
            NotificationManager.success(`${result.message ?? "Appointment answered successfuly"}`);

            if(!headers){
              await dispatch(fetchPatientNotifications());
              await dispatch(fetchUpcomingChats());
            }          
        } else {
            NotificationManager.error(result?.message, "Could not accept or decline the appointment");
        }
        return result
    }
    catch(error) {
        console.error(error.message);
        NotificationManager.error(
            error.message,
            "Could not accept or decline the appointment"
        );
    }
};

export const cancelAppointment = slot_id => async dispatch => {
  try {
    const response = await dispatch({
      type: API,
      route: `patient_portal/mobile/upcoming_chats/${slot_id}/cancel`,
      method: "PUT"
    });

    if(response && response.success){
      NotificationManager.success("Appointment cancelled successfuly");
      dispatch(fetchUpcomingChats());
    }
    else
      NotificationManager.error(response?.message, "Could not cancel the appointment");  
    
    return response;
  }
  catch (error) {
    console.error(error.message);
    NotificationManager.error(
      error.message,
      "Could not cancel the appointment"
    );
  }
}
