import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AppLayoutContext } from "components/app-layout";
import { IconButton, Toolbar, AppBar, Menu, MenuItem } from "@mui/material";
import { Menu as MenuIcon, ArrowBack, Close } from "@mui/icons-material";
import browserHistory from "browser-history";
import Spinner from "../spinner";
import EmptyView from "../empty-view";
import "./page.scss";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useSelector, useDispatch } from "react-redux";
import selectors from "selectors";
import { setNewNotificationsAmount } from "reducers/notifications";
import { useHistory } from "react-router-dom";
import useTranslate from "hooks/use-translate-language";
import NotificationSettingsModal from "components/profile/notification-settings-modal";
import ChangePasswordModal from "components/profile/change-password-modal";
import { logoutUser } from "actions/auth";
import { fetchNotificationCountBeforeDate } from "actions/notifications";
import { Modal } from "components/ui";
import ConfirmPageClose from "./confirm-page-close";

export const Header = ({
  title,
  goBack,
  children,
  action,
  closeText = null
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const translate = useTranslate();
  const { handleDrawerToggle } = useContext(AppLayoutContext);
  const user_details = useSelector(selectors.getUserDetails);
  const newNotificationsAmount = useSelector(
    selectors.getNewNotificationsAmount
  );
  const notificationsLastBrowse = useSelector(
    selectors.getNotificationsLastBrowse
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openMenuFromName = () => {
    document.getElementById("menuButton").click();
  };

  const setNotificationsAmount = async () => {
    const result = await dispatch(
      fetchNotificationCountBeforeDate(notificationsLastBrowse)
    );
    dispatch(setNewNotificationsAmount(result));
  };

  useEffect(() => {
    setNotificationsAmount();
  }, [notificationsLastBrowse]);

  const HeaderIcon = () => {
    if (goBack && closeText) {
      return (
        <Modal
          renderTrigger={({ openModal }) => (
            <IconButton
              onClick={openModal}
              aria-controls="cancel-order"
              aria-label="cancel-order"
              aria-haspopup="true"
              // color="primary"
              size="small"
            >
              <Close />
            </IconButton>
          )}
          renderContent={closeModal =>
            ConfirmPageClose(closeModal, closeText, goBack)
          }
        />
      );
    } else if (goBack) {
      return (
        <IconButton
          onClick={() => {
            if (typeof goBack === "string") {
              browserHistory.push(goBack);
            } else {
              browserHistory.goBack();
            }
          }}
          color="inherit"
          aria-label="open drawer"
          edge="start"
        >
          <ArrowBack />
        </IconButton>
      );
    } else
      return (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className="menuButton"
        >
          <MenuIcon />
        </IconButton>
      );
  };

  return (
    <AppBar className="appBar">
      <Toolbar>
        {HeaderIcon()}
        <div className="app-bar-title-container">
          <div>
            <div className="app-bar-title">{children}</div>
            <div className="app-bar-title">{title}</div>
          </div>
          <div className="app-bar-action">{action}</div>
        </div>
        <div className="notificationsAndNameContainer">
          <div
            className="notificationsIconContainer"
            onClick={() => {
              history.push("/notifications");
            }}
          >
            <NotificationsIcon fontSize="large" color="primary" />
            {newNotificationsAmount > 0 && (
              <div className="newNotificationsAmountContainer">
                {newNotificationsAmount}
              </div>
            )}
          </div>
          <div className="usernameAndMenuContainer">
            <IconButton
              onClick={handleClick}
              aria-controls="profile-menu"
              aria-label="settings"
              aria-haspopup="true"
              id="menuButton"
            >
              <PersonOutlineOutlinedIcon fontSize="large" />
            </IconButton>
            <div onClick={openMenuFromName} className="userName">
              {user_details.name}
            </div>
            <Menu
              id={`profile-menu`}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  browserHistory.push("/profile");
                }}
              >
                {translate("myInfo")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  browserHistory.push("/profile/edit");
                }}
              >
                {translate("editProfile")}
              </MenuItem>
              <NotificationSettingsModal />
              <ChangePasswordModal />
              <MenuItem
                onClick={() => {
                  dispatch(logoutUser());
                }}
              >
                {translate("logout")}
              </MenuItem>
            </Menu>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  goBack: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
  action: PropTypes.node,
  closeText: PropTypes.string
};

export const Container = ({ children, ...rest }) => (
  <div className="page" {...rest}>
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node
};
export const Content = ({ isFetching, isEmpty, emptyMessage, children, styles }) => {
  return isFetching ? (
    <div className="page-content">
      <Spinner size={50} />
    </div>
  ) : isEmpty ? (
    <EmptyView message={emptyMessage} />
  ) : (
    <div style={ styles ? { display: "flex", flexDirection: "column", flex: 1, ...styles} : { display: "flex", flexDirection: "column", flex: 1 } }>
      {children}
    </div>
  );
};

Content.propTypes = {
  isFetching: PropTypes.bool,
  isEmpty: PropTypes.bool,
  emptyMessage: PropTypes.string,
  children: PropTypes.node,
  styles: PropTypes.object
};

export default { Container, Content, Header };
