import { setPatientProfile, setPatientFormAttributes } from "reducers/user";
import { API } from "actions/api";
import { NotificationManager } from "react-notifications";
import browserHistory from "browser-history";
import { setLanguage, resetRecallPrompt } from "reducers/auth";

const getSuccessMessage = speaksSpanish => {
  return speaksSpanish
    ? "Tu perfil ha sido actualizado"
    : "Your profile has been updated";
};

const getErrorMessage = speaksSpanish => {
  return speaksSpanish
    ? "No se pudo actualizar tu perfil"
    : "Could not update your profile";
};
// accept optional headers for email order token authorization
export const fetchPatientProfile = headers => async dispatch => {
  try {
    const { profile } = await dispatch({
      type: API,
      route: "patient_portal/mobile/users/profile",
      method: "GET",
      headers,
      publicRoute: headers ? true : false
    });
    dispatch(setPatientProfile(profile));
    return profile;
  } catch (error) {
    console.error(error.message);
  }
};

// accept optional headers for email order token authorization
export const fetchPatientFormAttributes = headers => async dispatch => {
  try {
    const data = await dispatch({
      type: API,
      route: "patient_portal/mobile/form_attributes",
      method: "GET",
      headers,
      publicRoute: headers ? true : false
    });
    dispatch(setPatientFormAttributes(data));
    return data;
  } catch (error) {
    console.error(error.message);
  }
};

export const updatePatientProfile = profile => async dispatch => {
  try {
    const result = await dispatch({
      type: API,
      route: "patient_portal/mobile/users/profile",
      method: "PUT",
      body: profile
    });
    browserHistory.push("/profile");
    const toBool = value => value === "true" || value === true;
    const speaksSpanish = toBool(profile.patient_information.speaks_spanish);
    dispatch(setLanguage(speaksSpanish));
    NotificationManager.success(getSuccessMessage(speaksSpanish));
    return result;
  } catch (error) {
    console.error(error.message);
    NotificationManager.error(
      getErrorMessage(profile.patient_information.speaks_spanish),
      error.message
    );
  }
};

export const updateTextConsent =
  (patient, token, speaksSpanish) => async dispatch => {
    try {
      const result = await dispatch({
        type: API,
        route: "patient_portal/patients",
        method: "POST",
        body: { patient },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        publicRoute: true
      });
      browserHistory.push({
        pathname: "/thanks",
        state: {
          message: getSuccessMessage(speaksSpanish),
          backTo: "/dashboard"
        }
      });
      NotificationManager.success(getSuccessMessage(speaksSpanish));
      return result;
    } catch (error) {
      console.error(error.message);
      NotificationManager.error(getErrorMessage(speaksSpanish), error.message);
    }
  };

export const submitPhilipsRecall = (formValues, token) => async dispatch => {
  try {
    const success = await dispatch({
      type: API,
      route: "patient_portal/mobile/philips_recall",
      method: "POST",
      body: formValues,
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        : undefined,
      publicRoute: token ? true : false
    });
    if (!success) throw "Sorry failed to submit recall information";

    const Reroute = () => {
      // Send patients back to the order form if they continue service
      if (formValues.recallStatus !== "Pending") {
        console.log(token);
        if (token) return `/new-email-order?token=${token}`;
        else return "/order-form";
      } else return "/";
    };

    browserHistory.push({
      pathname: "/thanks",
      state: {
        message: getSuccessMessage(formValues.speaksSpanish),
        backTo: Reroute()
      }
    });
    dispatch(resetRecallPrompt());
    NotificationManager.success(getSuccessMessage(formValues.speaksSpanish));
    return success;
  } catch (error) {
    NotificationManager.error(
      getErrorMessage(formValues.speaksSpanish)?.error?.message ??
        "Sorry something went wrong"
    );
  }
};

export const uploadPatientProfileImage = file => async dispatch => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const result = await dispatch({
      type: API,
      route: "patient_portal/mobile/users/patient_photo",
      method: "POST",
      headers: {},
      body: formData
    });
    console.log(result);
    return result;
  } catch (error) {
    console.error(error.message);
  }
};

export const updateProfilePhoto = file => async dispatch => {
  try {
    const data = new FormData();
    data.append("file", {
      name: file.path,
      uri: file.path,
      // Platform.OS === 'android' ? file.path : file.path.replace('file://', ''),
      type: file.mime
    });
    const result = await dispatch({
      type: API,
      route: "patient_portal/mobile/users/patient_photo",
      method: "PUT",
      headers: {},
      body: data
    });
    console.log(result);
    return result;
  } catch (error) {
    console.error(error.message);
  }
};

export const changePassword =
  ({ current_password, password, password_confirmation }) =>
  async dispatch => {
    try {
      const { message } = await dispatch({
        type: API,
        route: "patient_portal/mobile/change_password",
        method: "PUT",
        body: {
          old_password: current_password,
          new_password: password,
          new_password_confirmation: password_confirmation
        }
      });
      NotificationManager.success(message);
    } catch (error) {
      console.log(error);
      NotificationManager.error(error.message);
    }
  };
export const pushOutreachWithToken =
  (token, speaksSpanish) => async dispatch => {
    try {
      const { message, success } = await dispatch({
        type: "API",
        route: "patient_portal/mobile/users/profile/push_outreach",
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      if (success) {
        NotificationManager.success(getSuccessMessage(speaksSpanish));
        browserHistory.push("/login");
        return message;
      } else {
        NotificationManager.error(getErrorMessage(speaksSpanish), message);
        return message;
      }
    } catch (error) {
      console.error(error);
      NotificationManager.error(getErrorMessage(speaksSpanish), error?.message);
    }
  };
