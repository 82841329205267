import PropTypes from "prop-types";
import { Page } from "components/ui";
import { Button } from "@mui/material";
import "./not-found.scss";
import useTranslate from "hooks/use-translate-language";

const NotFoundPage = ({ history }) => {
  const translate = useTranslate();
  return (
    <Page.Container>
      <div className="not-found-screen">
        <div className="not-found-container">
          <h1>{translate("sorry")}</h1>
          <h3>{translate("pageNotFound")}</h3>
          <Button
            onClick={() => {
              history.goBack();
            }}
            variant="contained"
          >
            {translate("back")}
          </Button>
        </div>
      </div>
    </Page.Container>
  );
};

NotFoundPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default NotFoundPage;
