import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { TextField, FormControl, InputLabel } from "@mui/material";
import { path } from "ramda";

const getPath = name => name.split(".");

// https://material-ui.com/api/text-field/
const TextInput = ({ label, name, ...rest }) => {
  const { values, handleBlur, handleChange, errors, touched } =
    useFormikContext();
  const namePath = getPath(name);
  const error = path(namePath, touched) && path(namePath, errors);
  const value = path(namePath, values);
  return (
    <FormControl fullWidth error={!!error}>
      <InputLabel
        shrink={true}
        id={`${name}-label`}
        size="small"
        style={{
          whiteSpace: "initial",
          wordWrap: "break-word",
          //margin: label?.length > 20 ? -1 : 5,
          width: "100%"
        }}
      >
        {label}
      </InputLabel>
      <TextField
        error={!!error}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={error}
        margin="dense"
        style={{ width: "100%" }}
        {...rest}
      />
    </FormControl>
  );
};

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default TextInput;
