export default {
  // Index
  // sorry is listed in error-page
  errorAuthenticatingAccount: {
    english: "There was an error authenticating your account",
    spanish: "Hubo un error al autenticar su cuenta",
  },
  linkNoLongerValid: {
    english: "Link is no longer valid",
    spanish: "El enlace ya no es válido",
  },
  loginToUpdateAccount: {
    english: "Login To Update Your Account",
    spanish: "Inicie sesión para actualizar su cuenta",
  },

  // Text-Consent-Form
  thanksForAllowing: {
    english: "Thanks for allowing",
    spanish: "Gracias por permitir",
  },
  verifyTextConsent: {
    english:
      "to provide service for your PAP needs. We are updating our records and would like to verify our ability to text/SMS messages about your care to the numbers provided below. Please verify the numbers you authorize for us to contact.",
    spanish:
      "para brindarle servicio para sus necesidades de PAP. Estamos actualizando nuestros registros y nos gustaría verificar nuestra capacidad para enviar mensajes de texto / SMS sobre su atención a los números que se proporcionan a continuación. Verifique los números que autoriza para que nos comuniquemos.",
  },
  agreeToReceiveText: {
    english:
      "I agree to receive text/SMS messages to the numbers provided above",
    spanish:
      "Acepto recibir mensajes de texto / SMS a los números proporcionados anteriormente",
  },
  declineToReceiveText: {
    english: "I do not want to receive any text messages",
    spanish: "No quiero recibir mensajes de texto",
  },
  save: {
    english: "Save",
    spanish: "Ahorrar",
  },
  numberOnFileIncorrect: {
    english:
      "If the number we have on file is not correct please contact us at",
    spanish:
      "Si el número que tenemos registrado no es correcto, contáctenos en",
  },
  textConsentDisclaimer: {
    english:
      "Disclaimer: By granting Text Consent, you are asserting that you have legal, authorized permission status for these phone accounts, and allowing us to send text/SMS messages to these numbers",
    spanish:
      "Descargo de responsabilidad: al otorgar el consentimiento de texto, usted afirma que tiene estado de permiso legal y autorizado para estas cuentas telefónicas, y permitiéndonos enviar mensajes de texto / SMS a estos números",
  },
  // Phone-Number-Consent-Input
  yes: {
    english: "Yes",
    spanish: "Sí",
  },
  no: {
    english: "No",
    spanish: "No",
  },
};
