import React from "react";
import PropTypes from "prop-types";
import ChooseSupplies from "./choose-supplies";
import Ussage from "./usage";
import OrderSummary from "./summary";
import ShippingDetails from "./shipping-details";
import Physician from "./physician";
import Insurance from "./insurance";
import Dysfunctions from "./dysfunctions";
import { propOr } from "ramda";
import OrderFormStepper from "./stepper";
import { useFormikContext } from "formik";
import { AlertBar } from "components/ui";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import useTranslate from "hooks/use-translate-language";
import { useSelector } from "react-redux";
import selectors from "selectors";

const OrderForm = ({ activeStep, profile, setActiveStep, token }) => {
  const translate = useTranslate();
  const { validateForm } = useFormikContext();
  const history = useHistory();
  React.useEffect(() => {
    validateForm();
  }, [validateForm]);

  const companyOrderFormOptions = useSelector(
    selectors.getCompanyOrderFormOptions
  );

  var orderFormSections = [
    <ChooseSupplies
      key={"supplies"}
      token={token}
      showRecallPrompt={profile.show_recall_prompt}
    />,
    <Ussage key={"usage"} />,
    <Physician key={"physician"} physician_information={profile?.physician_information} />,
    <Insurance
      insuranceOptions={propOr([], "insurance_types", profile).map(
        ({ text }) => ({ text, value: text })
      )}
      key={"insurance"}
      patientInsurances={profile?.insurances}
    />,
    <Dysfunctions key={"dysfunctions"} />,
    <OrderSummary key={"summary"} />,
    <ShippingDetails
      address={profile?.patient_information?.home_address ?? {}}
      key={"shipping"}
    />
  ];

  let numberOfSteps = 7;
  if (
    !companyOrderFormOptions.usage &&
    !companyOrderFormOptions.saw_physician_last
  ) {
    //Don't show usage page if not asking these questions
    numberOfSteps = numberOfSteps - 1;
    orderFormSections = orderFormSections.filter(x => x.key != "usage");
  }
  if (!companyOrderFormOptions.updated_physician) {
    //Don't show physician page if not asking these questions
    numberOfSteps = numberOfSteps - 1;
    orderFormSections = orderFormSections.filter(x => x.key != "physician");
  }

  return (
    <div className="order-form-container">
      <div className="order-form" style={{ margin: 20 }}>
        {profile.show_recall_prompt && (
          <AlertBar color="red">
            <div
              style={{ alignItems: "center" }}
              className="recall-alert-banner"
            >
              {translate("orderFormRecallText")}
              <Button
                style={{ marginLeft: 15 }}
                variant="contained"
                onClick={() => {
                  history.push(
                    token ? `/recall-notice?token=${token}` : "/recall-notice"
                  );
                }}
              >
                {translate("acknowlegeHere")}
              </Button>
            </div>
          </AlertBar>
        )}

        {/* Dynamically reneder form pages */}
        {orderFormSections[activeStep]}
      </div>
      <OrderFormStepper
        setActiveStep={setActiveStep}
        activeStep={activeStep}
        numberOfSteps={numberOfSteps}
        showRecallPrompt={profile.show_recall_prompt}
        orderFormSections={orderFormSections}
      />
    </div>
  );
};

OrderForm.propTypes = {
  activeStep: PropTypes.number.isRequired,
  profile: PropTypes.object.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  token: PropTypes.string
};

export default OrderForm;
