import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Spinner } from "components/ui";
import { Button } from "@mui/material";
import { PASSWORD_EXP } from "utils/validation";
import Logo from "assets/images/icon_trans.png";
import { useLocation, useParams } from "react-router-dom";
import { TextInput } from "components/form";
import { useDispatch } from "react-redux";
import { resetPassword, checkToken } from "actions/auth";
import "./reset-password.scss";
import useTranslate from "hooks/use-translate-language";
import { decodeJwt } from "utils/misc";
import queryString from "query-string";
import { setLanguage } from "reducers/auth";

const ResetPassword = () => {
  const translate = useTranslate();

  // Check for legacy token for backwards compatibility
  const { token } = useParams();

  const location = useLocation();
  const tokenString = queryString.parse(location.search).token || token;
  const decoded = decodeJwt(tokenString);
  const speaksSpanish = decoded?.speaks_spanish;

  const dispatch = useDispatch();
  const [validToken, setIsValidToken] = useState(null);
  useEffect(() => {
    dispatch(checkToken(tokenString || token)).then(result => {
      setIsValidToken(result);
    });
  }, [tokenString, dispatch]);
  useEffect(() => {
    dispatch(setLanguage(speaksSpanish));
  }, []);
  return (
    <div className="login-screen reset-password">
      <div className="login-screen-header">
        <img src={Logo} className="login-logo" alt="logo" />
        <div>{translate("resetPassword")}</div>
      </div>
      {validToken === null && (
        <div className="validating-container">
          <Spinner />
        </div>
      )}
      {validToken === true && (
        <Formik
          initialValues={{ password: "", password_confirmation: "" }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required(translate("required"))
              .matches(PASSWORD_EXP, translate("passwordRequirements")),
            password_confirmation: Yup.string()
              .required(translate("required"))
              .oneOf([Yup.ref("password")], translate("passwordsDoNotMatch"))
          })}
          onSubmit={async values => {
            await dispatch(resetPassword({ ...values, tokenString }));
          }}
        >
          {({ isSubmitting, isValid }) => (
            <Form className="reset-password-form">
              <TextInput
                type="password"
                name="password"
                label={translate("password")}
              />
              <TextInput
                type="password"
                name="password_confirmation"
                label={translate("confirmPassword")}
                style={{ marginBottom: 30 }}
              />
              {isSubmitting ? (
                <Spinner />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  id="reset-password-button"
                  type="submit"
                  disabled={!isValid}
                >
                  {translate("resetPassword")}
                </Button>
              )}
            </Form>
          )}
        </Formik>
      )}
      {validToken === false && (
        <div className="validation-error">
          <h3>{translate("sorryLinkNoLongerValid")}</h3>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
