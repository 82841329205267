import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { loginWithToken } from "actions/auth";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import queryString from "query-string";
import { Spinner } from "components/ui";

const SingleSigninPage = () => {
  const { search } = useLocation();
  // const { token, redirectTo } = parseQueryString(search);
  const { token, redirectTo } = queryString.parse(search);
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  // const loginError = useSelector(selectors.getLoginError);
  const history = useHistory();
  useEffect(() => {
    login(token, redirectTo);
  }, [token, redirectTo]);
  async function login(token, redirectTo) {
    try {
      setIsFetching(true);
      const decodedToken = atob(token);
      await dispatch(loginWithToken({ token: decodedToken, redirectTo }));
    } finally {
      setIsFetching(false);
    }
  }
  return (
    <div className="thanks-screen">
      <div className="thanks-container">
        {isFetching && <h2>Authorizing</h2>}
        {isFetching ? (
          <Spinner />
        ) : (
          <Button
            onClick={() => {
              history.push("/login");
            }}
            variant="contained"
            color="primary"
          >
            Go To Login
          </Button>
        )}
      </div>
    </div>
  );
};

SingleSigninPage.defaultProps = {};

SingleSigninPage.propTypes = {};

export default SingleSigninPage;
