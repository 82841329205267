import { setPatientDocuments, setDocumentCategories } from "reducers/documents";
import { API } from "actions/api";
import { NotificationManager } from "react-notifications";

export const fetchPatientDocuments = () => async dispatch => {
  try {
    const { success, message, documents } = await dispatch({
      type: API,
      route: "patient_portal/mobile/documents",
      method: "GET"
    });
    dispatch(setPatientDocuments(documents));
    return { success, message, documents };
  } catch (error) {
    console.error(error.message);
  }
};

export const fetchDocumentCategories = () => async dispatch => {
  try {
    const { success, message, document_categories } = await dispatch({
      type: API,
      route: "patient_portal/mobile/document_categories",
      method: "GET"
    });
    dispatch(setDocumentCategories(document_categories));
    return { success, message, document_categories };
  } catch (error) {
    console.error(error.message);
  }
};

export const uploadPatientDocument =
  ({ file, title, description, document_category_id }) =>
  async dispatch => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("document_category_id", document_category_id);
    formData.append("active", true);
    try {
      const result = await dispatch({
        type: API,
        route: "patient_portal/mobile/documents",
        method: "POST",
        headers: {},
        body: formData
      });
      await dispatch(fetchPatientDocuments());
      NotificationManager.success("Document Uploaded", "");
      return result;
    } catch (error) {
      console.error(error.message);
    }
  };

export const deletePatientDocument = document_id => async dispatch => {
  try {
    const result = await dispatch({
      type: API,
      route: `patient_portal/mobile/documents/${document_id}`,
      method: "DELETE"
    });
    await dispatch(fetchPatientDocuments());
    return result;
  } catch (error) {
    console.error(error.message);
  }
};
