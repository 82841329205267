export default {
  // Chat-Now-Window
  textChat: {
    english: "Text Chat",
    spanish: "Chat de texto",
  },
  willBeWithYouShortly: {
    english: "A Representative will be with you shortly",
    spanish: "Un representante estará con usted en breve",
  },
  cancel: {
    english: "Cancel",
    spanish: "Cancelar",
  },
  close: {
    english: "Close",
    spanish: "Cerca",
  },
  // Send-Msg-Input
  leaveChat: {
    english: "Leave Chat",
    spanish: "Salir del chat",
  },
  endChatConfirmationMessage: {
    english: "Are you sure you want to end chat",
    spanish: "¿Estás seguro de que quieres terminar el chat?",
  },
  messagePlaceholder: {
    english: "message...",
    spanish: "mensaje...",
  },
  // Message
  me: {
    english: "Me",
    spanish: "Yo",
  },
};
