import getInitialValues from "../initial-values";
import { pick, pipe, match, join, pathOr } from "ramda";

const getDuplicateOrderValues = (profile, order) => {
  const initialValues = getInitialValues(profile);
  return {
    ...initialValues,
    ...getCompliance(order?.compliance),
    ...order?.dysfunctions,
    requested_equipment: order?.requested_equipment,
    inpatient_facility: order?.inpatient_facility,
    address: order?.address,
    other_issues: ""
  };
};

export default getDuplicateOrderValues;

export const getCompliance = compliance => {
  const result = {
    ...pick(
      ["subscriber_id", "supply_period"], //"other_issues",
      compliance
    ),
    hours_per_night: getHoursPerNightValue(compliance),
    nights_per_week: getNightsPerWeekValue(compliance),
    saw_physician_last: ""
  };
  return result;
};

export const getNightsPerWeekValue = pipe(
  pathOr("", ["nights_per_week", "original"]),
  match(/[0-9]+/g),
  join("")
);

export const getHoursPerNightValue = pipe(
  pathOr("", ["hours_per_night", "original"]),
  match(/(less than 4 hours|4-6 hours|6\+ hours)/g),
  join("")
);

// export const getSawPhysicianLastValue = pipe(
//   propOr("", "saw_physician_last"),
//   match(/(0-3 months|3-6 months|6-12 months|12 or more months)/g),
//   join("")
// );
