import PropTypes from "prop-types";
import { Button, MobileStepper, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Spinner } from "components/ui";
import {
  validateChooseSuppliesForm,
  validateUsageForm,
  validatePhysicianForm,
  validateInsuranceForm,
  validateShippingDetails,
  validateDysfunctions
} from "./validation";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useFormikContext } from "formik";
import useTranslate from "hooks/use-translate-language";

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

const OrderFormStepper = ({
  numberOfSteps,
  activeStep,
  setActiveStep,
  showRecallPrompt,
  orderFormSections
}) => {
  const translate = useTranslate();
  const classes = useStyles();
  const theme = useTheme();
  const { setFieldTouched, handleSubmit, values, errors, isSubmitting } =
    useFormikContext();
  return (
    <div className="order-form-stepper" style={{ marginTop: 20 }}>
      <MobileStepper
        variant="dots"
        steps={numberOfSteps}
        position="static"
        activeStep={activeStep}
        className={classes.root}
        nextButton={
          isSubmitting ? (
            <Spinner />
          ) : (
            <Button
              size="small"
              onClick={() => {
                if (showRecallPrompt) {
                  return;
                }
                if (
                  orderFormSections[activeStep].key === "supplies" &&
                  !validateChooseSuppliesForm({
                    values,
                    errors,
                    setFieldTouched
                  })
                ) {
                  return;
                } else if (
                  orderFormSections[activeStep].key === "usage" &&
                  !validateUsageForm({ errors, setFieldTouched })
                ) {
                  return;
                } else if (
                  orderFormSections[activeStep].key === "physician" &&
                  !validatePhysicianForm({ errors, setFieldTouched })
                ) {
                  return;
                } else if (
                  orderFormSections[activeStep].key === "insurance" &&
                  !validateInsuranceForm({ errors, setFieldTouched })
                ) {
                  return;
                } else if (
                  orderFormSections[activeStep].key === "dysfunctions" &&
                  !validateDysfunctions({ values, errors, setFieldTouched })
                ) {
                  return;
                } else if (orderFormSections[activeStep].key === "shipping") {
                  if (
                    !validateShippingDetails({
                      values,
                      errors,
                      setFieldTouched
                    })
                  ) {
                    handleSubmit();
                  }
                } else setActiveStep(prevActiveStep => prevActiveStep + 1);
              }}
              disabled={activeStep === numberOfSteps}
            >
              {activeStep === numberOfSteps - 1
                ? translate("finish")
                : translate("next")}
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          )
        }
        backButton={
          <Button
            size="small"
            onClick={() => setActiveStep(prevActiveStep => prevActiveStep - 1)}
            disabled={activeStep === 0}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            {translate("back")}
          </Button>
        }
      />
    </div>
  );
};

OrderFormStepper.propTypes = {
  numberOfSteps: PropTypes.number,
  activeStep: PropTypes.number,
  setActiveStep: PropTypes.func.isRequired,
  showRecallPrompt: PropTypes.bool,
  orderFormSections: PropTypes.array
};

export default OrderFormStepper;
