import DashboardTranslation from "components/dashboard/translation";
import SideBar from "components/app-layout/translation";
import OrderForm from "components/order-form/translation";
import Profile from "components/profile/translation";
import ChatNow from "components/chat-now/translation";
import ErrorPage from "components/error-page/translation";
import Orders from "components/orders/translation";
import ExpiredPassword from "components/expired-password/translation";
import ErrorBoundary from "components/translation";
import VideoChatHistory from "components/video-chat-history/translation";
import UpcomingChats from "components/upcoming-chats/translation";
import TwilioChat from "components/twilio-chat/translation";
import ThankYou from "components/thank-you/translation";
import TextChat from "components/text-chat/translation";
import Signatures from "components/signatures/translation";
import ScheduleChat from "components/schedule-chat/translation";
import Questionnaires from "components/questionnaires/translation";
import Products from "components/products/translation";
import ProductDemo from "components/product-demo/translation";
import NotFound from "components/not-found/translation";
import Documents from "components/documents/translation";
import EditProfile from "components/edit-profile/translation";
import NewTextConsent from "components/text-consent/translation";
import NewEmailOrder from "components/new-email-order/translation";
import PushOutreach from "components/push-outreach/translation";
import ResetPassword from "components/reset-password/translation";
import RecallNotice from "components/recall-notice/translation";
import TwilioVideo from "components/twilio-video/translation";
import Notifications from "components/notifications/translation";
import LoginPages from "components/login-pages/translation";
import Tracking from "components/tracking/translation";
import KnowledgeBase from "components/knowledge-base/translation";
import UnconfirmedAppointmentActions from "components/shared/unconfirmed-appointment-actions/translation"
import Onboarding from "components/onboarding/translation";

export default {
  ...DashboardTranslation,
  ...SideBar,
  ...OrderForm,
  ...Profile,
  ...ChatNow,
  ...ErrorPage,
  ...Orders,
  ...ExpiredPassword,
  ...ErrorBoundary,
  ...VideoChatHistory,
  ...UpcomingChats,
  ...TwilioChat,
  ...ThankYou,
  ...TextChat,
  ...Signatures,
  ...ScheduleChat,
  ...Questionnaires,
  ...Products,
  ...ProductDemo,
  ...NotFound,
  ...Documents,
  ...EditProfile,
  ...NewTextConsent,
  ...PushOutreach,
  ...NewEmailOrder,
  ...ThankYou,
  ...ResetPassword,
  ...RecallNotice,
  ...TwilioVideo,
  ...Notifications,
  ...LoginPages,
  ...Tracking,
  ...KnowledgeBase,
  ...UnconfirmedAppointmentActions,
  ...Onboarding
};
