export default {
  // Index
  // resetPassword listed in profile
  // sorryLinkNoLongerValid listed in push-outreach
  // required listed in order-form
  confirmPassword: {
    english: "Confirm Password",
    spanish: "Confirmar Contraseña",
  },
  password: {
    english: "Password",
    spanish: "Contraseña",
  },
  passwordsDoNotMatch: {
    english: "Passwords do not match",
    spanish: "Las contraseñas no coinciden",
  },
  passwordRequirements: {
    english:
      "Password must be at least 8 characters long with 1 number, 1 special character, 1 capital and 1 lowercase letter",
    spanish:
      "La contraseña debe tener al menos 8 caracteres con 1 número, 1 carácter especial, 1 mayúscula y 1 letra minúscula",
  },
};
