import { Page, Spinner } from "components/ui";
import { useLocation } from "react-router-dom";
import { propOr } from "ramda";
import useTwilioChat from "hooks/use-twilio-chat";
import MessageList from "components/twilio-chat/message-list";
import SendMsgInput from "components/twilio-chat/send-msg-input";
import "./text-chat.scss";
import useTranslate from "hooks/use-translate-language";

const TextChat = () => {
  const translate = useTranslate();
  const { state } = useLocation();
  const textChats = propOr([], "text_chats", state);
  const {
    messages,
    sendMessage,
    connecting,
    error,
    connected,
    leaveTwilioChat,
    // members
  } = useTwilioChat({
    channel_sid: textChats[0]?.conversation_sid,
  });
  return (
    <Page.Container style={{ margin: 0 }}>
      <Page.Header title={translate("textChat")} goBack />
      <div className="text-chat-container">
        {connecting ? (
          <div className="text-chat-connecting">
            <Spinner />
            <div style={{ color: "#888" }}>{translate("connecting")}</div>
          </div>
        ) : error ? (
          <div style={{ color: "red" }}>{error}</div>
        ) : connected ? (
          textChats.map(({ channel_sid }) => (
            <div key={channel_sid} className="text-chat-window">
              <MessageList messages={messages} />
              <SendMsgInput
                sendMessage={sendMessage}
                leaveTwilioChat={leaveTwilioChat}
              />
            </div>
          ))
        ) : (
          <div>{translate("chatRoomClosed")}</div>
        )}
      </div>
    </Page.Container>
  );
};

export default TextChat;
