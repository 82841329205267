import * as R from "ramda";

const initialValues = {
  patient_information: {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    mobile_number: "",
    primary_phone_number: "",
    primary_phone_number_text_consent: false,
    secondary_phone_number: "",
    secondary_phone_number_text_consent: false,
    // speaks_spanish: false,
    home_address: {
      street_address: "",
      street_address2: "",
      city: "",
      state: "",
      zip: ""
    }
  },
  changed_insurance: true,
  physician_information: {
    address: {
      street_address: "",
      street_address2: "",
      city: "",
      state: "",
      zip: ""
    },
    name: "",
    fax: "",
    phone: "",
    note: ""
  },
  patient_insurances: []
};

const getInitialValues = profile => {
  const patient_phone_numbers = R.pipe(
    R.pathOr([], ["patient_information", "phone_numbers"]),
    phone_numbers =>
      phone_numbers.reduce((acc, cur, index) => {
        if (index === 0) {
          return {
            ...acc,
            phone_number: cur.phone_number,
            primary_phone_number_text_consent: cur.text_consent
          };
        } else if (index === 1) {
          return {
            ...acc,
            mobile_number: cur.phone_number,
            secondary_phone_number_text_consent: cur.text_consent
          };
        } else return acc;
      }, {})
  )(profile);
  return {
    patient_information: {
      ...initialValues.patient_information,
      ...R.pick(
        [
          "first_name",
          "last_name",
          "email",
          // 'phone_numbers',
          "home_address",
          "shipping_address",
          "billing_address"
        ],
        R.propOr([], "patient_information", profile)
      ),
      speaks_spanish: profile.patient_information?.speaks_spanish
        ? "true"
        : "false",
      ...patient_phone_numbers
    },
    physician_information: {
      ...initialValues.physician_information,
      ...R.pick(
        ["name", "fax", "phone", "address"],
        R.propOr({}, "physician_information", profile)
      )
    },
    patient_insurances: profile?.insurances ?? []
  };
};
export default getInitialValues;
