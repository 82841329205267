import { API } from "actions/api";
import { NotificationManager } from "react-notifications";

export const setMFAOptions = body => async dispatch => {
  try {
    const result = await dispatch({
      type: API,
      route: "patient_portal/mobile/mfa",
      method: "PUT",
      body
    });
    return result;
  } catch (error) {
    NotificationManager.error(error.message);
    console.error(error.message);
  }
};

export const fetchMFAOptions = headers => async dispatch => {
  try {
    const MFAOptions = await dispatch({
      type: API,
      route: "patient_portal/mobile/mfa",
      method: "GET"
    });
    return MFAOptions;
  } catch (error) {
    NotificationManager.error(error.message);
    console.error(error.message);
  }
};

export const verifyPhoneNumber = body => async dispatch => {
  try {
    const result = await dispatch({
      type: API,
      route: "patient_portal/mobile/mfa/phone-verify",
      method: "PUT",
      body
    });
    return result;
  } catch (error) {
    NotificationManager.error(error.message);
    console.error(error.message);
  }
};

export const sendCodeToContactMethod = (means, body) => async dispatch => {
  try {
    const result = await dispatch({
      type: API,
      route: `patient_portal/mobile/mfa/send-code/${means}`,
      method: "POST",
      body
    });
    return result;
  } catch (error) {
    NotificationManager.error(error.message);
    console.error(error.message);
  }
};

export const resendCodeToContactMethod = (means, body) => async dispatch => {
  try {
    const result = await dispatch({
      type: API,
      route: `patient_portal/mobile/mfa/resend-code/${means}`,
      method: "POST",
      body
    });
    return result;
  } catch (error) {
    NotificationManager.error(error.message);
    console.error(error.message);
  }
};
