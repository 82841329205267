export const API = "API";

export const Types = {
  API,
  API_ERROR: "API_ERROR",
  API_REQUEST: "API_REQUEST",
  API_RESPONSE: "API_RESPONSE",
  API_NOT_AUTHORIZED: "API_NOT_AUTHORIZED"
};

export const get = (
  route,
  headers = {
    "Content-Type": "application/json"
  }
) => ({
  type: Types.API,
  route,
  method: "GET",
  headers
});

export const post = (
  route,
  body,
  headers = {
    "Content-Type": "application/json"
  }
) => ({
  type: Types.API,
  route,
  method: "POST",
  headers,
  body
});

export const put = (
  route,
  body,
  headers = {
    "Content-Type": "application/json"
  }
) => ({
  type: Types.API,
  method: "PUT",
  route,
  headers,
  body
});

export const del = (
  route,
  body,
  headers = {
    "Content-Type": "application/json"
  }
) => ({
  type: Types.API,
  method: "DEL",
  route,
  headers,
  body
});
