export default {
  itemsRequested: {
    english: "Items Requested",
    spanish: "Elementos solicitados"
  },
  status: { english: "Status", spanish: "Estado" },
  noPreviousOrders: {
    english: "You have no previous orders",
    spanish: "No tienes pedidos previos"
  },

  // Potential order statuses
  new: {
    english: "New",
    spanish: "Nuevo"
  },
  rejected: {
    english: "Rejected",
    spanish: "Rechazado"
  },
  completed: {
    english: "Completed",
    spanish: "Terminado"
  },
  accepted: {
    english: "Accepted",
    spanish: "Aceptado"
  },
  backordered: {
    english: "Backordered",
    spanish: "Pendiente"
  },

  incorrect: {
    english: "Incorrect",
    spanish: "Incorrecto"
  },
  "internal processing": {
    english: "Internal Processing",
    spanish: "Procesamiento interno"
  },
  "ivr pending work": {
    english: "IVR Pending Work",
    spanish: "IVR trabajo pendiente"
  },
  "on hold": {
    english: "On Hold",
    spanish: "En espera"
  },
  pending: {
    english: "Pending",
    spanish: "Pendiente"
  },
  "pending shipment": {
    english: "Pending Shipment",
    spanish: "Envio pendiente"
  },
  "ready for review": {
    english: "Ready For Feview",
    spanish: "Listo para revisión"
  },
  voided: {
    english: "Voided",
    spanish: "Anulado"
  },
  // Renamed, these are order statuses that the patient doesn't really need to know about so they will default to pending
  "coach follow up": {
    english: "Pending",
    spanish: "Pendiente"
  },
  "location assistance needed": {
    english: "Pending",
    spanish: "Pendiente"
  },
  "pending buildout": {
    english: "Pending",
    spanish: "Pendiente"
  },
  "pending fulfillment": {
    english: "Pending",
    spanish: "Pendiente"
  },
  // End of renamed statuses

  // Accordian
  compliance: {
    english: "Compliance",
    spanish: "Cumplimiento"
  },
  otherIssues: {
    english: "Other Issues",
    spanish: "Otros asuntos"
  },
  insuranceAtTheTime: {
    english: "Primary insurance provider: ",
    spanish: "Proveedor de seguros primario: "
  },
  numberOfMonths: {
    english: "Months since last seeing physician: ",
    spanish: "Meses desde la última vez que consultó al médico: "
  },
  hoursPerNight: {
    english: "Hours per night using sleep device: ",
    spanish: "Horas por noche usando el dispositivo para dormir: "
  },
  nightsPerWeek: {
    english: "Nights per week using sleep device: ",
    spanish: "Noches por semana con dispositivo para dormir: "
  },

  // Order-Details
  orderDetails: {
    english: "Order Details",
    spanish: "Detalles del pedido"
  },
  orderDoesntExist: {
    english: "This order doesn't seem to exist",
    spanish: "Este orden no parece existir"
  },
  invoiceNumber: {
    english: "Invoice Number",
    spanish: "Número de factura"
  },
  equipmentOrdered: {
    english: "Equipment Ordered",
    spanish: "Equipo que se ordenó"
  },
  shippingAddress: {
    english: "Shipping Address",
    spanish: "Dirección de Envío"
  },
  trackingNumber: {
    english: "Tracking Number",
    spanish: "El número de rastreo"
  },
  quantityRequested: {
    english: "Quantity Requested",
    spanish: "Cantidad solicitada"
  },
  thirtyDaysSupply: {
    english: "30 day supply",
    spanish: "Suministro de 30 dias"
  },
  sixtyDaysSupply: {
    english: "60 day supply",
    spanish: "Suministro de 60 dias"
  },
  ninetyDaysSupply: {
    english: "90 day supply",
    spanish: "Suministro de 90 dias"
  },
  orderFormResponses: {
    english: "Order Form Responses",
    spanish: "Respuestas al formulario de pedido"
  }
};
