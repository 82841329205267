import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import HasPermission from "components/shared/has-permission";
import { fetchDashboard } from "actions/dashboard";
import selectors from "selectors";
import { useDispatch, useSelector } from "react-redux";
import { Page } from "components/ui";
import DashboardItem from "./dashboard-item";
import { isNil, isEmpty } from "ramda";
import OrderSuppliesAltImg from "assets/images/dashbooard_supplies.png";
import DropdownImg from "assets/images/dashboard_dropdown.png";
import TrackingImg from "assets/images/TrackingBigPin.svg";
import OrdersImg from "assets/images/ShoppingBag.svg";
import SignaturesImg from "assets/images/SigningDocument.svg";
import QuestionnaireIcon from "assets/images/ClipBoard.svg";
import ProductsImg from "assets/images/ProductBox.svg";
import DocumentsImg from "assets/images/Papers.svg";
import ScheduleChatImg from "assets/images/ScheduleChat.svg";
import UpcomingChatImg from "assets/images/UpcomingChat.svg";
import ChatNowImg from "assets/images/OnlineChat.svg";
import OrderSuppliesImg from "assets/images/ShoppingCart.svg";
import useTranslate from "hooks/use-translate-language";
import AlertBar from "components/ui/alert-bar";
import { Button } from "@mui/material";
import Modal from "components/ui/modal";
import LoggedInRecallPage from "components/recall-notice/login-recall-page";
import { closeRecallDialog } from "reducers/auth";
import "./dashboard.scss";
import useLanguageCheck from "hooks/use-language-check";
import AskForTextConsentModal from "./ask-for-text-consent-modal";
import { fetchPatientNotifications } from "actions/notifications";

const Dashboard = () => {
  const renderSublabel = (value, text) => (isNil(value) ? null : text);
  const translate = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const dashboardInfo = useSelector(selectors.getDashboardInfo);
  const appPermissions = useSelector(selectors.getAppPermissions);
  const showRecallModal = useSelector(selectors.getShowRecallPrompt);
  const dialogClosed = useSelector(selectors.getRecallDialogClosed);
  const usersCompany = useSelector(selectors.getUsersCompany);
  const { consent_required, phone_number } = useSelector(
    selectors.getUserTextConsent
  );
  const userDetails = useSelector(selectors.getUserDetails);

  useEffect(() => {
    setIsFetching(true);
    dispatch(fetchDashboard()).finally(() => {
      setIsFetching(false);
    });
  }, [dispatch]);
  useLanguageCheck();

  useEffect(async () => {
    await dispatch(fetchPatientNotifications());
  }, []);

  return (
    <Page.Content isFetching={isEmpty(dashboardInfo) && isFetching}>
      <Page.Header title="My Sleep Coach">
        <div style={{ fontSize: 12, fontWeight: "normal" }}>{usersCompany}</div>
      </Page.Header>
      {showRecallModal && (
        <div>
          <AlertBar color="red">
            <div
              style={{ alignItems: "center" }}
              className="recall-alert-banner"
            >
              {translate("dashboardRecallText")}
              <Button
                style={{ marginLeft: 15 }}
                variant="contained"
                onClick={() => {
                  history.push("/recall");
                }}
              >
                {translate("learnMore")}
              </Button>
            </div>
          </AlertBar>
          <Modal
            maxWidth="lg"
            fullWidth
            renderTrigger={() => null}
            startOpen={!dialogClosed}
            onClose={() => dispatch(closeRecallDialog())}
          >
            <LoggedInRecallPage />
          </Modal>
        </div>
      )}
      {consent_required && (
        <AskForTextConsentModal phone_number={phone_number} />
      )}
      <div className="dashboard-container">
        <HasPermission permission="onboarding">
          <DashboardItem
            backgroundColor="#26bbad"
            onClick={() => {
              history.push("/onboarding");
            }}
            className="layout-full"
          >
            <div className="dashboard-text">
              <div className="dashboard-label">{translate("welcome")}</div>
            </div>
          </DashboardItem>
        </HasPermission>
        <HasPermission permission="orderSupplies">
          <DashboardItem
            backgroundColor="#31C3E0"
            onClick={() => {
              history.push("/supplies");
            }}
            className="layout-full"
          >
            <div>
              <img src={OrderSuppliesImg} alt="" />
              <div className="dashboard-text">
                <div className="dashboard-label">
                  {translate("orderSupplies")}
                </div>
              </div>
            </div>
            <img src={OrderSuppliesAltImg} alt="" />
          </DashboardItem>
        </HasPermission>
        <HasPermission permission="scheduleChat">
          <DashboardItem
            backgroundColor="#24ABFB"
            onClick={() => {
              history.push("/schedule-chat");
            }}
            className="layout-half"
          >
            <img src={ScheduleChatImg} alt="" />
            <div className="dashboard-text">
              <div className="dashboard-label">{translate("scheduleChat")}</div>
            </div>
          </DashboardItem>
        </HasPermission>
        <HasPermission permission="scheduleChat">
          <DashboardItem
            backgroundColor="#53D1C3"
            onClick={() => {
              history.push("/upcoming-chats");
            }}
            className="layout-half"
          >
            <img src={UpcomingChatImg} alt="" />
            <div className="dashboard-text">
              <div className="dashboard-label">
                {translate("upcomingChats")}
              </div>
            </div>
          </DashboardItem>
        </HasPermission>
        <HasPermission permission="myProducts">
          <DashboardItem
            backgroundColor="#24ABFB"
            onClick={() => {
              history.push("/products");
            }}
            className="layout-full-left"
          >
            <img src={ProductsImg} alt="" />
            <div className="dashboard-text">
              <div className="dashboard-label">{translate("myProducts")}</div>
              {/* count is inccorrect and pulling from equipment tab, no need for this anyways
              <div className="dashboard-sub-label">
                {renderSublabel(
                  dashboardInfo.my_products_count,
                  `${translate("total")} ${
                    dashboardInfo.my_products_count
                  } ${translate("products")} `
                )}
              </div>*/}
            </div>
            <img src={DropdownImg} alt="" />
          </DashboardItem>
        </HasPermission>
        <HasPermission permission="myOrders">
          <DashboardItem
            backgroundColor="#81C3E0"
            onClick={() => {
              history.push("/orders");
            }}
            className="layout-half"
          >
            <div className="dashboard-text">
              <div className="dashboard-label">{translate("myOrders")}</div>
              <div className="dashboard-sub-label">
                {renderSublabel(
                  dashboardInfo.my_orders_count,
                  `${translate("total")} ${
                    dashboardInfo.my_orders_count
                  } ${translate("orders")}`
                )}
              </div>
            </div>
            <img src={OrdersImg} alt="" />
          </DashboardItem>
        </HasPermission>
        <HasPermission permission="tracking">
          <DashboardItem
            backgroundColor="#31C3E0"
            onClick={() => {
              history.push("/tracking");
            }}
            className={
              appPermissions.signatures ? "layout-half" : "layout-full-right"
            }
          >
            <div className="dashboard-text">
              <div className="dashboard-label">{translate("tracking")}</div>
            </div>
            <img src={TrackingImg} alt="" />
          </DashboardItem>
        </HasPermission>
        <HasPermission permission="documents">
          <DashboardItem
            backgroundColor="#E4E5E8"
            onClick={() => {
              history.push("/documents");
            }}
            className={
              appPermissions.signatures ? "layout-half" : "layout-full-right"
            }
          >
            <img src={DocumentsImg} alt="" style={{ width: 72 }} />
            <div className="dashboard-text">
              <div className="dashboard-label">{translate("myDocuments")}</div>
              <div className="dashboard-sub-label">
                {renderSublabel(
                  dashboardInfo.my_documents_count,
                  `${translate("total")} ${
                    dashboardInfo.my_documents_count
                  } ${translate("documents")} `
                )}
              </div>
            </div>
          </DashboardItem>
        </HasPermission>
        <HasPermission permission="signatures">
          <DashboardItem
            backgroundColor="#05d0b8"
            onClick={() => {
              history.push("/signatures");
            }}
            className={
              appPermissions.documents && appPermissions.tracking
                ? "layout-half"
                : "layout-full-left"
            }
          >
            <img src={SignaturesImg} alt="" style={{ width: 72 }} />
            <div className="dashboard-text">
              <div className="dashboard-label">{translate("signatures")}</div>
              <div className="dashboard-sub-label">
                {renderSublabel(
                  dashboardInfo.documents_needing_signatures,
                  `${dashboardInfo.documents_needing_signatures} ${translate(
                    "signatures"
                  )} `
                )}
              </div>
            </div>
          </DashboardItem>
        </HasPermission>
        <HasPermission permission="chatNow">
          <DashboardItem
            backgroundColor="#53D1C3"
            onClick={() => {
              history.push("/chat-now");
            }}
            className="layout-full-right"
          >
            <div className="dashboard-text">
              <div className="dashboard-label">{translate("onlineChat")}</div>
              <div className="dashboard-sub-label">
                {translate("chatWithRep")}
              </div>
            </div>
            <img src={ChatNowImg} alt="" />
          </DashboardItem>
        </HasPermission>
        <HasPermission permission="questionaires">
          <DashboardItem
            backgroundColor="#26bbad"
            onClick={() => {
              history.push("/questionnaires");
            }}
            className="layout-full-right"
          >
            <div className="dashboard-text">
              <div className="dashboard-label">
                {translate("questionnaires")}
              </div>
              <div className="dashboard-sub-label">
                {renderSublabel(
                  dashboardInfo.questionaire_count,
                  `${dashboardInfo.questionaire_count}`
                )}
              </div>
            </div>
            <img src={QuestionnaireIcon} alt="" style={{ width: 50 }} />
          </DashboardItem>
        </HasPermission>
      </div>
      {!userDetails.status_active ? (
        <div className="inactive-message-container">
          <p>
            Status:<b> Inactive</b>
          </p>
          <p>{`Last Order: ${userDetails.last_order ?? "No order registered or date not available"}`}</p>
          <p>{userDetails.error_message}</p>
        </div>
      ) : null}
    </Page.Content>
  );
};

export default Dashboard;
