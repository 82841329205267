import {
  take,
  takeLatest,
  select,
  call,
  fork,
  cancel,
  put
} from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import selectors from "selectors";
import { Types } from "actions/twilio-chat";
import TwilioChat from "twilio-chat";
import browserHistory from "browser-history";

export default function* rootTwilioChatSaga() {
  yield takeLatest(
    Types.INITIALIZE_TWILIO_CHAT_CLIENT,
    function* ({ payload }) {
      try {
        const userId = yield select(state => selectors.getUserId(state));

        const chatClient = yield createTwilioChatClient();
        yield put({ type: Types.TWILIO_CHAT_READY });
        browserHistory.push("/");
        const startTask = yield startTwilioChat(payload, chatClient, userId);
        yield take(["auth/logout", "TWILIO_CHAT_ENDED"]);
        console.log("ending chat");
        if (startTask) yield cancel(startTask);
      } catch (error) {
        console.log("---Chat Error--");
        console.log(error);
      }
    }
  );
}

function* createTwilioChatClient() {
  console.debug("create TwilioChat Client");
  const twilioToken = yield select(state => selectors.getTwilioToken(state));
  const client = yield TwilioChat.create(twilioToken);
  return client;
}

function* startTwilioChat(channelSid, chatClient, userId) {
  try {
    // yield put({
    //   type: Types.TWILIO_CHAT_MESSAGE_ADDED,
    //   payload: { body: "Connecting to Chat Room" }
    // });
    const channel = yield getTwilioChatChannel(chatClient, channelSid);
    if (!channel) {
      yield put({
        type: Types.TWILIO_CHAT_ERROR,
        error: "Could not connect to chat room"
      });
      throw Error("could not get chat channel");
    }
    if (channel.channelState.status !== "joined") {
      yield channel.join();
    }
    yield put({
      type: Types.TWILIO_CHAT_PARTICIPENT_JOINED,
      payload: userId
    });
    yield put({ type: Types.TWILIO_CHAT_STARTED });
    yield call(getChatHistory, channel);

    const handlePageLeave = e => {
      e.preventDefault();
      e.returnValue = "";
      console.log("leaving channel");
      channel.leave();
    };
    window.addEventListener("beforeunload", handlePageLeave);
    const channelEventsTask = yield fork(handleChatChannelEvents, channel);
    yield take([
      "auth/logout",
      Types.CLOSE_TWILIO_CHAT,
      Types.START_TWILIO_CHAT,
      Types.END_TWILIO_CHAT
    ]);
    yield channel.leave();
    if (channelEventsTask) yield cancel(channelEventsTask);
    yield put({ type: Types.TWILIO_CHAT_ENDED });
    window.removeEventListener("beforeunload", handlePageLeave);
  } catch (error) {
    console.error(error);
    yield put({ type: Types.TWILIO_CHAT_ERROR, error });
    yield put({ type: Types.TWILIO_CHAT_ENDED, error });
  }
}

async function getTwilioChatChannel(chatClient, channelSid) {
  try {
    const channel = await chatClient
      .getChannelBySid(channelSid)
      .catch(error => {
        console.error(error);
      });
    if (channel) return channel;
  } catch (error) {
    console.error("getTwilioChatChannel ERROR:", error);
  }
}

function* getChatHistory(channel) {
  const { items = [] } = yield channel.getMessages();
  const restoredMessages = items.map(message => ({
    ...message.state
  }));
  yield put({
    type: Types.TWILIO_CHAT_RESTORE_MESSAGES,
    payload: restoredMessages
  });
}
const chatEventChannel = channel =>
  eventChannel(emit => {
    channel.on("messageAdded", message => {
      emit({
        type: Types.TWILIO_CHAT_MESSAGE_ADDED,
        payload: { ...message.state }
      });
    });

    channel.on("memberJoined", member => {
      emit({
        type: Types.TWILIO_CHAT_PARTICIPENT_JOINED,
        payload: member.identity
      });
      emit({
        type: Types.TWILIO_CHAT_MESSAGE_ADDED,
        payload: { body: "User Joined Chat" }
      });
    });

    channel.on("memberLeft", member => {
      emit({
        type: Types.TWILIO_CHAT_PARTICIPENT_LEFT,
        payload: member.identity
      });
      emit({
        type: Types.TWILIO_CHAT_MESSAGE_ADDED,
        payload: { body: "User Left Chat" }
      });
    });
    channel.on("removed", () => {
      emit({
        type: Types.TWILIO_CHAT_MESSAGE_ADDED,
        payload: { body: "Chat Ended" }
      });
      emit({
        type: Types.END_TWILIO_CHAT
      });
    });
    return () => {};
  });

function* handleChatChannelEvents(channel) {
  const userName = yield select(state => selectors.getUserName(state));
  yield fork(listenSendMessage, channel, userName);
  const channelActions = yield call(chatEventChannel, channel);
  while (true) {
    const action = yield take(channelActions);
    yield put(action);
  }
}

function* listenSendMessage(channel, userName) {
  while (true) {
    const { payload } = yield take(Types.TWILIO_SEND_MESSAGE);
    try {
      yield channel.sendMessage(payload, {
        authorName: userName
      });
    } catch (error) {
      console.error(error);
    }
  }
}
