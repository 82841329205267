import * as React from "react";
import {
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Typography
} from "@mui/material";
import PropTypes from "prop-types";
import { ExpandMore } from "@mui/icons-material";
import useTranslate from "hooks/use-translate-language";

const OrderResponseAccordian = ({ order }) => {
  const {
    current_insurance,
    hours_per_night,
    nights_per_week,
    saw_physician_last
  } = order.compliance;
  const translate = useTranslate();
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{translate("compliance")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <p>
              {current_insurance.variable &&
                translate("insuranceAtTheTime") + current_insurance.variable}
            </p>
            <p>
              {saw_physician_last.variable &&
                translate("numberOfMonths") + saw_physician_last.variable}
            </p>

            <p>
              {hours_per_night.variable &&
                translate("hoursPerNight") + hours_per_night.variable}
            </p>
            <p>
              {nights_per_week.variable &&
                translate("nightsPerWeek") + nights_per_week.variable}
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>{translate("dysfunctions")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {order.requested_equipment.map((equipment, index) => (
              <div key={index}>
                <h4 style={{ marginTop: 5 }} key={equipment}>
                  {translate(equipment)}
                </h4>
                <p style={{ margin: 0 }}>
                  {translate("orderEquipmentQuestions")
                    .filter(
                      q =>
                        q.equipment === equipment && order?.dysfunctions[q.key]
                    )
                    .map(({ key, text }) => (
                      <div key={key}>{text}</div>
                    ))}
                </p>
              </div>
            ))}
            {order.dysfunctions.other_issues && (
              <div>
                <h4>{translate("otherIssues")}</h4>
                <div>{order.dysfunctions.other_issues}</div>
              </div>
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

OrderResponseAccordian.propTypes = {
  order: PropTypes.object
};

export default OrderResponseAccordian;
