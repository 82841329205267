import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { formatPhone } from "utils/misc";
import { ContactPhone } from "@mui/icons-material";
import useTranslate from "hooks/use-translate-language";

const YesNoRadioInput = ({ onChange, label, field, value }) => {
  const translate = useTranslate();
  return (
    <div>
      <span
        style={{
          marginTop: "1rem",
          paddingTop: "1rem",
        }}
      >
        {label}
      </span>
      <div>
        <label
          style={{
            display: "inline",
            padding: "1px 3px",
          }}
        >
          <input
            type="radio"
            name={field}
            checked={value}
            onChange={(e) => onChange(e)}
          />
          {translate("yes")}
        </label>
        <label
          style={{
            display: "inline",
            padding: "1px 3px",
          }}
        >
          <input
            type="radio"
            name={field}
            checked={!value}
            onChange={(e) => onChange(e)}
          />
          {translate("no")}
        </label>
      </div>
    </div>
  );
};

YesNoRadioInput.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  field: PropTypes.string,
  value: PropTypes.bool,
};

const PhoneNumberConsent = ({
  phone_number,
  value,
  textConsent,
  setConsentValue,
}) => {
  useEffect(() => {
    setConsentValue(true); // set initial value
  }, [textConsent, setConsentValue]);
  return (
    <label
      style={{ display: "flex", justifyContent: "space-between", margin: 10 }}
    >
      <span style={{ display: "flex", flex: 1, alignItems: "center" }}>
        <ContactPhone size={16} style={{ paddingRight: 10 }} color="primary" />
        <span>{formatPhone(phone_number)}</span>
      </span>
      <YesNoRadioInput
        value={value}
        onChange={() => {
          setConsentValue(!value);
        }}
      />
    </label>
  );
};

PhoneNumberConsent.propTypes = {
  phone_number: PropTypes.string,
  value: PropTypes.bool,
  setConsentValue: PropTypes.func.isRequired,
  textConsent: PropTypes.bool,
  nameOfNumber: PropTypes.string,
};

export default PhoneNumberConsent;
